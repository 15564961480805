export const defaultTheme = {
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        light: '#00A5E0',
        main: '#1C4E87',
        dark: '#1C4E87',
        contrastText: '#F6F8FF',
      },
      secondary: {
        light: '#00A5E0',
        main: '#1C4E87',
        dark: '#1C4E87',
        contrastText: '#F6F8FF',
      },
      background: {
        default: '#191919',
      },
      error: {
        light: '#B9314F',
        main: '#B9314F',
        dark: '#B9314F',
      },
      text: {
        light: '#F6F8FF',
        primary: '#F6F8FF',
        secondary: '#F6F8FF',
      },
      gradients: {
        linear: {
          _0: 'linear-gradient(-90deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
          _90: 'linear-gradient(0deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
          _180: 'linear-gradient(90deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
          _270: 'linear-gradient(180deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
        },
      },
    },
  },
  light: {
    palette: {
      mode: 'light',
      primary: {
        light: '#00A5E0',
        main: '#1C4E87',
        dark: '#1C4E87',
        contrastText: '#F6F8FF',
      },
      secondary: {
        light: '#191919',
        main: '#191919',
        dark: '#191919',
        contrastText: '#F6F8FF',
      },
      background: {
        default: '#F6F8FF',
      },
      error: {
        light: '#B9314F',
        main: '#B9314F',
        dark: '#B9314F',
      },
      success: {
        light: '#5cb85c',
        main: '#4a934a',
        dark: '#376e37',
      },
      text: {
        light: '#F6F8FF',
        primary: '#191919',
        secondary: '#191919',
      },
      gradients: {
        linear: {
          _0: 'linear-gradient(-90deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
          _90: 'linear-gradient(0deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
          _180: 'linear-gradient(90deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
          _270: 'linear-gradient(180deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
        },
      },
    },
  },
}
