import './style.scss'

import { useTheme } from '@emotion/react'
import { Alert, Grid, Snackbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import useServerResponse from 'src/hooks/useServerResponse'

import SplashScreen from '@/lib/SplashScreen'

import img2 from '../../../../assets/img/authBackgrounds/iStock-617862870.webp'
import img1 from '../../../../assets/img/authBackgrounds/iStock-1356751732.webp'

const AuthLayout = (): JSX.Element => {
  // AuthLayout Component
  // -------------------
  // Description:
  // This component creates a background for all pre-authenticated views.

  // Impact:
  // Modifying this component will affect the following components:
  // - SignIn
  // - Register
  // - PasswordReset

  const appTheme: any = useTheme()
  const { showResponse, responseMessage, setShowResponse, isSuccess } = useServerResponse()

  const backgroundImgs = [img1, img2]

  const [current, setCurrent] = useState<number>(0)
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent((c) => (c === 1 ? 0 : c + 1))
    }, 10000)
    return () => clearInterval(timer)
  }, [])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  if (isLoading) {
    return (
      <SplashScreen
        isLoading={isLoading}
        finishLoading={() => setIsLoading(false)}
      />
    )
  }

  return (
    <Grid
      container
      spacing={0}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      className={'auth_layout_wrapper'}
      style={{
        backgroundImage: `url(${backgroundImgs[current]})`,
      }}
    >
      <Grid
        item
        md={4}
        sm={6}
        xs={10}
        container
        direction={'column'}
        justifyContent={'center'}
        className={'auth_layout_form_container'}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Outlet />
      </Grid>
      {showResponse != undefined && (
        <Snackbar
          open={showResponse}
          autoHideDuration={6000}
          onClose={() => setShowResponse(false)}
        >
          <Alert
            variant={'filled'}
            onClose={() => setShowResponse(false)}
            severity={isSuccess ? 'success' : 'error'}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  )
}

export default AuthLayout
