import { useState, Suspense, lazy, LazyExoticComponent, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'

import { ISideDialogRef } from '@lib/SideDialog'
import { Tooltip, IconButton, Button } from '@mui/material'
import useServerResponse from '@hooks/useServerResponse'
import { parseError } from '@/shared/utils/formatters'
import { ISideDialogChildProps } from '@lib/SideDialog'

import HomeIcon from '@mui/icons-material/Home'
import HistoryIcon from '@mui/icons-material/History'
import Unarchive from '@mui/icons-material/Unarchive'
import { useUpdateTruckMutation } from '@/features/equipment/equipmentApiSlice'
import { createUpdateTruckRequest } from '@/pkg/equipment/converters'

type trailerDialogElements = 'info' | 'history' | 'link'

interface TruckDialogProps extends ISideDialogChildProps {
  refetch: () => void
  handleClose: () => void
  elementRef: MutableRefObject<ISideDialogRef | undefined>
  confirmationRef: MutableRefObject<any>
}

const TruckDialog: React.FC<TruckDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [truckId, setTruckId] = useState<string>('')
  const [updateTruck] = useUpdateTruckMutation()
  const { setResponseMessage, setShowResponse, setIsSuccess } = useServerResponse()

  const handleUnarchiveTruck = () => {
    const handleUnarchive = () => {
      let trailer = createUpdateTruckRequest(props.data!)
      trailer.archived = false
      updateTruck(trailer)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Fleet:responses.truckUpdated'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.unarchiveTruckTitle'),
      text: t('Fleet:confirmationDialog.unarchiveTruckText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleUnarchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:truckDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.unarchive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleUnarchiveTruck()}
        >
          <Unarchive />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<trailerDialogElements>('info')
  const getProperElement = (
    key: trailerDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    if (key === 'history') {
      return lazy(() => import('@app/fleet/views/TrucksView/components/truckDialog/history'))
    }
    return lazy(() => import('@app/fleet/views/TrucksView/components/truckDialog/info'))
  }

  useEffect(() => {
    setElementKey('info')
    if (props.data) setTruckId(props.data.id)
  }, [props.data, elementKey])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [truckId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
      />
    </Suspense>
  )
}

export default TruckDialog
