import dayjs from 'dayjs'
import { t } from 'i18next'

import appConfig from '@/config'
import { getRegion } from '@/shared/utils/formatters'

import { ICountryConfig } from '../types'
import { IStop } from './models'
import { ICreateCarrierInstanceLoadRequest } from './requests'

const date = new Date()
const country: string | null = localStorage.getItem('country')

export const initICreateStopRequest = (): IStop => {
  return {
    city: '',
    category: '',
    country: String(t(`Common:countries.${country?.toUpperCase()}` ?? 'US')),
    countryAbbreviation: country?.toUpperCase() ?? 'US',
    // @ts-ignore
    date: dayjs(date),
    // @ts-ignore
    dateTo: dayjs(date),
    // @ts-ignore
    time: dayjs(date),
    // @ts-ignore
    timeTo: dayjs(date),
    zipCode: '',
    street: '',
    lat: 0,
    lon: 0,
  }
}

export const initICreateCarrierLoadRequest = (): ICreateCarrierInstanceLoadRequest => {
  return {
    externalId: '',
    type: 'FTL',
    stops: [initICreateStopRequest(), initICreateStopRequest()],
    published: false,
    archived: false,
    commodityName: '',
    weight: 0,
    weightUnit: 'lbs',
    shipperId: '',
    carrierId: '',
    carrierPrice: 0,
    carrierPriceCurrency:
      (appConfig[getRegion(country!) as keyof typeof appConfig] as ICountryConfig)
        .defaultCurrency ?? 'USD',
    publishedPrice: 0,
    publishedPriceCurrency:
      (appConfig[getRegion(country!) as keyof typeof appConfig] as ICountryConfig)
        .defaultCurrency ?? 'USD',
    shipperPrice: 0,
    shipperPriceCurrency:
      (appConfig[getRegion(country!) as keyof typeof appConfig] as ICountryConfig)
        .defaultCurrency ?? 'USD',
    statusName: 'ho-pu',
    truckId: '',
    trailerId: '',
    driverIds: [],
    invoiceStatus: '',
    tags: [],
  }
}
