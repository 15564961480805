import { Route, Routes } from 'react-router-dom'

import RequireAuth from '@features/auth/RequireAuth'
import AccessRequestManager from './views/accessRequestManager'

const Management = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route
          path={'/access-requests'}
          element={<AccessRequestManager />}
        />
      </Route>
    </Routes>
  )
}

export default Management
