import { memo, useState } from 'react'
import dayjs from 'dayjs'
import { ISideDialogChildProps } from '@lib/SideDialog'
import { useTranslation } from 'react-i18next'
import { IStop } from '@pkg/loads/models'
import RouteMap from '@lib/RouteMap'
import { ITheme } from '@pkg/sokarUI'
import styles from './carrierLoadDialog.module.scss'

import {
  Box,
  Stack,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableCell,
  useTheme,
  Skeleton,
  Collapse,
  TableBody,
  Typography,
  Avatar,
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { parsePhoneNumber } from '@/shared/utils/formatters'
import { useGetUserQuery } from '@/features/users/usersApiSlice'

interface CarrierLoadDialogProps extends ISideDialogChildProps {
  elementRef: any
  handleClose: () => void
}

const CarrierLoadDialog = ({ ...props }: CarrierLoadDialogProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()

  const { data: owner, isSuccess: isOwnerSuccess } = useGetUserQuery(props.data?.owner.id, {
    skip: !props.data?.owner.id,
  })
  const [isStopsOpen, setIsStopsOpen] = useState<boolean>(true)

  if (!props.data) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    )
  }

  const MemoizedRouteMap = memo(RouteMap)

  return (
    <Stack
      direction={'column'}
      spacing={2}
    >
      <Typography variant={'h5'}>{t('Market:carrierLoadDialog.info')}</Typography>
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Box className={styles.mapWrapper}>
          {!!props.data && (
            <MemoizedRouteMap
              key={props.data.id}
              offer={props.data}
            />
          )}
        </Box>
        <Typography className={styles.ownerTitle}>{t('Market:carrierLoadDialog.owner')}</Typography>
        <Box className={styles.contact}>
          <Avatar className={styles.avatar} />
          {isOwnerSuccess && (
            <Stack
              direction={'column'}
              spacing={2}
            >
              <Typography component={'h2'}>
                {isOwnerSuccess && owner.firstName} {isOwnerSuccess && owner.lastName}
              </Typography>
              <Typography
                component={'a'}
                href={'tel:' + owner.phone.replace(/^0+/, '')}
              >
                {parsePhoneNumber(owner.phone)}
              </Typography>
              <Typography
                component={'a'}
                href={'mailto:' + props.data?.owner.email}
              >
                {props.data?.owner.email}
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.type')}</TableCell>
              <TableCell>{props.data?.type ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.weight')}</TableCell>
              <TableCell>
                {props.data?.weight ?? ''} {props.data?.weightUnit}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.commodityName')}</TableCell>
              <TableCell>{props.data?.commodityName ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.price')}</TableCell>
              <TableCell>
                {props.data?.publishedPrice ?? ''} {props.data?.publishedPriceCurrency ?? ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.trailer')}</TableCell>
              <TableCell>{props.data?.equipment ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.loadingMeters')}</TableCell>
              <TableCell>{props.data?.loadingMeters ?? '-'}</TableCell>
            </TableRow>
            <TableRow
              onClick={() => setIsStopsOpen(!isStopsOpen)}
              className={styles.extendableTableCell}
            >
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.stops')}</TableCell>
              <TableCell>
                {isStopsOpen ?
                  <KeyboardArrowUpIcon />
                : <KeyboardArrowDownIcon />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={2}
                className={styles.collapsableTableCell}
              >
                <Collapse
                  in={isStopsOpen}
                  unmountOnExit
                >
                  <Table>
                    <TableBody>
                      {props.data.stops.map((stop: IStop, index: number) => (
                        <>
                          <TableRow
                            key={index}
                            className={styles.noBorderBottom}
                          >
                            <TableCell variant={'head'}>
                              {stop.countryAbbreviation} {stop.city}
                            </TableCell>
                            <TableCell>
                              {dayjs(stop.date).format('YYYY-MM-DD')}
                              {!!stop.dateTo && <> - {dayjs(stop.dateTo).format('YYYY-MM-DD')}</>}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={index}
                            className={styles.noBorderTop}
                          >
                            <TableCell variant={'head'}>
                              {t(`Market:carrierLoadDialog.${stop.category}`)}
                            </TableCell>
                            <TableCell>
                              {dayjs(stop.date).format('HH:mm')}
                              {!!stop.dateTo && <> - {dayjs(stop.dateTo).format('HH:mm')}</>}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      <TableRow>
                        <TableCell variant={'head'}>
                          {t('Market:carrierLoadDialog.description')}
                        </TableCell>
                        <TableCell>
                          {props.data?.description ? props.data?.description : '-'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default CarrierLoadDialog
