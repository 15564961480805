import { t } from 'i18next'
import { boolean, object, string } from 'yup'

export const companyValidator = object().shape(
  {
    name: string()
      .required(t('company:validatorResponses.nameReq') ?? '')
      .min(3, t('company:validatorResponses.nameMin') ?? ''),
    fullName: string()
      .required(t('company:validatorResponses.fullNameReq') ?? '')
      .min(5, t('company:validatorResponses.fullNameMin') ?? ''),
    vatId: string().when('regon', {
      is: (vatId: string) => !vatId || vatId.length === 0,
      then: string()
        .required(t('company:validatorResponses.vatIdReq') ?? '')
        .min(3, t('company:validatorResponses.vatIdMin') ?? '')
        .max(15, t('company:validatorResponses.vatIdMax') ?? '')
        .matches(/^[0-9]+$/, 'company:validatorResponses.vatIdDigits'),
      otherwise: string(),
    }),
    regon: string().when('vatId', {
      is: (regon: string) => !regon || regon.length === 0,
      then: string()
        .required(t('company:validatorResponses.regonReq') ?? '')
        .min(3, t('company:validatorResponses.regonMin') ?? ''),
      otherwise: string(),
    }),
    phone: string()
      .required(t('company:validatorResponses.phoneReq') ?? '')
      .max(15, t('company:validatorResponses.phoneMax') ?? '')
      .matches(/^[0-9]+$/, 'company:validatorResponses.phoneDigits'),
    email: string()
      .required(t('company:validatorResponses.emailReq') ?? '')
      .email(t('company:validatorResponses.emailEmail') ?? ''),
    countryCode: string().required(t('company:validatorResponses.countryCodeReq') ?? ''),
    city: string().required(t('company:validatorResponses.cityReq') ?? ''),
    street: string().required(t('company:validatorResponses.streetReq') ?? ''),
    zipCode: string().required(t('company:validatorResponses.zipCodeReq') ?? ''),
    correspondence: string().required(t('company:validatorResponses.correspondenceReq') ?? ''),
    freightCurrency: string().required(t('company:validatorResponses.freightCurrencyReq') ?? ''),
    paymentCurrency: string().required(t('company:validatorResponses.paymentCurrencyReq') ?? ''),
    defaultVat: string().required(t('company:validatorResponses.defaultVatReq') ?? ''),
    incomingPaymentValue: string().required(
      t('company:validatorResponses.incomingPaymentValueReq') ?? '',
    ),
    incomingPaymentCurrency: string().required(
      t('company:validatorResponses.incomingPaymentCurrencyReq') ?? '',
    ),
    outgoingPaymentValue: string().required(
      t('company:validatorResponses.outgoingPaymentValueReq') ?? '',
    ),
    outgoingPaymentCurrency: string().required(
      t('company:validatorResponses.outgoingPaymentCurrencyReq') ?? '',
    ),
    incomingInvoicePaymentDays: string().required(
      t('company:validatorResponses.incomingInvoicePaymentDaysReq') ?? '',
    ),
    outgoingInvoicePaymentDays: string().required(
      t('company:validatorResponses.outgoingInvoicePaymentDaysReq') ?? '',
    ),

    isSuspended: boolean(),
    suspendedDescription: string().when('isSuspended', {
      is: true,
      then: string().required(t('company:validatorResponses.suspendedDescriptionReq') ?? ''),
    }),
  },
  [['vatId', 'regon']],
)
