import { apiSlice } from '@api/apiSlice'
import { IDriver } from '@pkg/drivers/models'
import { IDriverCreateRequest, IDriverUpdateRequest } from '@pkg/drivers/requests'

export const driversApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDriver: builder.query<
      IDriver[],
      { driverId?: string; companyId: string; archived?: boolean }
    >({
      query: ({ driverId: id, companyId, archived }) => ({
        url: '/driver',
        params: { id, companyId, archived },
      }),
      transformResponse: (response: { drivers: IDriver[] }) => response.drivers,
    }),
    createDriver: builder.mutation<IDriver, IDriverCreateRequest>({
      query: (driverInfo) => ({
        url: '/driver',
        method: 'POST',
        body: driverInfo,
      }),
      transformResponse: (response: { driver: IDriver }) => response.driver,
    }),
    updateDriver: builder.mutation<IDriver, IDriverUpdateRequest>({
      query: (driverInfo) => ({
        url: '/driver',
        method: 'PUT',
        body: driverInfo,
      }),
      transformResponse: (response: { driver: IDriver }) => response.driver,
    }),
    searchDriver: builder.query<
      IDriver[],
      { phrase: string; companyId?: string; archived?: boolean; carrierId?: string }
    >({
      query: ({ phrase, companyId, archived, carrierId }) => ({
        url: `/driver/search`,
        params: { phrase, companyId, archived, carrierId },
      }),
      transformResponse: (response: { drivers: IDriver[] }) => response.drivers,
    }),
    deleteDriver: builder.mutation<{ id: string }, string>({
      query: (driverId) => ({
        url: `/driver/${driverId}`,
        method: 'DELETE',
      }),
    }),
  }),
})
export const {
  useGetDriverQuery,
  useCreateDriverMutation,
  useUpdateDriverMutation,
  useDeleteDriverMutation,
  useSearchDriverQuery,
} = driversApiSlice
