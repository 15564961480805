import { Fragment, useState, ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import { useSearchCitiesQuery } from '@features/loads/loadsApiSlice'
import { ICity } from '@pkg/loads/models'

import styles from './cityField.module.scss'

import { Box, Autocomplete, TextField, CircularProgress } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

interface CityFieldProps {
  index: number
  error?: FieldError
  value: any
  country: string
  handleValueChange: (index: number, city: ICity) => void
}

const CityField = ({ ...props }: CityFieldProps): JSX.Element => {
  const { t } = useTranslation()
  const [searchPhrase, setSearchPhrase] = useState<string>('')

  const {
    data: cities,
    isSuccess: isCitiesSuccess,
    isFetching: isCitiesFetching,
  } = useSearchCitiesQuery(
    { searchPhrase, countryCode: props.country ?? '' },
    {
      skip: searchPhrase.length < 3,
    },
  )

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchPhrase(e.target.value)
  }

  const handleValueChange = (value: ICityOption | null): void => {
    if (!value) return
    props.handleValueChange(props.index, value?.originalObject as ICity)
  }

  useEffect(() => {
    setSearchPhrase(props.value)
  }, [props.value])

  const getNoOptions = (): JSX.Element => {
    if (isCitiesFetching) {
      return (
        <Box className={styles.loader}>
          <CircularProgress
            className={styles.loader}
            size={'1rem'}
          />
        </Box>
      )
    }
    return <>{t('Common:noOptions')}</>
  }

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      disablePortal
      value={props.value}
      options={cities ? parseCitiesForSelect(cities) : []}
      onChange={(e, value) => handleValueChange(value)}
      noOptionsText={getNoOptions()}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleSearchInput}
          disabled={!props.country}
          className={'create_load_dialog_text_field'}
          label={t('Fleet:createLoadDialog.city')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Fragment>
                <SearchIcon color={'secondary'} />
              </Fragment>
            ),
          }}
          error={!!props.error}
          helperText={<Box height={2}>{props.error?.message}</Box>}
        />
      )}
    />
  )
}

export default CityField

interface ICityOption {
  label: string
  value: string
  originalObject: ICity
}

const parseCitiesForSelect = (cities: ICity[]): ICityOption[] => {
  return cities.map((city) => ({
    label: `${city.name}, ${city.state} ${city.postalCode}`,
    value: city.id,
    originalObject: city,
  }))
}
