import { useState, useRef, useEffect, useCallback } from 'react'
import { t } from 'i18next'
import { useTheme } from '@emotion/react'
import useOwnUser from 'src/hooks/useOwnUser'
import useRefetchWithAlert from 'src/hooks/useRefetchWithAlert'
import { useGetTruckQuery, useSearchTruckQuery } from '@features/equipment/equipmentApiSlice'

import { ITruck } from '@pkg/equipment/models'
import ConfirmationDialog from '@lib/ConfirmationDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import TruckCard from './components/truckCard'
import { ITableColumn } from 'src/pkg/sokarUI'
import ContentTable, { ITableRef } from 'src/lib/ContentTable'
import CreateTruckDialog from './components/createTruckDialog'
import TruckDialog from './components/truckDialog'
import SideDialog from '@lib/SideDialog'
import UsedByCell from './components/usedByCell'
import TrucksOptionsCard from './components/trucksOptionsCard'

import { Tooltip, Skeleton, Typography, IconButton, useMediaQuery } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import WarningIcon from '@mui/icons-material/Warning'
import { getFileAlertColour } from '@/shared/utils/formatters'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:trucksTable.truckNumber'),
    dataKey: 'number',
    isSortable: false,
  },
  {
    label: t('Fleet:trucksTable.plates'),
    dataKey: 'plate',
    isSortable: true,
  },
  {
    label: t('Fleet:trucksTable.ownership'),
    dataKey: 'ownership',
    isSortable: false,
  },
  {
    label: t('Fleet:trucksTable.usedBy'),
    dataKey: 'usedBy',
    isSortable: false,
  },
]

const TrucksView = () => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const cellRenderer = (column: any, row: any): JSX.Element => {
    if (column.dataKey === 'ownership')
      return (
        <Typography>
          {row['isCarrierOwned'] ?
            t('Fleet:trucksTable.contractorsProperty')
          : t('Fleet:trucksTable.companysProperty')}
        </Typography>
      )
    if (column.dataKey === 'usedBy') {
      return (
        <UsedByCell
          companyId={companyId}
          driverId={row['usedBy']}
        />
      )
    }
    if (column.dataKey === 'options') {
      if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
        return (
          <Tooltip title={t('Common:documentRequiresAttention')}>
            {/* @ts-ignore */}
            <WarningIcon color={getFileAlertColour(row.documents)} />
          </Tooltip>
        )
      }
    }

    return row[column.dataKey]
  }

  const createTruckDialogRef = useRef<any>()
  const handleCreateTruckDialogOpen = (): void => {
    createTruckDialogRef.current?.open({})
  }
  const handleCreateTruckDialogClose = () => createTruckDialogRef.current?.close()
  const truckDialogRef = useRef<any>()
  const handleTruckDialogOpen = useCallback((row: ITruck): void => {
    truckDialogRef.current?.open(row)
  }, [])
  const handleTruckDialogClose = useCallback((): void => {
    truckDialogRef.current?.close()
  }, [])

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Fleet:trucksTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateTruckDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trucksTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )
  const { companyId } = useOwnUser()

  const {
    data: trucks,
    isSuccess: isTrucksSuccess,
    refetch: refetchTrucks,
  } = useGetTruckQuery({ companyId: companyId ?? '', archived: false }, { skip: false })
  const refresh = useRefetchWithAlert(refetchTrucks)

  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchTrucks = (phrase: string, isSearched: boolean) => {
    setIsSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedTrucks, isSuccess: isSearchTrucksSuccess } = useSearchTruckQuery(
    { phrase: searchPhrase, companyId: companyId, archived: false },
    { skip: !isSearched },
  )

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(trucks)
    else ref.current?.open(trucks, columns)
  }, [isMobile, trucks, columns])

  useEffect(() => {
    if (isTrucksSuccess && !isSearched) ref.current?.open(trucks, columns)
  }, [isTrucksSuccess, isSearched])

  useEffect(() => {
    if (isSearchTrucksSuccess && isSearched) ref.current?.open(searchedTrucks, columns)
  }, [isTrucksSuccess, isSearched, searchPhrase])

  const confirmationDialogRef = useRef<any>(null)

  if (!isTrucksSuccess) return <Skeleton />

  return (
    <>
      <TrucksOptionsCard handleSearch={handleSearchTrucks} />
      {isMobile ?
        <ContentList
          ref={listRef}
          openDialog={handleTruckDialogOpen}
          listComponent={TruckCard}
        />
      : <ContentTable
          ref={ref}
          freeActions={freeActions}
          cellRenderer={cellRenderer}
          openDialog={handleTruckDialogOpen}
        />
      }
      <SideDialog
        ref={createTruckDialogRef}
        title={t('Fleet:createTruckDialog.title')}
      >
        <CreateTruckDialog
          refetch={refetchTrucks}
          handleClose={handleCreateTruckDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={truckDialogRef}
        title={t('Fleet:truckDialog.title')}
      >
        <TruckDialog
          refetch={refetchTrucks}
          elementRef={truckDialogRef}
          confirmationRef={confirmationDialogRef}
          handleClose={handleTruckDialogClose}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default TrucksView
