import { useState, useRef, useEffect, useCallback } from 'react'
import { useTheme } from '@emotion/react'
import useOwnUser from 'src/hooks/useOwnUser'
import { t } from 'i18next'
import useRefetchWithAlert from 'src/hooks/useRefetchWithAlert'
import { useGetTrailerQuery, useSearchTrailerQuery } from '@/features/equipment/equipmentApiSlice'

import { ITrailer } from 'src/pkg/equipment/models'
import { ITableColumn } from 'src/pkg/sokarUI'
import ContentList, { IContentListRef } from '@lib/ContentList'
import TrailerCard from './components/trailerCard'
import ContentTable, { ITableRef } from 'src/lib/ContentTable'
import TrailerDialog from './components/trailerDialog'
import TrailerOptionsCard from './components/trailersOptionsCard'
import CreateTrailerDialog from './components/createTrailerDialog'
import SideDialog from '@lib/SideDialog'
import ConfirmationDialog from '@lib/ConfirmationDialog'
import { getFileAlertColour } from '@shared/utils/formatters'

import { Chip, Tooltip, Skeleton, Typography, IconButton, useMediaQuery } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import UsedByCell from './components/usedByCell'
import WarningIcon from '@mui/icons-material/Warning'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:trailersTable.trailerNumber'),
    dataKey: 'number',
    isSortable: false,
  },
  {
    label: t('Fleet:trailersTable.plates'),
    dataKey: 'plate',
    isSortable: true,
  },
  {
    label: t('Fleet:trailersTable.ownership'),
    dataKey: 'ownership',
    isSortable: false,
  },
  {
    label: t('Fleet:trailersTable.type'),
    dataKey: 'category',
    isSortable: true,
  },
  {
    label: t('Fleet:trailersTable.usedBy'),
    dataKey: 'usedBy',
    isSortable: false,
  },
]

const TrailersView = () => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const createTrailerDialogRef = useRef<any>(null)
  const handleCreateTrailerDialogOpen = (): void => {
    createTrailerDialogRef.current?.open({})
  }
  const handleCreateTrailerDialogClose = (): void => {
    createTrailerDialogRef.current?.close()
  }

  const trailerDialogRef = useRef<any>(null)
  const handleTrailerDialogOpen = useCallback((row: ITrailer): void => {
    trailerDialogRef.current?.open(row)
  }, [])
  const handleTrailerDialogClose = useCallback((): void => {
    trailerDialogRef.current?.close()
  }, [])

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Fleet:trailersTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateTrailerDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailersTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const { companyId } = useOwnUser()

  const cellRenderer = (column: any, row: any): JSX.Element => {
    if (column.dataKey === 'category')
      return (
        <Chip
          color={'primary'}
          label={row['category']}
        />
      )
    if (column.dataKey === 'ownership')
      return (
        <Typography>
          {row['isCarrierOwned'] ?
            t('Fleet:trailersTable.contractorsProperty')
          : t('Fleet:trailersTable.companysProperty')}
        </Typography>
      )
    if (column.dataKey === 'usedBy') {
      return (
        <UsedByCell
          driverId={row['usedBy']}
          companyId={companyId}
        />
      )
    }
    if (column.dataKey === 'options') {
      if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
        return (
          <Tooltip title={t('Common:documentRequiresAttention')}>
            {/* @ts-ignore */}
            <WarningIcon color={getFileAlertColour(row.documents)} />
          </Tooltip>
        )
      }
    }

    return row[column.dataKey]
  }

  const {
    data: trailers,
    isSuccess: isTrailersSuccess,
    refetch: refetchTrailers,
  } = useGetTrailerQuery({ companyId: companyId ?? '', archived: false }, { skip: false })
  const refresh = useRefetchWithAlert(refetchTrailers)

  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchTrailers = (phrase: string, isSearched: boolean) => {
    setIsSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedTrailers, isSuccess: isSearchTrailersSuccess } = useSearchTrailerQuery(
    { phrase: searchPhrase, archived: false },
    { skip: !isSearched },
  )

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(trailers)
    else ref.current?.open(trailers, columns)
  }, [isMobile, trailers, columns])

  useEffect(() => {
    if (isTrailersSuccess && !isSearched) ref.current?.open(trailers, columns)
  }, [isTrailersSuccess, isSearched])

  useEffect(() => {
    if (isSearchTrailersSuccess && isSearched) ref.current?.open(searchedTrailers, columns)
  }, [isSearchTrailersSuccess, isSearched, searchedTrailers])

  const confirmationDialogRef = useRef<any>(null)

  if (!isTrailersSuccess) return <Skeleton />

  return (
    <>
      <TrailerOptionsCard handleSearch={handleSearchTrailers} />
      {isMobile ?
        <ContentList
          ref={listRef}
          openDialog={handleTrailerDialogOpen}
          listComponent={TrailerCard}
        />
      : <ContentTable
          ref={ref}
          freeActions={freeActions}
          cellRenderer={cellRenderer}
          openDialog={handleTrailerDialogOpen}
        />
      }
      <SideDialog
        ref={createTrailerDialogRef}
        title={t('Fleet:createTrailerDialog.title')}
      >
        <CreateTrailerDialog
          refetch={refetchTrailers}
          handleClose={handleCreateTrailerDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={trailerDialogRef}
        title={t('Fleet:trailerDialog.title')}
      >
        <TrailerDialog
          refetch={refetchTrailers}
          handleClose={handleTrailerDialogClose}
          elementRef={trailerDialogRef}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default TrailersView
