import './style.scss'

import Logo from '@assets/img/sokar_logo.svg'
import { useTheme } from '@emotion/react'
import { useResetPasswordRequestMutation } from '@features/users/usersApiSlice'
import useServerResponse from '@hooks/useServerResponse'
import EmailIcon from '@mui/icons-material/Email'
import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import { parseError } from '@shared/utils/formatters'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetCustomerIdQuery } from '@/api/apiSlice'
import useCustomerID from '@/hooks/useCustomerID'

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [askForResetPassword] = useResetPasswordRequestMutation()
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState(false)

  const customerTag = useCustomerID()
  const { data: customerId } = useGetCustomerIdQuery(customerTag, {
    skip: !customerTag,
  })

  const { setResponseMessage, setShowResponse } = useServerResponse()

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (!customerId) return
    askForResetPassword({ email, customerId })
      .unwrap()
      .then(() => {
        setOpen(true)
      })
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setShowResponse(true)
      })
  }
  const handleClose = () => setOpen(false)
  return (
    <div className='forgot-password-container'>
      <Box className='forgot-password-form'>
        <img
          src={Logo}
          alt={"application's logo"}
          className='reset-logo'
        />
        <Typography
          variant='h5'
          component='h1'
          className='reset-title'
          sx={{
            color: appTheme.palette.primary.main,
          }}
        >
          {t('Root:resetPassword.forgot')}
        </Typography>
        <Typography
          variant='body1'
          className='reset-paragraf'
        >
          {t('Root:resetPassword.subtitle')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin='normal'
            label='Email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            className='reset-btn'
          >
            {t('Root:resetPassword.submit')}
          </Button>
        </form>
        <Typography>
          <a
            style={{
              color: appTheme.palette.primary.main,
            }}
            className='reset-back-to-login'
            href='/signin'
          >
            {t('Root:resetPassword.back')}
          </a>
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          className='email-sent-modal'
          style={{
            backgroundColor: appTheme.palette.background.default,
          }}
        >
          <EmailIcon
            className='email-icon'
            sx={{
              color: appTheme.palette.primary.main,
            }}
          />
          <Typography
            className='modal-title'
            variant='h6'
            component='h2'
          >
            {t('Root:resetPassword.infoEmail')}
          </Typography>
          <Typography
            className='modal-message'
            variant='body2'
          >
            {t('Root:resetPassword.emailTitle')}
            {email}
          </Typography>
          <a href='/signin'>
            <Button
              variant='contained'
              color='primary'
              onClick={handleClose}
              fullWidth
            >
              {t('Root:resetPassword.btnText')}
            </Button>
          </a>
        </Box>
      </Modal>
    </div>
  )
}

export default ForgotPassword
