import { yupResolver } from '@hookform/resolvers/yup'
import PhoneNumberField from '@lib/PhoneNumberField'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material'
import { getRegion } from '@shared/utils/formatters'
import { ChangeEvent, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import appConfig from '@/config'
import SideDialog, { ISideDialogRef } from '@/lib/SideDialog'
import { initCompayDialogRequest } from '@/pkg/companies/initializers'
import { CompanyCategories, ICompany, ICompanyPartial } from '@/pkg/companies/models'
import { companyValidator } from '@/pkg/companies/validators'
import { countryCodes } from '@/pkg/countryCodes'
import { ITheme } from '@/pkg/sokarUI'

interface CompanyDialogProps {
  onCompanyCreate: (data: ICompanyPartial) => void
  onCompanyUpdate: (data: ICompanyPartial) => void
  onCompanyDelete: (id: string) => void
}

export interface ICompanyDialogRef {
  open: (open: boolean, data?: ICompany) => void
}

const CompanyDialog = forwardRef<ICompanyDialogRef, CompanyDialogProps>(
  ({ onCompanyCreate, onCompanyUpdate, onCompanyDelete }, ref) => {
    const { t } = useTranslation()
    const country: string | null = localStorage.getItem('country')
    const appTheme: ITheme = useTheme()
    const companyDialogRef = useRef<ISideDialogRef>(null)
    const [isSuspended, setIsSuspended] = useState<boolean>(false)
    const [companyInfo, setCompanyInfo] = useState<ICompany | undefined>(undefined)

    const countryCodesFormatted = countryCodes as Record<string, string>

    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: initCompayDialogRequest(),
      resolver: yupResolver(companyValidator),
    })

    const handleDeleteCompany = (id?: string) => {
      if (!id) return
      onCompanyDelete(id)
    }

    const dialogActions = useMemo(
      () => (
        <>
          <Tooltip
            title={t('Companies:dialog.delete')}
            placement={'left'}
            arrow={true}
          >
            <IconButton
              sx={{ color: appTheme.palette.text.light }}
              onClick={() => handleDeleteCompany(companyInfo?.id)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
      [appTheme.palette.text.light, companyInfo?.id, t],
    )

    useImperativeHandle(ref, () => ({
      open(isOpen, _data?: ICompany) {
        reset(initCompayDialogRequest())
        setCompanyInfo(undefined)
        setIsSuspended(false)
        if (_data) {
          setCompanyInfo(_data)
          setIsSuspended(_data.isSuspended)
          reset(_data)
        }

        if (!isOpen) {
          companyDialogRef.current?.close()
        } else {
          companyDialogRef.current?.open(_data ?? true)
        }
      },
    }))

    const onSubmit: SubmitHandler<ICompanyPartial> = (data) => {
      if (!companyInfo) {
        onCompanyCreate(data)
      }
      onCompanyUpdate({ ...companyInfo, ...data })
    }

    const CompanyForm = () => {
      return (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='companies dialog-form'
        >
          <span className='col'>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.name')}
                  error={!!errors?.name}
                  helperText={<Box height={2}>{errors?.name?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='fullName'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.fullName')}
                  error={!!errors?.fullName}
                  helperText={<Box height={2}>{errors?.fullName?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='vatId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Companies:dialog.vatId')}
                  error={!!errors?.vatId}
                  helperText={<Box height={2}>{errors?.vatId?.message}</Box>}
                />
              )}
            />
            <Controller
              name='regon'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Companies:dialog.regon')}
                  error={!!errors?.regon}
                  helperText={<Box height={2}>{errors?.regon?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              control={control}
              name={'phone'}
              render={({ field }) => {
                const _field = { ...field, ref: undefined }
                return (
                  <PhoneNumberField
                    {..._field}
                    sx={{ flex: 1 }}
                    error={errors?.phone}
                  />
                )
              }}
            />
          </span>
          <span className='col'>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.email')}
                  error={!!errors?.email}
                  helperText={<Box height={2}>{errors?.email?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='countryCode'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel required>{t('Companies:dialog.countryCode')}</InputLabel>
                  <Select
                    label={t('Companies:dialog.countryCode')}
                    {...field}
                  >
                    {Object.keys(countryCodesFormatted)
                      .sort((a, b) =>
                        countryCodesFormatted[a].localeCompare(countryCodesFormatted[b]),
                      )
                      .filter((code) => countryCodesFormatted[code] === getRegion(country ?? ''))
                      .map((countryCode: string, index: number) => (
                        <MenuItem
                          value={countryCode}
                          key={index}
                        >
                          {t(`Common:countries.${countryCode}`)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.city')}
                  error={!!errors?.city}
                  helperText={<Box height={2}>{errors?.city?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='street'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.street')}
                  error={!!errors?.street}
                  helperText={<Box height={2}>{errors?.street?.message}</Box>}
                />
              )}
            />
            <Controller
              name='zipCode'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.zipCode')}
                  error={!!errors?.zipCode}
                  helperText={<Box height={2}>{errors?.zipCode?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='correspondence'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.correspondence')}
                  error={!!errors?.correspondence}
                  helperText={<Box height={2}>{errors?.correspondence?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='categories'
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
                <FormControl>
                  <InputLabel required>{t('Companies:dialog.categories')}</InputLabel>
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value && (Array.isArray(value) ? value : [value])}
                    name={name}
                    ref={_ref}
                    multiple
                    input={<OutlinedInput label={t('Companies:dialog.categories')} />}
                    renderValue={(selected: CompanyCategories[]) => {
                      return (
                        <Box className='companies dialog-form-chip'>
                          {(Array.isArray(selected) ? [...selected] : [selected]).map(
                            (_value: CompanyCategories) => (
                              <Chip
                                key={_value}
                                label={t(`Companies:dialog.${_value}`)}
                              />
                            ),
                          )}
                        </Box>
                      )
                    }}
                  >
                    <MenuItem value='carrier'>{t('Companies:dialog.carrier')}</MenuItem>
                    <MenuItem value='broker'>{t('Companies:dialog.broker')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='freightCurrency'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel required>{t('Companies:dialog.freightCurrency')}</InputLabel>
                  <Select
                    label={t('Companies:dialog.freightCurrency')}
                    {...field}
                  >
                    {/* @ts-ignore */}
                    {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                      (currency: string) => (
                        <MenuItem value={currency}>{t(`Common:currencies.${currency}`)}</MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name='paymentCurrency'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel required>{t('Companies:dialog.paymentCurrency')}</InputLabel>
                  <Select
                    label={t('Companies:dialog.paymentCurrency')}
                    {...field}
                  >
                    {/* @ts-ignore */}
                    {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                      (currency: string) => (
                        <MenuItem value={currency}>{t(`Common:currencies.${currency}`)}</MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name='defaultVat'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel required>{t('Companies:dialog.defaultVat')}</InputLabel>
                  <Select
                    label={t('Companies:dialog.defaultVat')}
                    {...field}
                  >
                    <MenuItem value={'0'}>0%</MenuItem>
                    <MenuItem value={'23'}>23%</MenuItem>
                    <MenuItem value={'n/a'}>{t('Companies:dialog.notApplicable')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='incomingPaymentValue'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.incomingPaymentValue')}
                  error={!!errors?.incomingPaymentValue}
                  helperText={<Box height={2}>{errors?.incomingPaymentValue?.message}</Box>}
                />
              )}
            />
            <Controller
              name='incomingPaymentCurrency'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel required>{t('Companies:dialog.incomingPaymentCurrency')}</InputLabel>
                  <Select
                    label={t('Companies:dialog.incomingPaymentCurrency')}
                    {...field}
                  >
                    {/* @ts-ignore */}
                    {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                      (currency: string) => (
                        <MenuItem value={currency}>{t(`Common:currencies.${currency}`)}</MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='outgoingPaymentValue'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.outgoingPaymentValue')}
                  error={!!errors?.outgoingPaymentValue}
                  helperText={<Box height={2}>{errors?.outgoingPaymentValue?.message}</Box>}
                />
              )}
            />
            <Controller
              name='outgoingPaymentCurrency'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel required>{t('Companies:dialog.outgoingPaymentCurrency')}</InputLabel>
                  <Select
                    label={t('Companies:dialog.outgoingPaymentCurrency')}
                    {...field}
                  >
                    {/* @ts-ignore */}
                    {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                      (currency: string) => (
                        <MenuItem value={currency}>{t(`Common:currencies.${currency}`)}</MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='incomingInvoicePaymentDays'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.incomingInvoicePaymentDays')}
                  error={!!errors?.incomingInvoicePaymentDays}
                  helperText={<Box height={2}>{errors?.incomingInvoicePaymentDays?.message}</Box>}
                />
              )}
            />
            <Controller
              name='outgoingInvoicePaymentDays'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t('Companies:dialog.outgoingInvoicePaymentDays')}
                  error={!!errors?.outgoingInvoicePaymentDays}
                  helperText={<Box height={2}>{errors?.outgoingInvoicePaymentDays?.message}</Box>}
                />
              )}
            />
          </span>
          <span className='col'>
            <Controller
              name='description'
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label={t('Companies:dialog.description')}
                    error={!!errors?.description}
                    helperText={<Box height={2}>{errors?.description?.message}</Box>}
                  />
                )
              }}
            />
          </span>
          <span className='col'>
            <Controller
              name='isSuspended'
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={name}
                      onBlur={onBlur}
                      onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        setIsSuspended(checked)
                        onChange(event, checked)
                      }}
                      checked={!!value}
                      inputRef={_ref}
                    />
                  }
                  label={t('Companies:dialog.isSuspended')}
                />
              )}
            />
          </span>
          {isSuspended && (
            <span className='col'>
              <Controller
                name='suspendedDescription'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Companies:dialog.suspendedDescription')}
                    error={!!errors?.suspendedDescription}
                    helperText={<Box height={2}>{errors?.suspendedDescription?.message}</Box>}
                  />
                )}
              />
            </span>
          )}
          <span className='btn-submit'>
            <Button
              type='submit'
              variant='contained'
              size='large'
            >
              {t('Companies:dialog.save')}
            </Button>
          </span>
        </form>
      )
    }

    return (
      <SideDialog
        ref={companyDialogRef}
        title={
          <>
            {companyInfo ? t('Companies:dialog.title.view') : t('Companies:dialog.title.create')}{' '}
            {isSuspended && (
              <Chip
                label={t('Companies:dialog.title.suspended')}
                color='error'
              />
            )}
          </>
        }
        dialogActions={companyInfo ? dialogActions : <></>}
      >
        <CompanyForm />
      </SideDialog>
    )
  },
)

CompanyDialog.displayName = 'CompanyDialog'

export default CompanyDialog
