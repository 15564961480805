import { useState, Suspense, lazy, LazyExoticComponent, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'

import { ISideDialogChildProps, ISideDialogRef } from 'src/lib/SideDialog'
import useServerResponse from '@hooks/useServerResponse'
import { parseError } from '@shared/utils/formatters'

import { Tooltip, IconButton, Button } from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import HistoryIcon from '@mui/icons-material/History'
import PaymentsIcon from '@mui/icons-material/Payments'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import { useUpdateDriverMutation } from '@/features/drivers/driversApiSlice'
import { IConfirmationDialogRef } from '@/lib/ConfirmationDialog'
import { createUpdateDriverRequest } from '@/pkg/drivers/converters'

interface DriverDialogProps extends ISideDialogChildProps {
  elementRef: MutableRefObject<ISideDialogRef>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
  refetch: () => void
  handleClose: () => void
}

type driverDialogElements = 'info' | 'history' | 'loads'

const DriverDialog: React.FC<DriverDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [updateDriver] = useUpdateDriverMutation()
  const { setResponseMessage, setIsSuccess, setShowResponse } = useServerResponse()
  const [driverId, setDriverId] = useState<string>('')

  const handleUnarchiveDriver = () => {
    const handleUnarchive = () => {
      let driver = createUpdateDriverRequest(props.data!)
      driver.archived = false
      updateDriver(driver)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Fleet:responses.driverDeleted'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.unarchiveDriverTitle'),
      text: t('Fleet:confirmationDialog.unarchiveDriverText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleUnarchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }
  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:driverDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.loads')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('loads')}
        >
          <WorkHistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.paymentHistory')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('loads')}
        >
          <PaymentsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.unarchive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleUnarchiveDriver()}
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<driverDialogElements>('info')
  const getProperElement = (
    key: driverDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    // if (key === 'history') {
    //     return lazy(() => import('./history'))
    // }
    return lazy(() => import('@app/fleet/views/DriversView/components/driverDialog/info'))
  }

  useEffect(() => {
    setElementKey('info')
    if (!!props.data) setDriverId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [driverId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent data={props.data} />
    </Suspense>
  )
}

export default DriverDialog
