import dayjs from 'dayjs'
import { useTheme } from '@emotion/react'
import { ILoad } from '@pkg/loads/models'
import styles from './offerCard.module.scss'

import { Box, Stack, Paper, Divider, Typography, Skeleton } from '@mui/material'

import PaidIcon from '@mui/icons-material/Paid'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface IOfferCardProps {
  data: ILoad
  openDialog: (row: any) => void
}

const ClientOfferCard = ({ ...props }: IOfferCardProps): JSX.Element => {
  const appTheme: any = useTheme()

  if (!props.data) return <Skeleton height={200} />

  return (
    <Paper
      className={styles.wrapper}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Typography>
          {props.data.stops[0].city +
            ' ' +
            props.data.stops[0].countryAbbreviation +
            ' ' +
            dayjs(props.data.stops[0].date).format('YYYY-MM-DD')}
        </Typography>
        <KeyboardArrowDownIcon className={styles.icon} />
        <Typography>
          {props.data.stops[props.data.stops.length - 1].city +
            ' ' +
            props.data.stops[props.data.stops.length - 1].countryAbbreviation +
            ' ' +
            dayjs(props.data.stops[props.data.stops.length - 1].date).format('YYYY-MM-DD')}
        </Typography>
        <Divider />
        <Box className={styles.footer}>
          <div>
            <PaidIcon />
            <Typography>
              {props.data.publishedPrice + ' ' + props.data.publishedPriceCurrency}
            </Typography>
          </div>
        </Box>
      </Stack>
    </Paper>
  )
}

export default ClientOfferCard
