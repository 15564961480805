import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OptionsCard from '@lib/OptionsCard'
import './style.scss'

import { Button, TextField } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'

interface IDriversOptionsCardProps {
  handleSearch: (phrase: string, isSuccess: boolean) => void
}

const DriversOptionsCard = ({ ...props }: IDriversOptionsCardProps): JSX.Element => {
  const { t } = useTranslation()
  const [phrase, setPhrase] = useState<string>('')

  const handleClear = () => {
    setPhrase('')
    props.handleSearch('', false)
  }

  return (
    <OptionsCard>
      <TextField
        value={phrase}
        className={'drivers_table_search'}
        label={t('Fleet:driversTable.searchTitle')}
        onChange={(e) => setPhrase(e.target.value)}
      />
      <Button
        variant={'contained'}
        onClick={() => props.handleSearch(phrase, true)}
        disabled={phrase === ''}
      >
        <SearchIcon />
      </Button>
      <Button variant={'contained'} onClick={() => handleClear()} disabled={phrase === ''}>
        <SearchOffIcon />
      </Button>
    </OptionsCard>
  )
}

export default DriversOptionsCard
