import { useState, MouseEvent } from 'react'
import { ITableColumn } from '@pkg/sokarUI'
import { useTheme } from '@emotion/react'
import './style.scss'

import { Box, IconButton, Popover, Typography, Divider } from '@mui/material'

import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'

const TeamCell = ({ row, column }: { row: any; column: ITableColumn }): JSX.Element => {
  const appTheme: any = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleTeamClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleTeamClose = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  if (!row[column.dataKey]) {
    return <CircleIcon sx={{ color: appTheme.palette.background.default }} />
  }

  return (
    <>
      <IconButton onClick={handleTeamClick}>
        <CircleIcon sx={{ color: row[column.dataKey].color }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleTeamClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={'team_cell_popover'}
        classes={{
          paper: 'team_cell_popover',
        }}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Box
            className={'team_cell_popover_title'}
            sx={{ color: row[column.dataKey].color }}
          >
            <Typography>{row[column.dataKey].name}</Typography>
            <IconButton onClick={handleTeamClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {row[column.dataKey].members.map((member: string, index: number) => (
            <>
              <Typography>{member}</Typography>
              {index !== row[column.dataKey].members.length - 1 && <Divider />}
            </>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default TeamCell
