import { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { FieldError, Merge } from 'react-hook-form'
import { IDriver } from '@/pkg/drivers/models'
import { useTranslation } from 'react-i18next'
import { useGetDriverQuery, useSearchDriverQuery } from '@/features/drivers/driversApiSlice'

import styles from './multipleDriversSearch.module.scss'

import {
  Box,
  Stack,
  Table,
  Paper,
  Checkbox,
  Button,
  Tooltip,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  Typography,
  Skeleton,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

interface DriversSearchProps {
  isDisabled: boolean
  onChange: any
  value: string[]
  companyId?: string
  error?: Merge<FieldError, (FieldError | undefined)[]>
}

const MultipleDriversSearch = ({ ...props }: DriversSearchProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [driverIds, setDriverIds] = useState<string[]>([])
  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [values, setValues] = useState<string[]>([])

  const {
    data: drivers,
    isSuccess: isDriversSuccess,
    refetch: refetchDrivers,
  } = useSearchDriverQuery(
    { phrase: searchPhrase, companyId: props.companyId ?? '' },
    { skip: !isSearched },
  )

  const {
    data: driver,
    isSuccess: isDriverSuccess,
    isFetching: isDriverFetching,
  } = useGetDriverQuery(
    {
      driverId: '',
      companyId: props.companyId ?? '',
    },
    { skip: driverIds.length === 0 || isSearched },
  )

  useEffect(() => {
    if (props.value) setDriverIds(props.value)
  }, [props.value])

  const handleSearch = (): void => {
    if (!isSearched) setIsSearched(true)
    else refetchDrivers()
  }

  const handleDriversChange = (e: any): void => {
    if (values.includes(e.target.value)) {
      setValues((prev) => prev.filter((item) => item !== e.target.value))
      props.onChange(values.filter((item) => item !== e.target.value))
      return
    }

    setValues((prev) => [...prev, e.target.value])
    props.onChange([...values, e.target.value])
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault() // Prevent default behavior of form submission
    }
  }

  return (
    <>
      <TableContainer
        component={Paper}
        className={!!props.error ? styles.wrapperError : styles.wrapper}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Stack
          direction={'row'}
          spacing={2}
        >
          <TextField
            label={t('Driver')}
            sx={{ flex: 1 }}
            disabled={props.isDisabled}
            value={searchPhrase}
            onChange={(e) => setSearchPhrase(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Tooltip title={t('Common:search')}>
            <Button
              variant={'contained'}
              onClick={handleSearch}
              disabled={searchPhrase.length === 0 || props.isDisabled}
            >
              <SearchIcon />
            </Button>
          </Tooltip>
        </Stack>
        <Table>
          <TableBody>
            {(isDriverFetching || isDriverFetching) && (
              <>
                <Skeleton height={75} />
                <Skeleton height={75} />
                <Skeleton height={75} />
                <Skeleton height={75} />
                <Skeleton height={75} />
              </>
            )}
            {isDriversSuccess &&
              drivers.length > 0 &&
              drivers.map((driver: IDriver) => (
                <TableRow
                  key={driver.id}
                  className={styles.tableRow}
                >
                  <TableCell>
                    <Checkbox
                      value={driver.id}
                      disabled={props.isDisabled}
                      onClick={(e) => handleDriversChange(e)}
                    />
                  </TableCell>
                  <TableCell>
                    {driver.firstName} {driver.lastName}
                  </TableCell>
                </TableRow>
              ))}
            {isDriverSuccess &&
              driver.length > 0 &&
              driver.map((driver: IDriver) => (
                <TableRow
                  key={driver.id}
                  className={styles.tableRow}
                >
                  <TableCell>
                    <Checkbox
                      value={driver.id}
                      checked={props.value.includes(driver.id)}
                      disabled={props.isDisabled}
                      onClick={(e) => handleDriversChange(e)}
                    />
                  </TableCell>
                  <TableCell>
                    {driver.firstName} {driver.lastName}
                  </TableCell>
                </TableRow>
              ))}
            {isSearched && driver?.length == 0 && (
              <Box className={styles.noMatch}>
                <Typography>{t('Common:noMatch')}</Typography>
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ color: appTheme.palette.error.main }}>{props.error?.message}</Typography>
    </>
  )
}

export default MultipleDriversSearch
