import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, TextField, Button, Container, Typography } from '@mui/material'
import { parseError } from '@shared/utils/formatters'
import { useProvideNePasswordForPasswordResetMutation } from 'src/features/users/usersApiSlice'
import useServerResponse from 'src/hooks/useServerResponse'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import useCustomerID from '@/hooks/useCustomerID'
import { useGetCustomerIdQuery } from '@/api/apiSlice'
import { useLocation } from 'react-router'
import { resetSchema } from '@pkg/users/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import './styles.scss'
import Logo from '@assets/img/sokar_logo.svg'

interface NewPasswordRequest {
  id: string
  newPassword: string
  passwordCheck: string
}

const NewPassword: React.FC = () => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const location = useLocation()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordRequest>({
    resolver: yupResolver(resetSchema),
  })
  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()
  const [resetPassword] = useProvideNePasswordForPasswordResetMutation()

  const customerTag = useCustomerID()
  const { data: customerId } = useGetCustomerIdQuery(customerTag, { skip: !customerTag })

  const onSubmit = (data: NewPasswordRequest) => {
    const id = location.pathname.split('/').pop()
    if (!customerId || !id) {
      return
    }
    data.id = id
    resetPassword(data)
      .unwrap()
      .then((res) => {
        setShowResponse(true)
        setIsSuccess(true)
        setResponseMessage(t('Root:resetPassword.newPasswordSuccess'))
      })
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setShowResponse(true)
      })
  }

  return (
    <Container
      className='register-container'
      sx={{ mt: 4 }}
    >
      <img
        src={Logo}
        alt="application's logo"
        className='register-logo'
      />
      <Typography
        component='h1'
        variant='h5'
        className='reset-title'
        sx={{
          color: appTheme.palette.primary.main,
          mb: 3,
        }}
      >
        {t('Root:resetPassword.NewPasswordTitle')}
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className='register-form'
      >
        <Controller
          control={control}
          name={'newPassword'}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Root:resetPassword.newPassword')}
              type='password'
              variant='outlined'
              fullWidth
              margin='normal'
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={'passwordCheck'}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Root:resetPassword.repeatPassword')}
              type='password'
              variant='outlined'
              fullWidth
              margin='normal'
              error={!!errors.passwordCheck}
              helperText={errors.passwordCheck?.message}
            />
          )}
        />
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className='register-button'
          sx={{ mt: 2 }}
          fullWidth
        >
          {t('Root:resetPassword.btn')}
        </Button>
      </form>
      <Typography>
          <a
            style={{
              color: appTheme.palette.primary.main,
            }}
            className='newpassword-title'
            href='/signin'
          >
            {t('Root:resetPassword.back')}
          </a>
        </Typography>
    </Container>
  )
}

export default NewPassword
