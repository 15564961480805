import { useContext } from 'react'
import { t } from 'i18next'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import ServerResponseContext from '../context/ServerResponseProvider'

const useRefetchWithAlert = <T extends () => Promise<QueryReturnValue<any>>>(refresh: any): T => {
  const { setIsSuccess, setShowResponse, setResponseMessage } = useContext(ServerResponseContext)

  const enhancedFunction = () => {
    refresh().then((res: any) => {
      if (res.data) {
        setIsSuccess(true)
        setShowResponse(true)
        setResponseMessage(t('Common:UI.responses.dataRefreshed'))
      }
      if (res.error) {
        setIsSuccess(false)
        setShowResponse(true)
        setResponseMessage(t('Common:UI.responses.dataNotRefreshed'))
      }
    })
  }

  return enhancedFunction as T
}

export default useRefetchWithAlert
