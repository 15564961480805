/* eslint-disable i18n-text/no-en */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import appConfig from '@/config'
import {
  selectCurrentToken,
  selectExpiryTimestamp,
  selectRefreshToken,
  setCredentials,
  signOut,
} from 'src/features/auth/authSlice'

import { ICustomer } from '@/pkg/users/models'

const baseQuery = fetchBaseQuery({
  baseUrl: appConfig.SERVER_URL,
  mode: 'cors',
  prepareHeaders: (headers, { getState }: any) => {
    const { token } = getState().auth
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryReauth = async (args: any, api: any, extraOptions: any) => {
  let _args = args
  const { customerId } = api.getState().auth
  if (['GET', 'DELETE'].includes(_args.method?.toUpperCase()) || _args.method === undefined) {
    let hasQueryParams = false
    const isStringArgsWithQuery = typeof _args === 'string' && _args.includes('?')
    const isUrlWithQuery = _args.url?.includes('?')

    if (isStringArgsWithQuery || isUrlWithQuery) {
      hasQueryParams = true
    }

    if (typeof _args === 'string' && hasQueryParams) {
      _args += `&customerId=${customerId}`
    } else if (typeof _args === 'string') {
      _args += `?customerId=${customerId}`
    }

    if (typeof _args != 'string' && hasQueryParams) {
      _args.url += `&customerId=${customerId}`
    } else if (typeof _args != 'string') {
      _args.url += `?customerId=${customerId}`
    }
  }
  if (['POST', 'PUT'].includes(_args.method?.toUpperCase()) && !_args.body.customerId) {
    _args.body = {
      ..._args.body,
      customerId,
    }
  }
  if (selectCurrentToken(api.getState())) {
    const expiresAt = selectExpiryTimestamp(api.getState())
    const currentTime = new Date()
    const options = { timeZone: 'utc' }
    const utcTime = currentTime.toLocaleString('en-US', options)
    const currentUTCTime = new Date(utcTime)
    const expiresIn = expiresAt ? expiresAt - currentUTCTime.getTime() : -currentUTCTime.getTime()
    if (expiresIn <= 10 * 60 * 1000) {
      const refreshResult = await baseQuery(
        {
          url: '/users/refresh-session',
          method: 'POST',
          body: {
            refreshToken: selectRefreshToken(api.getState()),
          },
        },
        api,
        extraOptions,
      )
      if (refreshResult?.data) {
        api.dispatch(setCredentials({ ...refreshResult.data, customerId }))
      } else {
        api.dispatch(signOut())
      }
    }
  }
  return baseQuery(args, api, extraOptions)
}

export const apiSlice = createApi({
  reducerPath: 'sokarAPI',
  baseQuery: baseQueryReauth,
  tagTypes: ['companies', 'subscriptions', 'subscriptionsExternal'],
  endpoints: () => ({}),
})

export const portalApiSlice = createApi({
  reducerPath: 'portalAPI',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.PORTAL_API_URL }),
  endpoints: (builder) => ({
    getCustomerId: builder.query<string, string>({
      query: (customerTag) => `/portal/customer-id?customerTag=${customerTag}`,
      transformResponse: (response: { customerId: string }) => {
        return response.customerId
      },
    }),
    getCustomer: builder.query<ICustomer, string>({
      query: (customerId) => `/portal/customers/${customerId}`,
    }),
  }),
})

export const { useGetCustomerIdQuery, useGetCustomerQuery } = portalApiSlice
