import { useState, Suspense, lazy, LazyExoticComponent, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { useUpdateLoadMutation } from '@features/loads/loadsApiSlice'
import { ISideDialogChildProps } from 'src/lib/SideDialog'
import useServerResponse from '@hooks/useServerResponse'
import { createUpdateLoadRequest } from '@pkg/loads/converters'
import RequireRole from '@features/auth/RequireRole'
import { parseError } from '@shared/utils/formatters'
import { CreateFromTemplate, InvoiceDetails } from '@pkg/documents/requests'
import { convertCompanyToInvoiceDetails } from '@pkg/documents/utils'
import styles from './detailsDialog.module.scss'
import useOwnUser from '@hooks/useOwnUser'
import { useGetCompaniesQuery } from '@features/companies/companiesApiSlice'
import {
  useCreateDocumentMutation,
  useGetDocumentContentMutation,
} from '@features/documents/documentsApiSlice'

import { Tooltip, IconButton, Button, TextField, MenuItem } from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { ICompany } from '@/pkg/companies/models'

interface DialogProps extends ISideDialogChildProps {
  elementRef: any
  confirmationRef: any
  refetch: () => void
  handleClose: () => void
}

type dialogElements = 'info' | 'history' | 'edit'

const DetailsDialog: React.FC<DialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [updateLoad] = useUpdateLoadMutation()
  const { companyId: ownCompanyId } = useOwnUser()
  const [loadId, setLoadId] = useState<string>('')
  const [generateDocument] = useCreateDocumentMutation()
  const [getDocumentsContent] = useGetDocumentContentMutation()
  const [elementKey, setElementKey] = useState<dialogElements>('info')
  const { setResponseMessage, setIsSuccess, setShowResponse } = useServerResponse()

  const handleStatusChange = () => {
    let loadData = createUpdateLoadRequest(props.data)
    const handleChange = () => {
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Accounting:responses.transportStatusUpdated'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.changeStatus'),
      text: (
        <TextField
          select
          defaultValue={loadData.invoiceStatus}
          className={styles.select}
          onChange={(e) => {
            loadData.invoiceStatus = e.target.value
          }}
        >
          <MenuItem value={'ready'}>{t('Accounting:statuses.ready')}</MenuItem>
          <MenuItem value={'awaiting-rev-rc'}>{t('Accounting:statuses.awaiting-rev-rc')}</MenuItem>
          <MenuItem value={'awaiting-better-paperwork'}>
            {t('Accounting:statuses.awaiting-better-paperwork')}
          </MenuItem>
          <MenuItem value={'invoiced'}>{t('Accounting:statuses.invoiced')}</MenuItem>
        </TextField>
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>
          {t('Common:cancel')}
        </Button>,
        <Button onClick={() => handleChange()}>{t('Common:confirm')}</Button>,
      ],
    })
  }

  // DO NOT TOUCH!!! I hate JavaScript
  const [buyerState, setBuyerState] = useState<ICompany[] | undefined>(undefined)
  const { data: buyer } = useGetCompaniesQuery({ id: props.data.shipper.id, page: 0, pageSize: 1 })
  useEffect(() => {
    setBuyerState(buyer)
  }, [buyer])

  const generateInvoice = useCallback(() => {
    if (!buyerState || buyerState.length != 1) {
      return
    }

    let data: Partial<CreateFromTemplate> = {
      templateId: 'c5c8155f-fbad-4cf2-9bac-8dd3a526cafc',
      category: 'invoice',
      loadId: [props.data.id],
      companyId: [ownCompanyId],
    }
    data.invoiceDetails = {
      ...convertCompanyToInvoiceDetails(buyerState[0]),
      ...data.invoiceDetails,
    } as InvoiceDetails

    setResponseMessage(String(t('Accounting:responses.startingFileGeneration')))
    setIsSuccess(true)
    setShowResponse(true)

    generateDocument(data as CreateFromTemplate)
      .unwrap()
      .then((res) => {
        getDocumentsContent(res.id)
          .unwrap()
          .then((content) => {
            fetch('data:application/pdf;base64,' + content.content)
              .then((res) => res.blob())
              .then((file) => {
                const url = URL.createObjectURL(file)
                const a = document.createElement('a')
                a.href = url
                a.download = res.id
                a.style.display = 'none'

                document.body.appendChild(a)
                a.click()

                document.body.removeChild(a)
                URL.revokeObjectURL(url)
              })
              .catch(() => {
                setResponseMessage(String(t('Accounting:responses.failedToDownload')))
                setIsSuccess(false)
                setShowResponse(true)
              })
              .then(() => {
                setResponseMessage(String(t('Accounting:responses.fileDownloaded')))
                setIsSuccess(true)
                setShowResponse(true)
              })
              .then(() => {
                props.refetch()
              })
          })
      })
      .then(() => {
        let loadData = createUpdateLoadRequest(props.data)
        loadData.invoiceStatus = 'invoiced'
        updateLoad(loadData)
      })
      .then(() => {
        props.handleClose()
      })
      .then(() => {
        props.refetch()
      })
      .catch((err) => {
        const error = parseError<any>(err)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
      .then(() => {
        setResponseMessage(String(t('Accounting:responses.invoiceGenerated')))
        setIsSuccess(true)
        setShowResponse(true)
      })
  }, [buyerState])

  const dialogActions = (
    <>
      <Tooltip
        title={t('Accounting:loadDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <RequireRole allowedRoles={['accountant']}>
        <Tooltip
          title={t('Accounting:loadDialog.changeStatus')}
          placement={'left'}
          arrow={true}
        >
          <IconButton
            sx={{ color: appTheme.palette.text.light }}
            onClick={() => handleStatusChange()}
          >
            <TrackChangesIcon />
          </IconButton>
        </Tooltip>
      </RequireRole>
      <RequireRole allowedRoles={['accountant']}>
        <Tooltip
          title={t('Accounting:loadDialog.generateInvoice')}
          placement={'left'}
          arrow={true}
        >
          <IconButton
            sx={{ color: appTheme.palette.text.light }}
            onClick={() => generateInvoice()}
          >
            <NoteAddIcon />
          </IconButton>
        </Tooltip>
      </RequireRole>
      <RequireRole allowedRoles={['accountant']}>
        <Tooltip
          title={t('Accounting:loadDialog.edit')}
          placement={'left'}
          arrow={true}
        >
          <IconButton
            sx={{ color: appTheme.palette.text.light }}
            onClick={() => setElementKey('edit')}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </RequireRole>
      <Tooltip
        title={t('Accounting:loadDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const getProperElement = (key: dialogElements): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('@app/fleet/views/CurrentLoads/components/LoadDialog/edit'))
      // case 'history':
      //     return lazy(() => import('./history'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    if (!!props.data) setLoadId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [loadId, buyer, buyerState])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        handleClose={props.handleClose}
      />
    </Suspense>
  )
}

export default DetailsDialog
