import './styles.scss'

import AddIcon from '@mui/icons-material/Add'
import { Box, Skeleton } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useAddSubscriptionMutation,
  useDeleteSubscriberMutation,
  useGetMarketExternalQuery,
  useGetMarketQuery,
  useUpdateSubscriberMutation,
} from '@/features/market/marketApiSlice'
import useServerResponse from '@/hooks/useServerResponse'
import { IMarket } from '@/pkg/market/models'
import { IAddSubscriptionRequest } from '@/pkg/market/requests'
import { parseError } from '@/shared/utils/formatters'

import MarketTab from './components/MarketTab'

const Market = () => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState(0)
  const [subscriptionId, setSubscriptionId] = useState<string | undefined>(undefined)

  const {
    data: subscriptions,
    isLoading: isLoadingMarket,
    isSuccess,
  } = useGetMarketQuery(undefined)

  const {
    data: externalLoads,
    // isLoading: isLoadingMarkets, //TODO need to test with table
    refetch,
  } = useGetMarketExternalQuery(subscriptionId, { pollingInterval: 60000, skip: !subscriptionId })

  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()

  const [createMarketQuery] = useAddSubscriptionMutation()
  const [updateMarketQuery] = useUpdateSubscriberMutation()
  const [deleteMarketQuery] = useDeleteSubscriberMutation()

  useEffect(() => {
    subscriptions?.[0]?.id && setSubscriptionId(subscriptions[0].id)
  }, [isSuccess, subscriptions])

  const isLoading = isLoadingMarket

  const handleChangeTab = (newValue: number) => {
    setCurrentTab(newValue)
    setSubscriptionId(subscriptions?.[newValue - 1].id)
  }

  useEffect(() => {
    if (!subscriptions || subscriptions.length < 1) return
    handleChangeTab(subscriptions.length)
  }, [subscriptions])

  const handleCreateMarketQuery = (req?: IAddSubscriptionRequest) => {
    if (!req) return
    createMarketQuery(req)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Market:response.success.create'))
        setIsSuccess(true)
        setShowResponse(true)
        refetch()
      })
      .catch((err) => {
        const error = parseError<object>(err)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const handleChangeMarketQuery = (req?: IAddSubscriptionRequest) => {
    if (!req) return
    updateMarketQuery(req)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Market:response.success.change'))
        setIsSuccess(true)
        setShowResponse(true)
        refetch()
      })
      .catch((err) => {
        const error = parseError<object>(err)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const handleDeleteMarketQuery = (id?: string) => {
    if (!id) return
    deleteMarketQuery(id)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Market:response.success.delete'))
        setIsSuccess(true)
        setShowResponse(true)
        refetch()
      })
      .catch((err) => {
        const error = parseError<object>(err)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
    if (subscriptions?.length === 1 || !subscriptions) {
      handleChangeTab(0)
    }
  }

  const mapHeaderQueriesToTabs = () => {
    if (!subscriptions?.length) {
      return (
        <Tab
          label={t('Market:tab.createQuery')}
          icon={<AddIcon />}
          iconPosition='start'
        />
      )
    }
    const marketHeaders = []
    marketHeaders.push(
      <Tab
        label={t('Market:tab.createQuery')}
        icon={<AddIcon />}
        iconPosition='start'
        key={0}
      />,
    )
    let idx = 0
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const subscription of subscriptions) {
      marketHeaders.push(
        <Tab
          label={`${t('Market:tab.tab')} ${idx + 1}`}
          key={idx + 1}
        />,
      )
      idx = idx + 1
    }
    return marketHeaders
  }

  const mapContentToTabs = () => {
    return (
      <>
        <MarketTab
          selectedIndex={currentTab}
          index={0}
          onCreateMarketQuery={handleCreateMarketQuery}
          onUpdateMarketQuery={handleChangeMarketQuery}
          onDeleteMarketQuery={handleDeleteMarketQuery}
          externalLoads={externalLoads}
        />
        {subscriptions?.map((subscription: IMarket, index: number) => {
          return (
            <MarketTab
              selectedIndex={currentTab}
              index={index + 1}
              key={index + 1}
              onCreateMarketQuery={handleCreateMarketQuery}
              onUpdateMarketQuery={handleChangeMarketQuery}
              onDeleteMarketQuery={handleDeleteMarketQuery}
              subscription={subscription}
              externalLoads={externalLoads}
            />
          )
        })}
      </>
    )
  }

  if (isLoading) {
    const loaders = []
    for (let i = 0; i < 10; i++) {
      loaders.push(
        <Skeleton
          animation='wave'
          key={i}
          sx={{ width: '100%' }}
        />,
      )
    }
    return <Box className='market page-content loaders'>{loaders}</Box>
  }

  return (
    <Box className='market page-content'>
      <Box className='market page-header'>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentTab}
            onChange={(_, idx: number) => handleChangeTab(idx)}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            aria-label={`market-outer-${currentTab}`}
          >
            {mapHeaderQueriesToTabs()}
          </Tabs>
        </Box>
      </Box>
      {mapContentToTabs()}
    </Box>
  )
}

export default Market
