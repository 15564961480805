import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { ITrailer } from '@/pkg/equipment/models'
import UsedByCell from '../usedByCell'
import './style.scss'

import { Box, Stack, Paper, Divider, Typography } from '@mui/material'

import PersonIcon from '@mui/icons-material/Person'
import NumbersIcon from '@mui/icons-material/Numbers'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

interface ITrailerCardProps {
  data: ITrailer
  openDialog: (row: any) => void
}

const TrailerCard = ({ ...props }: ITrailerCardProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()

  return (
    <Paper
      className={'trailer_card_wrapper'}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Typography>
          {t(`Fleet:trailerCard.${props.data.isCarrierOwned ? 'contractor' : 'company'}`)}
        </Typography>
        <Divider />
        <Box className={'trailer_card_text'}>
          <NumbersIcon />
          {props.data.number}
        </Box>
        <Box className={'trailer_card_text'}>
          <LocalShippingIcon />
          {props.data.plate}
        </Box>
        <Box className={'trailer_card_text'}>
          <PersonIcon />
          {props.data.usedBy && (
            <UsedByCell
              driverId={props.data.usedBy}
              companyId={props.data.companyId}
            />
          )}
        </Box>
      </Stack>
    </Paper>
  )
}

export default TrailerCard
