import { parsePhoneNumber } from '@/shared/utils/formatters'
import { ICompany } from '../companies/models'
import { Buyer } from './requests'

export const convertCompanyToInvoiceDetails = (company: ICompany): Buyer => {
  return {
    buyerName: company.fullName,
    buyerAddress: company.correspondence,
    buyerCity: company.city,
    buyerZipCode: company.zipCode,
    buyerPhone: parsePhoneNumber(company.phone),
    buyerEmail: company.email,
    buyerVatId: company.vatId,
    secondaryCurrency: company.incomingPaymentCurrency,
  }
}
