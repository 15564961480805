import { useState, Suspense, lazy, LazyExoticComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'

import { ITruck } from '@pkg/equipment/models'

import { Tooltip, IconButton, Button } from '@mui/material'
import useServerResponse from '@hooks/useServerResponse'
import {
  useDeleteTruckMutation,
  useUpdateTruckMutation,
} from '@/features/equipment/equipmentApiSlice'
import { parseError } from '@/shared/utils/formatters'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import HistoryIcon from '@mui/icons-material/History'
import AddLinkIcon from '@mui/icons-material/AddLink'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { createUpdateTruckRequest } from '@pkg/equipment/converters'

type trailerDialogElements = 'info' | 'edit' | 'history' | 'link'

export interface TruckDialogProps {
  trailerData: ITruck | null
}

const TruckDialog: React.FC<any> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [truckId, setTruckId] = useState<string>('')
  const [deleteTruck] = useDeleteTruckMutation()
  const [updateTruck] = useUpdateTruckMutation()
  const { setResponseMessage, setShowResponse, setIsSuccess } = useServerResponse()

  const handleDeleteTruck = (id: string) => {
    const handleDelete = (id: string) => {
      deleteTruck(id)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Fleet:responses.truckDeleted'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.deleteTruckTitle'),
      text: t('Fleet:confirmationDialog.deleteTruckText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleArchiveTruck = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      let truck = createUpdateTruckRequest(props.data)
      truck.archived = true
      updateTruck(truck)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Fleet:responses.trailerUpdated'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveTruckTitle'),
      text: t('Fleet:confirmationDialog.archiveTruckText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:truckDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.createLink')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('link')}
        >
          <AddLinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleDeleteTruck(truckId)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleArchiveTruck()}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<trailerDialogElements>('info')
  const getProperElement = (
    key: trailerDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      case 'link':
        return lazy(() => import('./link'))
      case 'history':
        return lazy(() => import('./history'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (!!props.data) setTruckId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [truckId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
      />
    </Suspense>
  )
}

export default TruckDialog
