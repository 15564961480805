import { t } from 'i18next'
import { array, date, number, object, string } from 'yup'

export const marketValidators = object().shape({
  originCords: array()
    .of(number())
    .required(t('Market:validatorResponses.originCordsReq') ?? ''),
  originRadius: number()
    .required(t('Market:validatorResponses.radiusReq') ?? '')
    .min(0, t('Market:validatorResponses.radiusMin') ?? ''),
  originRadiusUnit: string().required(t('Market:validatorResponses.radiusUnitReq') ?? ''),
  originAppointmentStart: date().required(
    t('Market:validatorResponses.originAppointmentStartReq') ?? '',
  ),
  originAppointmentEnd: date().required(
    t('Market:validatorResponses.originAppointmentEndReq') ?? '',
  ),

  destinationCords: array()
    .of(number())
    .required(t('Market:validatorResponses.destinationCordsReq') ?? ''),
  destinationRadius: number()
    .required(t('Market:validatorResponses.radiusReq') ?? '')
    .min(0, t('Market:validatorResponses.radiusMin') ?? ''),
  destinationRadiusUnit: string().required(t('Market:validatorResponses.radiusUnitReq') ?? ''),
  destinationAppointmentStart: date().required(
    t('Market:validatorResponses.destinationAppointmentStartReq') ?? '',
  ),
  destinationAppointmentEnd: date().required(
    t('Market:validatorResponses.destinationAppointmentEndReq') ?? '',
  ),
  equipmentType: string().required(t('Market:validatorResponses.equipmentTypeReq') ?? ''),
  mode: string().required(t('Market:validatorResponses.modeReq') ?? ''),
})
