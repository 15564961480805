import './styles.scss'

import {
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useSearchCompaniesQuery,
  useUpdateCompanyMutation,
} from '@features/companies/companiesApiSlice'
import ConfirmationDialog from '@lib/ConfirmationDialog'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Paper, Skeleton, TextField, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableVirtuoso } from 'react-virtuoso'

import appConfig from '@/config'
import useServerResponse from '@/hooks/useServerResponse'
import { ICompany, ICompanyPartial, IFormattedCompany } from '@/pkg/companies/models'
import { ICreateCompanyRequest } from '@/pkg/companies/requests'
import { parseError } from '@shared/utils/formatters'

import {
  CompaniesFixedHeaderContent,
  CompaniesRowContent,
  CompaniesVirtuosoTableComponents,
} from './components/CompaniesTable'
import CompanyDialog, { ICompanyDialogRef } from './components/CompanyDialog'

const Companies = () => {
  const { t } = useTranslation()
  const companyDialogRef = useRef<ICompanyDialogRef>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const confirmationRef = useRef<any>(null)

  const [page, setPage] = useState<number>(1)
  const [nextPage, setNextPage] = useState<null | number>(null)
  const [lastDataLen, setLastDataLen] = useState<number>(0)

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    refetch,
    isFetching,
  } = useGetCompaniesQuery({ page, pageSize: appConfig.PAGE_SIZE })

  const { data: companiesSearch } = useSearchCompaniesQuery(
    {
      phrase: searchQuery,
      page,
      pageSize: appConfig.PAGE_SIZE,
    },
    { skip: searchQuery === '' },
  )

  const isLoading = isLoadingCompanies

  const [createCompany] = useCreateCompanyMutation()
  const [updateCompany] = useUpdateCompanyMutation()
  const [deleteCompany] = useDeleteCompanyMutation()

  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  useEffect(() => {
    if (nextPage !== null && !isFetching) {
      setPage(nextPage)
      setNextPage(null)
    }
  }, [nextPage, isFetching])

  const handleEndReached = useCallback(() => {
    if (!isFetching && companies?.length !== lastDataLen && searchQuery !== '') {
      setNextPage(page + 1)
      setLastDataLen(companies?.length ?? 0)
    }
  }, [companies?.length, isFetching, lastDataLen, page])

  const mapCompaniesToRows = useCallback((): IFormattedCompany[] => {
    if (!companies) return [] as IFormattedCompany[]

    const formattedCompanies: IFormattedCompany[] = []
    for (const company of companies) {
      formattedCompanies.push({
        address: `${String(company.countryCode)} ${company.city}`,
        ...company,
      })
    }
    return formattedCompanies
  }, [companies])

  const mapCompaniesSearchToRows = useCallback((): IFormattedCompany[] => {
    if (!companiesSearch) return [] as IFormattedCompany[]

    const formattedCompanies: IFormattedCompany[] = []
    for (const company of companiesSearch) {
      formattedCompanies.push({
        address: `${String(company.countryCode)} ${company.city}`,
        ...company,
      })
    }
    return formattedCompanies
  }, [companiesSearch])

  const handleCompanyCreate = (data: ICompanyPartial) => {
    if (!data) return
    delete data.id
    const tmp = data
    if (tmp.categories && !Array.isArray(tmp.categories)) {
      tmp.categories = [tmp.categories]
    }
    createCompany(data as ICreateCompanyRequest)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Companies:response.success.create'))
        setIsSuccess(true)
        setShowResponse(true)
        refetch()
      })
      .then(() => companyDialogRef.current?.open(false, undefined))
      .catch((err) => {
        const error = parseError<object>(err)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const handleCompanyUpdate = (data: ICompanyPartial) => {
    if (!data) return
    const tmp = data
    if (tmp.categories && !Array.isArray(tmp.categories)) {
      tmp.categories = [tmp.categories]
    }
    updateCompany(tmp as ICompany)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Companies:response.success.update'))
        setIsSuccess(true)
        setShowResponse(true)
        refetch()
      })
      .then(() => companyDialogRef.current?.open(false, undefined))
      .catch((err) => {
        const error = parseError<object>(err)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const handleCompanyDelete = (id: string) => {
    const handleDelete = (_id: string) => {
      if (!_id) return
      deleteCompany(_id)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Companies:response.success.delete'))
          setIsSuccess(true)
          setShowResponse(true)
          refetch()
        })
        .then(() => confirmationRef.current?.close())
        .then(() => companyDialogRef.current?.open(false, undefined))
        .catch((err) => {
          const error = parseError<object>(err)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
        })
    }

    confirmationRef.current?.open({
      title: t('Companies:confirmationDialog.deleteCompanyTitle'),
      text: t('Companies:confirmationDialog.deleteCompanyText'),
      actions: [
        <Button onClick={() => confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target.value) {
      setSearchQuery('')
    } else {
      setPage(1)
      setNextPage(null)
      setSearchQuery(e.target.value)
    }
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 200)
  }, [])

  if (isLoading) {
    const loaders = []
    for (let i = 0; i < 10; i++) {
      loaders.push(
        <Skeleton
          animation='wave'
          key={i}
          sx={{ width: '100%' }}
        />,
      )
    }
    return <Box className='companies page-content loaders'>{loaders}</Box>
  }

  if (!companies) {
    return (
      <Box className='companies page-content error'>
        <Typography>{t('Common:error.noContent')}</Typography>
        <Button
          variant='contained'
          onClick={() => companyDialogRef.current?.open(true, undefined)}
          endIcon={<AddIcon />}
        >
          {t('Companies:createCompany')}
        </Button>
      </Box>
    )
  }

  return (
    <>
      <CompanyDialog
        ref={companyDialogRef}
        onCompanyCreate={handleCompanyCreate}
        onCompanyUpdate={handleCompanyUpdate}
        onCompanyDelete={handleCompanyDelete}
      />
      <Box className='companies page-content'>
        <Box className='companies page-header'>
          <TextField
            type='search'
            onChange={debouncedResults}
            placeholder={t('Companies:search') ?? ''}
          />
          <Button
            variant='contained'
            onClick={() => companyDialogRef.current?.open(true, undefined)}
            endIcon={<AddIcon />}
          >
            {t('Companies:createCompany')}
          </Button>
        </Box>
        <Paper className='companies table'>
          <TableVirtuoso
            data={searchQuery !== '' ? mapCompaniesSearchToRows() : mapCompaniesToRows()}
            components={CompaniesVirtuosoTableComponents}
            fixedHeaderContent={CompaniesFixedHeaderContent}
            itemContent={(_, row: IFormattedCompany) =>
              CompaniesRowContent(_, row, companyDialogRef)
            }
            endReached={handleEndReached}
          />
        </Paper>
      </Box>
      <ConfirmationDialog ref={confirmationRef} />
    </>
  )
}

export default Companies
