import { useSearchCompaniesQuery } from '@features/companies/companiesApiSlice'
import { useApproveAskMutation, useDeleteAskMutation } from '@features/users/usersApiSlice'
import useOwnUser from '@hooks/useOwnUser'
import useServerResponse from '@hooks/useServerResponse'
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { ITheme } from '@pkg/sokarUI'
import { IApproveAskRequest } from '@pkg/users/requests'
import { parseError, parsePhoneNumber } from '@shared/utils/formatters'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CompanySearch from 'src/shared/components/companySearch'
import i18n from 'i18next'

import appConfig from '@/config'

import styles from './askDialog.module.scss'

const RegisterAskDialog = ({ ...props }): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  const { id: userId } = useOwnUser()
  const [claimedVatNumber, setClaimedVatNumber] = useState<string>('')
  const [approveRequest] = useApproveAskMutation()
  const [rejectRequest] = useDeleteAskMutation()

  const [page, setPage] = useState<number>(1)
  const [nextPage, setNextPage] = useState<null | number>(null)
  const [lastDataLen, setLastDataLen] = useState<number>(0)

  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()
  const { data: companies, isFetching } = useSearchCompaniesQuery(
    {
      phrase: claimedVatNumber,
      page,
      pageSize: appConfig.PAGE_SIZE,
    },
    { skip: !claimedVatNumber },
  )

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IApproveAskRequest>()

  useEffect(() => {
    if (props.data?.vatNumber) setClaimedVatNumber(props.data.vatNumber)
  }, [props.data])

  //TODO TEMP FIX, table needs to handle handleEndReached hook

  useEffect(() => {
    if (nextPage !== null && !isFetching) {
      setPage(nextPage)
      setNextPage(null)
    }
  }, [nextPage, isFetching])

  const handleEndReached = useCallback(() => {
    if (!isFetching && companies?.length !== lastDataLen) {
      setNextPage(page + 1)
      setLastDataLen(companies?.length ?? 0)
    }
  }, [companies?.length, isFetching, lastDataLen, page])

  const onSubmit = (data: IApproveAskRequest): void => {
    if (!props.data) {
      return
    }
    data.approvedByUserId = userId
    approveRequest({ askId: props.data.id, request: data })
      .unwrap()
      .then((res) => {
        setIsSuccess(true)
        setResponseMessage(t('Management:responses.userEnabled'))
        setShowResponse(true)
      })
      .then(() => props.refetch())
      .then(() => props.handleClose())
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const getTranslatedRole = () => {
    const role = props.data?.user.roles[0] ?? ''
    return t(`Roles.${role}`)
  }

  return (
    <Stack
      component={'form'}
      direction={'column'}
      onSubmit={handleSubmit(onSubmit)}
      spacing={3}
    >
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant={'head'}>{t('Management:registerAskDialog.firstName')}</TableCell>
              <TableCell>{props.data?.user.firstName ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Management:registerAskDialog.surname')}</TableCell>
              <TableCell>{props.data?.user.lastName ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Management:registerAskDialog.phone')}</TableCell>
              <TableCell>{parsePhoneNumber(props.data?.user.phone ?? '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>e-mail</TableCell>
              <TableCell>{props.data?.user.email ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Management:registerAskDialog.vatId')}</TableCell>
              <TableCell>{props.data?.vatNumber ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Management:registerAskDialog.role')}</TableCell>
              <TableCell>{getTranslatedRole()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Controller
        control={control}
        name={'companyId'}
        render={({ field }) => (
          <CompanySearch
            {...field}
            isDisabled={false}
          />
        )}
      />
      {companies?.length === 0 && (
        <Stack
          spacing={2}
          direction={'row'}
        >
          <Typography>
            {t('Management:registerAskDialog.notVatNumber')}{' '}
            <Typography component={'span'}>
              <Link
                to={'/companies'}
                className={styles.link}
                style={{ color: appTheme.palette.primary.main }}
              >
                {t('Management:registerAskDialog.createCompany')}
              </Link>
            </Typography>
          </Typography>
        </Stack>
      )}
      <Stack
        direction={'row'}
        spacing={2}
        className={styles.buttonRow}
      >
        <Button
          variant={'contained'}
          onClick={() => {
            if (!props.data || !userId) {
              return
            }
            rejectRequest({ askId: props.data.id, issuer: userId })
              .unwrap()
              .then(() => {
                setIsSuccess(true)
                setResponseMessage(t('Management:responses.userDisabled'))
                setShowResponse(true)
              })
              .then(() => props.refetch())
              .then(() => props.handleClose())
              .catch((err: any) => {
                const error = parseError<any>(err.data.message)
                setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
                setIsSuccess(false)
                setShowResponse(true)
              })
          }}
        >
          {t('Common:reject')}
        </Button>
        <Button
          variant={'contained'}
          type={'submit'}
        >
          {t('Common:accept')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default RegisterAskDialog
