import { Fragment, forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { TableVirtuoso, TableComponents } from 'react-virtuoso'
import { useTheme } from '@emotion/react'

import { ITableColumn } from 'src/pkg/sokarUI'
import { sortDataSet } from './functions'

import './style.scss'

import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from '@mui/material'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface TableProps {
  freeActions?: JSX.Element
  openDialog?: (row: any) => void
  style?: object
  cellRenderer: (column: ITableColumn, row: any) => JSX.Element
}

export interface ITableRef {
  open: (dataSet: any, columns: ITableColumn[]) => void
}

const ContentTable = forwardRef<ITableRef, TableProps>((props, ref) => {
  const appTheme: any = useTheme()
  const [dataSet, setDataSet] = useState<any[]>([])
  const [columns, setColumns] = useState<ITableColumn[]>([])

  useImperativeHandle(ref, () => ({
    open(_dataSet: any | null, _columns: ITableColumn[]) {
      if (_dataSet === null) return
      setDataSet(_dataSet)
      setColumns(_columns)
    },
  }))

  const rowContent = (_index: number, row: any) => {
    const handleRowClick = (row: any): void => {
      if (!!props.openDialog) props.openDialog(row)
    }

    return (
      <Fragment key={_index}>
        {columns.map((column) => (
          <TableCell
            className={`${
              !!props.openDialog ?
                'content_table_clickable_cell'
              : 'content_table_notclickable_cell'
            } 
                ${
                  ['deliveryCity', 'pickupCity'].includes(column.dataKey) ?
                    'content_table_no_center'
                  : ''
                }`}
            key={column.dataKey}
            onClick={() => handleRowClick(row)}
          >
            {props.cellRenderer(column, row)}
          </TableCell>
        ))}
        {props.freeActions && (
          <TableCell
            className={
              !!props.openDialog ?
                'content_table_clickable_cell'
              : 'content_table_notclickable_cell'
            }
            onClick={() => handleRowClick(row)}
          >
            {props.cellRenderer({ label: '', dataKey: 'options', isSortable: false }, row)}
          </TableCell>
        )}
      </Fragment>
    )
  }

  const [sortingKey, setSortingKey] = useState<string>('')
  const [counter, setCounter] = useState<number>(0)

  const fixedHeaderContent = () => {
    const SortableHeaderCell = ({ column }: { column: ITableColumn }): JSX.Element => {
      const handleSortableCellClick = () => {
        if (sortingKey != column.dataKey) setCounter(1)
        else setCounter((counter) => (counter === 2 ? 0 : counter + 1))
        setSortingKey(column.dataKey)
      }

      return (
        <TableCell
          key={column.dataKey}
          variant={'head'}
          className={'clickable_content_table_header_cell'}
          onClick={handleSortableCellClick}
          sx={{
            backgroundColor: appTheme.palette.primary.main,
          }}
        >
          <Typography
            className={'content_table_sortable_cell'}
            sx={{ color: appTheme.palette.primary.contrastText }}
            key={counter}
          >
            {column.label}
            {(sortingKey != column.dataKey || counter === 0) && (
              <ExpandLessIcon className={'content_table_hidden_arrow'} />
            )}
            {sortingKey === column.dataKey && counter === 1 && <ExpandMoreIcon />}
            {sortingKey === column.dataKey && counter === 2 && <ExpandLessIcon />}
          </Typography>
        </TableCell>
      )
    }

    const HeaderCell = ({ column }: { column: ITableColumn }): JSX.Element => {
      return (
        <TableCell
          key={column.dataKey}
          variant={'head'}
          className={'notclickable_content_table_header_cell'}
          sx={{
            backgroundColor: appTheme.palette.primary.main,
          }}
        >
          <Typography sx={{ color: appTheme.palette.primary.contrastText }}>
            {column.label}
          </Typography>
        </TableCell>
      )
    }

    return (
      <TableRow>
        {columns.map((column, index) =>
          column.isSortable ?
            <SortableHeaderCell
              column={column}
              key={index}
            />
          : <HeaderCell
              column={column}
              key={index}
            />,
        )}
        {props.freeActions && (
          <TableCell
            variant={'head'}
            className={'content_table_header_cell'}
            sx={{
              backgroundColor: appTheme.palette.primary.main,
            }}
          >
            <div className={'content_table_free_actions'}>{props.freeActions}</div>
          </TableCell>
        )}
      </TableRow>
    )
  }

  const VirtuosoTableComponents: TableComponents<any> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer
        component={Paper}
        {...props}
        ref={ref}
        sx={{
          backgroundColor: appTheme.palette.background.default,
          overflowX: 'hidden',
        }}
      />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      />
    ),
    // @ts-ignore
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody
        {...props}
        ref={ref}
      />
    )),
  }

  return (
    <Paper
      className={'content_table_container'}
      sx={props.style}
    >
      <TableVirtuoso
        data={sortDataSet(dataSet, sortingKey, counter)}
        itemContent={rowContent}
        fixedHeaderContent={fixedHeaderContent}
        components={VirtuosoTableComponents}
      />
    </Paper>
  )
})

export default ContentTable
