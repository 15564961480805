import i18n from 'i18next'
import Cookies from 'js-cookie'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// PL
import rootPL from './assets/dictionaries/root/pl.json'
import commonPL from './assets/dictionaries/common/pl.json'
import usersPL from './assets/dictionaries/users/pl.json'
import companiesPL from './assets/dictionaries/companies/pl.json'
import fleetPL from './assets/dictionaries/fleet/pl.json'
import marketPL from './assets/dictionaries/market/pl.json'
import managementPL from './assets/dictionaries/management/pl.json'
import registerPL from './assets/dictionaries/root/pl.json'
import resetPL from './assets/dictionaries/root/pl.json'
import documentsPL from './assets/dictionaries/documents/pl.json'
import accountingPL from './assets/dictionaries/accounting/pl.json'
// make sure it's fetched
import errorsPL from './assets/dictionaries/errors/pl.json'

// EN
import rootEN from './assets/dictionaries/root/en.json'
import commonEN from './assets/dictionaries/common/en.json'
import usersEN from './assets/dictionaries/users/en.json'
import fleetEN from './assets/dictionaries/fleet/en.json'
import companiesEN from './assets/dictionaries/companies/en.json'
import marketEN from './assets/dictionaries/market/en.json'
import managementEN from './assets/dictionaries/management/en.json'
import registerEN from './assets/dictionaries/root/en.json'
import resetEN from './assets/dictionaries/root/en.json'
import documentsEN from './assets/dictionaries/documents/en.json'
import accountingEN from './assets/dictionaries/accounting/en.json'
// make sure it's fetched
import errorsEN from './assets/dictionaries/errors/en.json'

const languageCookie = Cookies.get('lang')
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    lng: languageCookie,
    ns: ['Common', 'Fleet', 'Users', 'Companies', 'Root', 'Market', 'Errors', 'Accounting'],
    defaultNS: 'Common',
    supportedLngs: ['en', 'pl'],
    resources: {
      pl: {
        Common: { ...commonPL },
        Root: { ...rootPL },
        Users: { ...usersPL },
        Fleet: { ...fleetPL },
        Companies: { ...companiesPL },
        Market: { ...marketPL },
        Document: { ...documentsPL },
        Errors: { ...errorsPL },
        Management: { ...managementPL },
        Register: { ...registerPL },
        Reset: { ...resetPL },
        Accounting: { ...accountingPL },
      },
      en: {
        Common: { ...commonEN },
        Root: { ...rootEN },
        Users: { ...usersEN },
        Fleet: { ...fleetEN },
        Companies: { ...companiesEN },
        Document: { ...documentsEN },
        Market: { ...marketEN },
        Errors: { ...errorsEN },
        Management: { ...managementEN },
        Register: { ...registerEN },
        Reset: { ...resetEN },
        Accounting: { ...accountingEN },
      },
    },
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 60 * 24 * 365,
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
    },
    interpolation: {
      escapeValue: false,
    },
  })
