/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTheme } from '@emotion/react'
import { Settings } from '@mui/icons-material'
import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Alert,
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import AppLogo from 'src/assets/img/sokar_logo.svg'
import { signOut } from 'src/features/auth/authSlice'
import useServerResponse from 'src/hooks/useServerResponse'
import { getTheme } from 'src/theme'

import { ThemeCtx } from '@/App'

// Components
import Navbar from '../../components/nav'

const drawerWidth = 240

const Layout = (): JSX.Element => {
  // Layout Component
  // -------------------
  // Description:
  // This component creates a layout for most authenticated views.

  // Impact:
  // Modifying this component will affect basically the whole application.

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { theme, setThemeHandler } = useContext(ThemeCtx)

  const { isSuccess, showResponse, responseMessage, setShowResponse } = useServerResponse()

  // mobile view drawer handlers
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)
  const appTheme: any = useTheme()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const [anchorElUser, setAnchorElUser] = useState<HTMLAnchorElement | null>(null)
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          ml: { sm: `${drawerWidth}px` },
          width: { sm: `${drawerWidth}px)` },
          zIndex: (_theme) => (_theme.zIndex.drawer as number) + 1,
        }}
      >
        <Toolbar>
          <IconButton
            edge={'start'}
            color={'inherit'}
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            style={{ maxHeight: '50px', cursor: 'pointer' }}
            src={AppLogo}
            alt={'application logo'}
            onClick={() => navigate(`/companies`)}
          />
          <Box sx={{ flexGrow: 1, display: 'flex' }} />
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t('Root:Menu.userMenu')}>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 1 }}
              >
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                mt: '45px',
              }}
              PaperProps={{
                sx: {
                  backgroundColor: appTheme.palette.background.default,
                },
              }}
            >
              <MenuItem>
                <FormGroup row>
                  <FormControlLabel
                    value={'top'}
                    control={
                      <Switch
                        color={'primary'}
                        checked={getTheme() === 'dark'}
                        onChange={() => setThemeHandler(theme === 'light' ? 'dark' : 'light')}
                      />
                    }
                    label={
                      <Badge
                        badgeContent={'BETA'}
                        color={'primary'}
                      >
                        <Typography>{t('Root:layout.darkMode')}</Typography>
                      </Badge>
                    }
                    labelPlacement={'top'}
                  />
                </FormGroup>
              </MenuItem>
              <Divider />
              {/* settings */}
              <MenuItem
                onClick={() => navigate('/settings')}
                sx={{ justifyContent: 'flex-start' }}
              >
                <ListItemIcon>
                  <Settings fontSize='small' />
                </ListItemIcon>
                {t('Root:Menu.settings')}
              </MenuItem>
              <MenuItem
                onClick={() => dispatch(signOut())}
                sx={{ justifyContent: 'flex-start' }}
              >
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                {t('Root:layout.signOut')}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Navbar
        drawerWidth={drawerWidth}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        sx={{
          flexGrow: 1,
          p: { xs: 0.5, sm: 3 },
          paddingBottom: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      {showResponse !== undefined && (
        <Snackbar
          open={showResponse}
          autoHideDuration={6000}
          onClose={() => setShowResponse(false)}
        >
          <Alert
            variant={'filled'}
            onClose={() => setShowResponse(false)}
            severity={isSuccess ? 'success' : 'error'}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  )
}

export default Layout
