import { CountryCode } from '@pkg/types'
import { countryCodes } from '@pkg/countryCodes'
import { IDocument } from '@pkg/documents/models'
import { IError } from 'src/pkg/sokarUI'
import { ifError } from 'assert'

export const toCamelCase = <T>(input: any): T => {
  if (typeof input !== 'object' || input === null) {
    return input
  }

  if (Array.isArray(input)) {
    return input.map((item) => toCamelCase(item)) as T
  }

  const camelCasedObject: any = {}

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const camelCaseKey = key.replace(/_([a-zA-Z])/g, (_, match) => match.toUpperCase())
      camelCasedObject[camelCaseKey] = toCamelCase(input[key])
    }
  }

  return camelCasedObject as T
}

export const toSnakeCase = <T>(input: any): T => {
  if (typeof input !== 'object' || input === null) {
    return input
  }

  const snakeCasedObject: any = {}

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const snakeCaseKey = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
      snakeCasedObject[snakeCaseKey] = toSnakeCase(input[key])
    }
  }

  return snakeCasedObject as T
}

export enum Directionals {
  USA = '001', // done
  Mexico = '052', //done
  Albania = '355', //done
  Andorra = '376',
  Austria = '043', // done
  Belarus = '375',
  Belgium = '032',
  BosniaAndHerzegovina = '387',
  Bulgaria = '359',
  Croatia = '385',
  Cyprus = '357',
  CzechRepublic = '042',
  Denmark = '045',
  Estonia = '372',
  FaroeIslands = '298',
  Finland = '358',
  France = '033',
  Germany = '049',
  Gibraltar = '350',
  Greece = '030',
  Greenland = '002',
  Hungary = '036',
  Iceland = '354',
  Ireland = '353',
  Italy = '039',
  Kosovo = '383',
  Latvia = '371',
  Liechtenstein = '423',
  Lithuania = '370',
  Luxembourg = '352',
  Malta = '356',
  Moldova = '373',
  Monaco = '377',
  Montenegro = '382',
  Netherlands = '031',
  NorthMacedonia = '389',
  Norway = '047',
  Poland = '048', // done
  Portugal = '351',
  Romania = '040',
  Russia = '007',
  SanMarino = '378',
  Serbia = '381',
  Slovakia = '042',
  Slovenia = '038',
  Spain = '034',
  SvalbardAndJanMayen = '0479',
  Sweden = '046',
  Switzerland = '041',
  Ukraine = '380', // done
  UnitedKingdom = '044', // done
  VaticanCity = '379',
}

export const parsePhoneNumber = (phoneNumber: string): string => {
  const directional: string = phoneNumber.substring(0, 3)
  const number: string = phoneNumber.substring(3, phoneNumber.length)

  if (/\D/.test(phoneNumber)) return 'Invalid number'

  switch (directional) {
    case Directionals.USA:
    case Directionals.Mexico:
    case Directionals.Austria:
      return `+${directional.replace(/0/g, '')} (${number.substring(0, 3)}) ${number.substring(
        3,
        6,
      )}-${number.substring(6, 10)}`
    case Directionals.Poland:
      return `+${directional.replace(/0/g, '')} ${number.substring(0, 3)} ${number.substring(
        3,
        6,
      )} ${number.substring(6, 9)}`
    case Directionals.Albania:
      return `+${directional.replace(/0/g, '')} 4 2${number.substring(0, 3)} ${number.substring(
        3,
        6,
      )}`
    case Directionals.UnitedKingdom:
      return `+${directional.replace(/0/g, '')} ${number.substring(0, 4)} ${number.substring(
        4,
        10,
      )}`
    case Directionals.Ukraine:
      return `+${directional} ${number.substring(0, 2)} ${number.substring(
        2,
        5,
      )} ${number.substring(5, 9)}`
    default:
      return phoneNumber
  }
}

export const parseError = <T>(error: string): IError<T> => {
  if (error === undefined) {
    return {
      dictKey: 'Errors:ERROR_NOT_FOUND_IN_ERROR_MAP',
    }
  }
  const delimeter: string = '|'
  const pairDelimeter: string = ';'
  let err: IError<T> = {
    dictKey: 'Errors:',
  }

  let errorArray = error.split(delimeter)
  if (errorArray[0] === '') {
    err.dictKey = 'ERROR_NOT_FOUND_IN_ERROR_MAP'
    return err
  }
  if (errorArray.length === 2) {
    let dependencies: Record<string, string> = {}
    let dependenciesArray = errorArray[1].split(pairDelimeter)
    for (const pairString of dependenciesArray) {
      const [key, value]: string[] = pairString.split(':')

      if (key && value) {
        dependencies[key.trim()] = value.trim()
      }
    }

    err.dictKey += errorArray[0]
    err.dependencies = dependencies as T

    return err
  }
  err.dictKey += errorArray[0]

  return err
}

export const getFileAlertColour = (files: IDocument[]): string => {
  const today = new Date()
  let highestAlertLevel: string = 'text'
  for (const file of files) {
    if (!file.expiresAt) {
      continue
    }
    const expirationDate = new Date(file.expiresAt)
    const oneDay = 24 * 60 * 60 * 1000
    const diffDays = Math.round(Math.abs((expirationDate.getTime() - today.getTime()) / oneDay))
    if (diffDays <= 7) {
      highestAlertLevel = 'error'
      break
    }
    if (diffDays <= 30) {
      highestAlertLevel = 'warning'
    }
  }

  return highestAlertLevel
}

// TODO: move entirley to same function in regionals.ts
export const getRegion = (countryCode: string): string => {
  if (!countryCode) {
    return 'NA'
  }

  return countryCodes[countryCode.toLocaleUpperCase() as CountryCode]
}
