import L, { LatLngTuple, LeafletMouseEvent } from 'leaflet'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet'

import { getDistanceFromLatLonInKm, zoomSwitch } from '../utils/calcDist'

export interface IMarketMapBoard {
  locationType: 'origin' | 'destination'
  originCords?: LatLngTuple
  destinationCords?: LatLngTuple
  onLocationChange: (type: 'origin' | 'destination', location?: LatLngTuple) => void
  originRadius?: number
  destinationRadius?: number
}

const MarketMapBoard = ({
  originCords,
  destinationCords,
  onLocationChange,
  locationType,
  originRadius = 0,
  destinationRadius = 0,
}: IMarketMapBoard) => {
  const { t } = useTranslation()
  const [positionOrigin, setPositionOrigin] = useState<LatLngTuple | undefined>(originCords)
  const [positionDestination, setPositionDestination] = useState<LatLngTuple | undefined>(
    destinationCords,
  )
  const [middlePoint, setMiddlePoint] = useState<LatLngTuple | undefined>(undefined)
  const [zoom, setZoom] = useState<number>(
    zoomSwitch(getDistanceFromLatLonInKm(positionOrigin, positionDestination)),
  )
  const [circleOrigin, setCircleOrigin] = useState<L.Circle | null>(null)
  const [circleDestination, setCircleDestination] = useState<L.Circle | null>(null)
  const [radiusOrigin, setRadiusOrigin] = useState<number>(originRadius)
  const [radiusDestination, setRadiusDestination] = useState<number>(originRadius)

  const calculateMiddlePoint = useCallback(() => {
    if (!positionOrigin || !positionDestination) return undefined
    const [lat1, lon1] = positionOrigin
    const [lat2, lon2] = positionDestination

    const latMid = (lat1 + lat2) / 2
    const lonMid = (lon1 + lon2) / 2

    return [latMid, lonMid] as LatLngTuple
  }, [positionOrigin, positionDestination])

  useEffect(() => {
    if (!positionOrigin || !positionDestination) return
    setMiddlePoint(calculateMiddlePoint())
    setZoom(zoomSwitch(getDistanceFromLatLonInKm(positionOrigin, positionDestination)))
  }, [positionOrigin, positionDestination])

  let useEffectDebounceOrigin = 0
  let useEffectDebounceDestination = 0

  const SelectLocation = () => {
    const map = useMapEvents({
      click: (e?: LeafletMouseEvent) => {
        if (locationType === 'origin' && e?.latlng) {
          setPositionOrigin([e.latlng.lat, e.latlng.lng])
          onLocationChange(locationType, [e.latlng.lat, e.latlng.lng])

          if (circleOrigin) {
            circleOrigin.remove()
            setCircleOrigin(null)
          }

          const newCircleOrigin = L.circle([e.latlng.lat, e.latlng.lng], {
            radius: originRadius * 1000,
          }).addTo(map)

          setCircleOrigin(newCircleOrigin)
        } else if (locationType === 'destination' && e?.latlng) {
          setPositionDestination([e.latlng.lat, e.latlng.lng])
          onLocationChange(locationType, [e.latlng.lat, e.latlng.lng])

          if (circleDestination) {
            circleDestination.remove()
          }

          const newCircleDestination = L.circle([e.latlng.lat, e.latlng.lng], {
            radius: destinationRadius * 1000,
          }).addTo(map)

          setCircleDestination(newCircleDestination)
        }
      },
    })

    useEffect(() => {
      useEffectDebounceOrigin = useEffectDebounceOrigin + 1
      !positionOrigin && originCords && setPositionOrigin(originCords)
      if (!positionOrigin || radiusOrigin === originRadius || useEffectDebounceOrigin % 2 === 0) {
        return
      }

      if (circleOrigin) {
        circleOrigin.remove()
        setCircleOrigin(null)
      }
      setRadiusOrigin(originRadius)

      const newCircle = L.circle(positionOrigin, {
        radius: originRadius * 1000,
      }).addTo(map)

      setCircleOrigin(newCircle)
    }, [map])

    useEffect(() => {
      useEffectDebounceDestination = useEffectDebounceDestination + 1
      !positionDestination && destinationCords && setPositionDestination(destinationCords)
      if (
        !positionDestination ||
        radiusDestination === destinationRadius ||
        useEffectDebounceDestination % 2 === 0
      ) {
        return
      }

      if (circleDestination) {
        circleDestination.remove()
        setCircleDestination(null)
      }
      setRadiusDestination(destinationRadius)

      const newCircle = L.circle(positionDestination ?? destinationCords, {
        radius: destinationRadius * 1000,
      }).addTo(map)

      setCircleDestination(newCircle)
    }, [map])

    return null
  }

  return (
    <>
      <MapContainer
        center={middlePoint ?? ([37, 0] as LatLngTuple)} //Preferable set to location of company or smth
        zoom={zoom}
        className='market'
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {positionOrigin && (
          <Marker position={positionOrigin}>
            <Popup>{t('Market:form.origin')}</Popup>
          </Marker>
        )}
        {positionDestination && (
          <Marker position={positionDestination}>
            <Popup>{t('Market:form.destination')}</Popup> {/* TODO different icon from origin*/}
          </Marker>
        )}
        <SelectLocation />
      </MapContainer>
    </>
  )
}

export default MarketMapBoard
