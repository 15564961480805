import { useRef, useEffect, useCallback } from 'react'
import { t } from 'i18next'
import SideDialog from '@lib/SideDialog'
import { ITableColumn, ITheme } from '@pkg/sokarUI'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import DetailsDialog from './components/DetailsDialog'
import { ILoad } from '@pkg/loads/models'
import { useGetLoadQuery } from '@features/loads/loadsApiSlice'
import { useGetUserQuery } from '@features/users/usersApiSlice'
import styles from './transportsToInvoice.module.scss'

import { Box, Skeleton, Typography, useTheme, useMediaQuery } from '@mui/material'
import ConfirmationDialog from '@/lib/ConfirmationDialog'
import ComputerIcon from '@mui/icons-material/Computer'

const columns: ITableColumn[] = [
  {
    label: t('Accounting:toInvoiceTable.externalId'),
    dataKey: 'externalId',
    isSortable: false,
  },
  {
    label: t('Accounting:toInvoiceTable.rate'),
    dataKey: 'rate',
    isSortable: false,
  },
  {
    label: t('Accounting:toInvoiceTable.invoiceStatus'),
    dataKey: 'invoiceStatus',
    isSortable: false,
  },
  {
    label: t('Accounting:toInvoiceTable.owner'),
    dataKey: 'owner',
    isSortable: false,
  },
]

const ToInvoiceTable = (): JSX.Element => {
  const appTheme: ITheme = useTheme()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const cellRenderer = (column: ITableColumn, row: ILoad): JSX.Element => {
    if (column.dataKey === 'invoiceStatus') {
      return (
        <Typography color={getProperColour(row.invoiceStatus ?? '')}>
          {t(`Accounting:statuses.${row.invoiceStatus}`)}
        </Typography>
      )
    }
    if (column.dataKey === 'owner') {
      return (
        <OwnerCell
          ownerId={row.owner.id}
          colour={getProperColour(row.invoiceStatus ?? '')}
        />
      )
    }
    if (column.dataKey === 'rate') {
      return (
        <Typography color={getProperColour(row.invoiceStatus ?? '')}>
          {`${row.shipperPriceCurrency} ${row.shipperPrice}`}
        </Typography>
      )
    }
    return (
      <Typography color={getProperColour(row.invoiceStatus ?? '')}>
        {row[column.dataKey as keyof ILoad] as string}
      </Typography>
    )
  }

  const getProperColour = (status: string): string => {
    switch (status) {
      case 'invoiced':
        return appTheme.palette.success.main
      case 'awaiting-rev-rc':
        return appTheme.palette.warning.main
      case 'awaiting-better-paperwork':
        return appTheme.palette.error.main
      default:
        return appTheme.palette.text.primary
    }
  }

  const { data: transports, refetch: refetchTransports } = useGetLoadQuery({
    published: false,
    archived: false,
    tags: 'ready-to-invoice',
  })

  const tableRef = useRef<ITableRef>(null)
  useEffect(() => {
    tableRef.current?.open(transports, columns)
  }, [transports])

  const dialogRef = useRef<any>(null)
  const handleDialogClose = useCallback((): void => {
    dialogRef.current?.close()
  }, [])
  const handleDialogOpen = useCallback((row: ILoad): void => {
    dialogRef.current?.open(row)
  }, [])

  const confirmationDialogRef = useRef<any>(null)

  return (
    <>
      {isMobile ?
        <Box className={styles.inaccessibleOnMobile}>
          <ComputerIcon className={styles.icon} />
          <Typography>{t('Accounting:useDesktop')}</Typography>
        </Box>
      : <>
          <ContentTable
            ref={tableRef}
            style={{ height: 'calc(100dvh - 120px)' }}
            cellRenderer={cellRenderer}
            openDialog={handleDialogOpen}
          />
          <SideDialog
            ref={dialogRef}
            title={''}
          >
            <DetailsDialog
              elementRef={dialogRef}
              confirmationRef={confirmationDialogRef}
              refetch={refetchTransports}
              handleClose={handleDialogClose}
            />
          </SideDialog>
          <ConfirmationDialog ref={confirmationDialogRef} />
        </>
      }
    </>
  )
}

export default ToInvoiceTable

const OwnerCell = ({ ownerId, colour }: { ownerId: string; colour: string }): JSX.Element => {
  const { data: owner, isSuccess: isOwnerSuccess } = useGetUserQuery(ownerId, { skip: !ownerId })

  if (!isOwnerSuccess) {
    return <Skeleton />
  }

  return <Typography color={colour}>{`${owner.firstName} ${owner.lastName}`}</Typography>
}
