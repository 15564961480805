import * as Yup from 'yup'
import { t } from 'i18next'

export const carrierInstanceLoadValidator = Yup.object().shape({
  externalId: Yup.string().required(
    t('Fleet:validatorResponses.externalIdIsRequiredConstraint') ?? '',
  ),
  weight: Yup.number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.weightIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.weightMustBePositiveConstraint') ?? ''),
  shipperPrice: Yup.number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.priceIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.priceMustBePositiveConstraint') ?? ''),
  stops: Yup.array()
    .of(
      Yup.object().shape({
        countryAbbreviation: Yup.string().required(
          t('Fleet:validatorResponses.countryIsRequiredConstraint') ?? '',
        ),
        city: Yup.string().required(t('Fleet:validatorResponses.cityIsRequiredConstraint') ?? ''),
        category: Yup.string().required(
          t('Fleet:validatorResponses.categoryIsRequiredConstraint') ?? '',
        ),
        date: Yup.string()
          .nullable()
          .required(t('Fleet:validatorResponses.dateIsRequiredConstraint') ?? ''),
        dateTo: Yup.string()
          .required(t('Fleet:validatorResponses.dateToIsRequiredConstraint') ?? '')
          .test(
            'isDateAfterOrEqualToStartingPoint',
            t('Fleet:validatorResponses.dateToIsAfterOrEqualDateConstraint') ?? '',
            function (value) {
              if (!value) return false
              const { date }: { date: any } = this.parent
              return new Date(value) >= new Date(date)
            },
          ),
        time: Yup.string().required(t('Fleet:validatorResponses.timeIsRequiredConstraint') ?? ''),
        timeTo: Yup.string()
          .required(t('Fleet:validatorResponses.timeToIsRequiredConstraint') ?? '')
          .test(
            'isDateAfterOrEqualToStartingPoint',
            t('Fleet:validatorResponses.dateToIsAfterOrEqualDateConstraint') ?? '',
            function (value) {
              if (!value) return false
              const { time } = this.parent
              return new Date(value) >= new Date(time)
            },
          ),
      }),
    )
    .test(
      'pickupAfterDelivery',
      t('Fleet:validatorResponses.pickupAfterDeliveryConstraint') ?? '',
      function (stops) {
        if (!stops) return false
        const sortedStops = stops.slice().sort((a, b) => {
          const dateA = new Date(a.date as string).getTime()
          const dateB = new Date(b.date as string).getTime()
          return dateA - dateB
        })

        if (sortedStops[0].category != 'P' && sortedStops[stops.length - 1].category != 'D') {
          return this.createError({
            path: `stops[${stops.length - 1}].date`,
            message: t('Fleet:validatorResponses.pickupAfterDeliveryConstraint') ?? '',
          })
        }
        return true
      },
    ),
  shipperId: Yup.string().required(t('Fleet:validatorResponses.shipperIsRequiredConstraint') ?? ''),
  carrierId: Yup.string().required(t('Fleet:validatorResponses.carrierIsRequiredConstraint') ?? ''),
  truckId: Yup.string().required(t('Fleet:validatorResponses.truckIsRequiredConstraint') ?? ''),
  trailerId: Yup.string().required(t('Fleet:validatorResponses.trailerIsRequiredConstraint') ?? ''),
  driverIds: Yup.array()
    .of(Yup.string())
    .min(1, t('Fleet:validatorResponses.driverIsRequiredConstraint') ?? ''),
})

export const brokerInstancePublishedLoadValidator = Yup.object().shape({
  weight: Yup.number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.weightIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.weightMustBePositiveConstraint') ?? ''),
  publishedPrice: Yup.number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.priceIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.priceMustBePositiveConstraint') ?? ''),
  equipment: Yup.string().required(
    t('Fleet:validatorResponses.equipmentIsRequriedConstraint') ?? '',
  ),
  stops: Yup.array()
    .of(
      Yup.object().shape({
        countryAbbreviation: Yup.string().required(
          t('Fleet:validatorResponses.countryIsRequiredConstraint') ?? '',
        ),
        city: Yup.string().required(t('Fleet:validatorResponses.cityIsRequiredConstraint') ?? ''),
        category: Yup.string().required(
          t('Fleet:validatorResponses.categoryIsRequiredConstraint') ?? '',
        ),
        date: Yup.string().required(t('Fleet:validatorResponses.dateIsRequiredConstraint') ?? ''),
        dateTo: Yup.string()
          .required(t('Fleet:validatorResponses.dateToIsRequiredConstraint') ?? '')
          .test(
            'isDateAfterOrEqualToStartingPoint',
            t('Fleet:validatorResponses.dateToIsAfterOrEqualDateConstraint') ?? '',
            function (value) {
              if (!value) return false
              const { date } = this.parent
              return new Date(value) >= new Date(date)
            },
          ),
        time: Yup.string().required(t('Fleet:validatorResponses.timeIsRequiredConstraint') ?? ''),
        timeTo: Yup.string()
          .required(t('Fleet:validatorResponses.timeToIsRequiredConstraint') ?? '')
          .test(
            'isDateAfterOrEqualToStartingPoint',
            t('Fleet:validatorResponses.dateToIsAfterOrEqualDateConstraint') ?? '',
            function (value) {
              if (!value) return false
              const { time } = this.parent
              return new Date(value) >= new Date(time)
            },
          ),
      }),
    )
    .test(
      'pickupAfterDelivery',
      t('Fleet:validatorResponses.pickupAfterDeliveryConstraint') ?? '',
      function (stops) {
        if (!stops) return false
        const sortedStops = stops.slice().sort((a, b) => {
          const dateA = new Date(a.date as string).getTime()
          const dateB = new Date(b.date as string).getTime()
          return dateA - dateB
        })

        if (sortedStops[0].category != 'P' && sortedStops[stops.length - 1].category != 'D') {
          return this.createError({
            path: `stops[${stops.length - 1}].date`,
            message: t('Fleet:validatorResponses.pickupAfterDeliveryConstraint') ?? '',
          })
        }
        return true
      },
    ),
})

export const shipperInstancePublishedLoadValidator = Yup.object().shape({
  publishedPrice: Yup.number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.priceIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.priceMustBePositiveConstraint') ?? ''),
  stops: Yup.array().of(
    Yup.object().shape({
      countryAbbreviation: Yup.string().required(
        t('Fleet:validatorResponses.countryIsRequiredConstraint') ?? '',
      ),
      city: Yup.string().required(t('Fleet:validatorResponses.cityIsRequiredConstraint') ?? ''),
      date: Yup.string().required(t('Fleet:validatorResponses.dateIsRequiredConstraint') ?? ''),
    }),
  ),
})

export const assignCarrierValidation = Yup.object().shape({
  shipperId: Yup.string().required(t('Fleet:validatorResponses.shipperIsRequiredConstraint') ?? ''),
  carrierId: Yup.string().required(t('Fleet:validatorResponses.carrierIsRequiredConstraint') ?? ''),
  driverIds: Yup.array()
    .of(Yup.string())
    .when('addDriver', {
      is: false,
      then: Yup.array().required(t('Fleet:validatorResponses.driverIsRequiredConstraint') ?? ''),
      otherwise: Yup.array().of(Yup.string()).notRequired(),
    }),
  truckId: Yup.string().when('addTruck', {
    is: false,
    then: Yup.string().required(t('Fleet:validatorResponses.truckIsRequiredConstraint') ?? ''),
    otherwise: Yup.string().notRequired(),
  }),
  trailerId: Yup.string().when('addTrailer', {
    is: false,
    then: Yup.string().required(t('Fleet:validatorResponses.trailerIsRequiredConstraint') ?? ''),
    otherwise: Yup.string().notRequired(),
  }),
})
