import { useGetDriverQuery } from '@features/drivers/driversApiSlice'

import { Typography, Skeleton } from '@mui/material'

interface IUsedByCellProps {
  driverId: string
  companyId: string
}

const UsedByCell = ({ ...props }: IUsedByCellProps): JSX.Element => {
  const { data: driver, isSuccess: isDriverSuccess } = useGetDriverQuery(
    {
      driverId: props.driverId,
      companyId: props.companyId,
    },
    { skip: !props.driverId },
  )

  if (!props.driverId) return <></>
  if (!isDriverSuccess) return <Skeleton />
  return (
    <Typography>
      {driver[0].firstName} {driver[0].lastName}
    </Typography>
  )
}

export default UsedByCell
