import { useState, Suspense, lazy, LazyExoticComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, IconButton, Button, useTheme } from '@mui/material'
import useServerResponse from '@hooks/useServerResponse'
import { parseError } from '@shared/utils/formatters'
import { ILoad } from '@pkg/loads/models'
import { ITheme } from '@pkg/sokarUI'
import { useDeleteLoadMutation } from '@/features/loads/loadsApiSlice'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'

type trailerDialogElements = 'info' | 'edit' | 'history' | 'link' | 'duplicate' | 'assign'

export interface OfferDialogProps {
  offerData: ILoad | null
}

const OfferDialog: React.FC<any> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  const [offerId, setOfferId] = useState<string>('')
  const [deleteLoad] = useDeleteLoadMutation()
  const { setResponseMessage, setShowResponse, setIsSuccess } = useServerResponse()

  const handleDeleteOffer = (id: string) => {
    const handleDelete = (id: string) => {
      deleteLoad(id)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Market:offerDialog.offerDeleted'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.deleteOfferTitle'),
      text: t('Market:offerDialog.deleteOfferText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Market:offerDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:offerDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:offerDialog.duplicate')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('duplicate')}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:offerDialog.assignCarrier')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('assign')}
        >
          <LibraryAddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:offerDialog.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleDeleteOffer(offerId)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<trailerDialogElements>('info')
  const getProperElement = (
    key: trailerDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      case 'duplicate':
        return lazy(() => import('./duplicate'))
      case 'assign':
        return lazy(() => import('./assign'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (!!props.data) setOfferId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [offerId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
        handleClose={props.handleClose}
      />
    </Suspense>
  )
}

export default OfferDialog
