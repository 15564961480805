import * as React from 'react'

interface IServerResponseContext {
  isSuccess: boolean
  showResponse: boolean
  responseMessage: string | null
  setIsSuccess: (success: boolean) => void
  setShowResponse: (show: boolean) => void
  setResponseMessage: (message: string) => void
}

const defaultServerResponseContext: IServerResponseContext = {
  isSuccess: false,
  showResponse: false,
  responseMessage: null,
  setIsSuccess: () => {},
  setShowResponse: () => {},
  setResponseMessage: () => {},
}

const ServerResponseContext = React.createContext<IServerResponseContext>(
  defaultServerResponseContext,
)

export const ServerResponseProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false)
  const [showResponse, setShowResponse] = React.useState<boolean>(false)
  const [responseMessage, setResponseMessage] = React.useState<string | null>(null)

  return (
    <ServerResponseContext.Provider
      value={{
        isSuccess,
        showResponse,
        responseMessage,
        setIsSuccess,
        setShowResponse,
        setResponseMessage,
      }}
    >
      {children}
    </ServerResponseContext.Provider>
  )
}

export default ServerResponseContext
