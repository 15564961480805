import React, { useState, lazy, Suspense, ComponentType } from 'react'
import { Grid, Paper, useTheme, Box, Skeleton } from '@mui/material'
import './style.scss'
import SettingsNav from './components/settingsNav'

export type settingsComponent = 'personal' | 'security'
const SettingsPage: React.FC = () => {
  
  const appTheme: any = useTheme()
  const [currentComponent, setCurrentComponent] = useState<settingsComponent>('personal')

  const getProperComponent = (
    key: settingsComponent,
  ): React.LazyExoticComponent<ComponentType<any>> => {
    switch (key) {
      case 'security':
        return lazy(() => import('./components/security'))
      default:
        return lazy(() => import('./components/personal'))
    }
  }

  const ProperComponent = getProperComponent(currentComponent)

  const loaders = []
  for (let i = 0; i < 10; i++) {
    loaders.push(
      <Skeleton
        animation='wave'
        key={i}
        sx={{ width: '100%' }}
      />,
    )
  }

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        md={4}
      >
        <Paper
          style={{
            backgroundColor: appTheme.palette.background.default,
          }}
          className='settings paper-box'
        >
          <SettingsNav setNavComponent={setCurrentComponent} />
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
      >
        <Paper
          style={{
            backgroundColor: appTheme.palette.background.default,
          }}
          className='settings paper-box'
        >
          <Suspense fallback={<Box className='companies page-content loaders'>{loaders}</Box>}>
            <ProperComponent />
          </Suspense>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SettingsPage
