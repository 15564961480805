import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useFieldArray } from 'react-hook-form'
import { CreateFromTemplate } from '@pkg/documents/requests'
import Additionals from './components/Additionals'
import CompanySelection from './components/CompanySelection'
import DriverSelection from './components/DriverSelection'
import TransportSelection from './components/TransportsSelection'
import styles from './generateSettlement.module.scss'
import { ICompany } from '@pkg/companies/models'
import useOwnUser from '@hooks/useOwnUser'
import { convertCompanyToInvoiceDetails } from '@pkg/documents/utils'
import { useUpdateLoadMutation } from '@features/loads/loadsApiSlice'
import { createUpdateLoadRequest } from '@pkg/loads/converters'
import {
  useCreateDocumentMutation,
  useGetDocumentContentMutation,
} from '@features/documents/documentsApiSlice'
import { parseError } from '@shared/utils/formatters'
import useServerResponse from '@hooks/useServerResponse'
import { ILoad } from '@pkg/loads/models'
import { ITheme } from '@pkg/sokarUI'

import { Box, Fab, Grid, Tooltip, useTheme, useMediaQuery, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ComputerIcon from '@mui/icons-material/Computer'

const GenerateSettlement: React.FC = () => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const [company, setCompany] = useState<ICompany | undefined>(undefined)
  const [driverId, setDriverId] = useState<string | undefined>(undefined)
  const [loadIds, setLoadIds] = useState<string[]>([])
  const [loads, setLoads] = useState<ILoad[]>([])
  const { companyId: ownCompanyId } = useOwnUser()
  const [generateDocument] = useCreateDocumentMutation()
  const [getDocumentsContent] = useGetDocumentContentMutation()
  const [updateLoad] = useUpdateLoadMutation()
  const { setIsSuccess, setShowResponse, setResponseMessage } = useServerResponse()

  const { control, handleSubmit, reset } = useForm<CreateFromTemplate>({
    mode: 'onSubmit',
  })

  useEffect(() => {
    setDriverId(undefined)
    setLoadIds([])
  }, [company])

  const {
    fields: additions,
    append: addAddition,
    remove: removeAddition,
  } = useFieldArray({
    control,
    name: 'invoiceDetails.services',
  })

  const {
    fields: deductions,
    append: addDeduction,
    remove: removeDeduction,
  } = useFieldArray({
    control,
    name: 'invoiceDetails.deductions',
  })

  const onSubmit = (data: CreateFromTemplate): void => {
    if (!driverId || !ownCompanyId || !company) {
      return
    }
    data.templateId = 'd2197007-1ff6-4fed-b3fc-8fbd0bed57e7'
    data.driverId = [driverId]
    data.companyId = [ownCompanyId]
    data.loadId = loadIds
    data.category = 'invoice'
    data.invoiceDetails = { ...convertCompanyToInvoiceDetails(company), ...data.invoiceDetails }

    setResponseMessage(String(t('Accounting:responses.startingFileGeneration')))
    setIsSuccess(true)
    setShowResponse(true)

    generateDocument(data)
      .unwrap()
      .then((res) => {
        getDocumentsContent(res.id)
          .unwrap()
          .then((content) => {
            fetch('data:application/pdf;base64,' + content.content)
              .then((res) => res.blob())
              .then((file) => {
                const url = URL.createObjectURL(file)
                const a = document.createElement('a')
                a.href = url
                a.download = res.id
                a.style.display = 'none'

                document.body.appendChild(a)
                a.click()

                document.body.removeChild(a)
                URL.revokeObjectURL(url)
              })
          })
          .then(() => {
            for (const load of loads) {
              let loadData = createUpdateLoadRequest(load)
              loadData.archived = true
              updateLoad(loadData)
            }
          })
          .then(() => {
            setResponseMessage(String(t('Accounting:response.invoiceGenerated')))
            setIsSuccess(true)
            setShowResponse(true)
          })
      })
      .catch((err) => {
        const error = parseError<any>(err)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  return (
    <>
      {isMobile ?
        <Box className={styles.inaccessibleOnMobile}>
          <ComputerIcon className={styles.icon} />
          <Typography>{t('Accounting:useDesktop')}</Typography>
        </Box>
      : <>
          <Grid
            container
            gap={2}
            className={styles.wrapper}
          >
            <Grid
              container
              item
              xs={11}
              sm={5.82}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                className={styles.topRowItem}
              >
                <CompanySelection setCompany={setCompany} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className={styles.bottomRowItem}
              >
                <TransportSelection
                  driverId={driverId}
                  value={loadIds}
                  setValue={setLoadIds}
                  setLoads={setLoads}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={11}
              sm={5.82}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                className={styles.topRowItem}
              >
                <DriverSelection
                  companyId={company?.id}
                  setDriverId={setDriverId}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className={styles.bottomRowItem}
              >
                <Additionals
                  control={control}
                  services={additions}
                  appendAddition={addAddition}
                  removeAddition={removeAddition}
                  deductions={deductions}
                  appendDeduction={addDeduction}
                  removeDeduction={removeDeduction}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box className={styles.floatingButtonsRow}>
            <Tooltip title={t('Common:generate')}>
              <Fab
                color={'primary'}
                disabled={!company}
                onClick={handleSubmit(onSubmit)}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </Box>
        </>
      }
    </>
  )
}

export default GenerateSettlement
