import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OptionsCard from '@lib/OptionsCard'
import styles from './optionCard.module.scss'

import { Button, TextField } from '@mui/material'

import MapIcon from '@mui/icons-material/Map'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

interface ICurrentLoadsOptionsCardProps {
  isMapView: boolean
  value: string
  handleSearch: (phrase: string, isSearched: boolean) => void
  handleViewToggle: () => void
}

const CurrentLoadsOptionsCard = ({ ...props }: ICurrentLoadsOptionsCardProps): JSX.Element => {
  const { t } = useTranslation()
  const [phrase, setPhrase] = useState<string>('')

  const handleClear = () => {
    setPhrase('')
    props.handleSearch('', false)
  }

  useEffect(() => {
    if (props.value) setPhrase(props.value)
  }, [props.value])

  return (
    <OptionsCard>
      <TextField
        value={phrase}
        className={styles.optionCardSearch}
        label={t('Fleet:currentLoads.searchTitle')}
        onChange={(e) => setPhrase(e.target.value)}
      />
      <Button
        variant={'contained'}
        onClick={() => props.handleSearch(phrase, true)}
        disabled={!phrase}
      >
        <SearchIcon />
      </Button>
      <Button
        variant={'contained'}
        onClick={() => handleClear()}
        disabled={!phrase}
      >
        <SearchOffIcon />
      </Button>
      <Button
        variant={'contained'}
        onClick={() => props.handleViewToggle()}
      >
        {props.isMapView ?
          <FormatListBulletedIcon />
        : <MapIcon />}
      </Button>
    </OptionsCard>
  )
}

export default CurrentLoadsOptionsCard
