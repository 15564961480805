import { useState, ChangeEvent, SyntheticEvent } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { brokerInstancePublishedLoadValidator } from '@pkg/loads/validators'
import useServerResponse from '@hooks/useServerResponse'
import { initICreateCarrierLoadRequest, initICreateStopRequest } from '@pkg/loads/initializers'
import { useCreateLoadMutation } from '@features/loads/loadsApiSlice'
import { ICreateBrokerInstanceLoadRequest } from '@pkg/loads/requests'
import { parseError } from '@shared/utils/formatters'
import { ICity } from '@pkg/loads/models'
import { IAutocompleteOption, ITheme } from '@pkg/sokarUI'
import { countryCodes } from '@pkg/countryCodes'
import CityField from '@lib/CityField'
import styles from './offerDialog/offerDialog.module.scss'
import { trailerTypes } from '@pkg/trailerTypes'
import appConfig from '@/config'
import { getRegion } from '@shared/utils/formatters'

import {
  Box,
  Stack,
  Paper,
  TextField,
  Tooltip,
  Button,
  MenuItem,
  Autocomplete,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

const CreateOfferDialog = ({ ...props }): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  const country: string | null = localStorage.getItem('country')
  const [createOffer] = useCreateLoadMutation()
  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()
  const entriesArray = Object.entries(trailerTypes)
  const options: IAutocompleteOption<string>[] = entriesArray.map(([key, value]) => {
    return {
      label: t('Fleet:equipment.' + key),
      value: key,
    }
  })

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICreateBrokerInstanceLoadRequest>({
    defaultValues: initICreateCarrierLoadRequest(),
    resolver: yupResolver(brokerInstancePublishedLoadValidator),
  })

  const {
    fields: stops,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'stops',
  })
  const [stopCountryCodes, setCountryCodes] = useState<string[]>(
    stops.map((stop) => stop.countryAbbreviation),
  )

  const moveUp = (index: number) => {
    if (index > 0) swap(index, index - 1)
  }

  const moveDown = (index: number) => {
    if (index < stops.length - 1) swap(index, index + 1)
  }

  const handleCityChange = (index: number, city: ICity): void => {
    setValue(`stops.${index}.zipCode`, city.postalCode)
    setValue(`stops.${index}.lat`, city.latitude)
    setValue(`stops.${index}.lon`, city.longitude)
    setValue(`stops.${index}.city`, `${city.name}, ${city.state}`)
  }

  const onSubmit = (loadData: any): void => {
    loadData.owner = {}
    loadData.published = true
    loadData.carrierPrice = loadData.publishedPrice
    loadData.carrierPriceCurrency = loadData.publishedPriceCurrency
    loadData.tags = ['broker-load']
    for (const stop of loadData.stops) {
      if (stop.time && stop.date) {
        const time = new Date(stop.time)
        let date = new Date(stop.date)
        date.setHours(time.getHours(), time.getMinutes(), 0)
        stop.date = date.toISOString()
        delete stop.time
      }
      if (stop.timeTo && stop.dateTo) {
        const time = new Date(stop.timeTo)
        let date = new Date(stop.dateTo)
        date.setHours(time.getHours(), time.getMinutes(), 0)
        stop.dateTo = date.toISOString()
        delete stop.timeTo
      }
    }

    createOffer(loadData)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Fleet:responses.loadCreated'))
        setIsSuccess(true)
        setShowResponse(true)
      })
      .then(() => reset())
      .then(() => props.handleClose())
      .then(() => props.refetch())
      .catch((err) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const handleCountryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void,
    name: string,
    index: number,
  ) => {
    onChange(e)
    setValue(name as keyof ICreateBrokerInstanceLoadRequest, String(t(e.target.value)))
    const updatedCodes = [...stopCountryCodes]
    updatedCodes[index] = e.target.value
    setCountryCodes(updatedCodes)
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={2}
    >
      {stops.map((field, index: number) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box key={field.id}>
              <Stack
                direction={'row'}
                spacing={2}
              >
                <Typography>{t('Market:offerDialog.stopTitle', { noStop: index + 1 })}</Typography>
                <span className={'create_load_dialog_text_field'} />
                <Tooltip title={t('Market:offerDialog.moveDown')}>
                  <IconButton
                    onClick={() => moveDown(index)}
                    disabled={index === stops.length - 1}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Fleet:offerLoadDialog.moveUp')}>
                  <IconButton
                    onClick={() => moveUp(index)}
                    disabled={index === 0}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Market:offerDialog.deleteStop', { noStop: index + 1 })}>
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack
                direction={'column'}
                spacing={4}
              >
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.countryAbbreviation`}
                    render={({ field: { name, value, onChange } }) => (
                      <TextField
                        select
                        value={value}
                        onChange={(e) => {
                          handleCountryChange(e, onChange, `stops.${index}.country`, index)
                        }}
                        className={'create_load_dialog_text_field'}
                        label={t('Market:offerDialog.country')}
                        error={!!errors?.stops?.[index]?.countryAbbreviation}
                        helperText={
                          <Box
                            className={styles.errorBox}
                            height={2}
                          >
                            {errors?.stops?.[index]?.countryAbbreviation?.message}
                          </Box>
                        }
                      >
                        {Object.keys(countryCodes)
                          // @ts-ignore
                          .filter((code) => countryCodes[code] === getRegion(country ?? ''))
                          .map((country) => (
                            <MenuItem value={country}>{t('Common:countries.' + country)}</MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.city`}
                    render={({ field }) => (
                      <CityField
                        index={index}
                        value={field.value}
                        country={stopCountryCodes[index]}
                        error={errors?.stops?.[index]?.city}
                        handleValueChange={handleCityChange}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.category`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        className={'create_load_dialog_text_field'}
                        label={t('Market:offerDialog.category')}
                        error={!!errors?.stops?.[index]?.category}
                        helperText={
                          <Box
                            className={styles.errorBox}
                            height={2}
                          >
                            {errors?.stops?.[index]?.category?.message}
                          </Box>
                        }
                      >
                        <MenuItem value={'P'}>{t('Market:offerDialog.P')}</MenuItem>
                        <MenuItem value={'R'}>{t('Market:offerDialog.R')}</MenuItem>
                        <MenuItem value={'D'}>{t('Market:offerDialog.D')}</MenuItem>
                      </TextField>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.street`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={'create_load_dialog_text_field'}
                        label={t('Market:offerDialog.address')}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.date`}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          format={'MM-DD-YYYY'}
                          label={t('Market:offerDialog.date')}
                          value={dayjs(field.value)}
                          onChange={field.onChange}
                          className={'create_load_dialog_text_field'}
                          slotProps={{
                            textField: {
                              error: !!errors?.stops?.[index]?.date,
                              helperText: (
                                <Box
                                  className={styles.errorBox}
                                  height={2}
                                >
                                  {errors?.stops?.[index]?.date?.message}
                                </Box>
                              ),
                            },
                          }}
                        />
                        <Stack
                          direction={'column'}
                          className={styles.dateModifier}
                        >
                          <Tooltip title={t('Market:offerDialog.addDay')}>
                            <IconButton
                              onClick={() => field.onChange(dayjs(field.value).add(1, 'day'))}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('Market:offerDialog.subtractDay')}>
                            <IconButton
                              onClick={() => field.onChange(dayjs(field.value).subtract(1, 'day'))}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.dateTo`}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          format={'MM-DD-YYYY'}
                          label={t('Market:offerDialog.dateTo')}
                          value={dayjs(field.value)}
                          onChange={field.onChange}
                          className={'create_load_dialog_text_field'}
                          slotProps={{
                            textField: {
                              error: !!errors?.stops?.[index]?.dateTo,
                              helperText: (
                                <Box
                                  className={styles.errorBox}
                                  height={2}
                                >
                                  {errors?.stops?.[index]?.dateTo?.message}
                                </Box>
                              ),
                            },
                          }}
                        />
                        <Stack
                          direction={'column'}
                          className={styles.dateModifier}
                        >
                          <Tooltip title={t('Market:offerDialog.addDay')}>
                            <IconButton
                              onClick={() => field.onChange(dayjs(field.value).add(1, 'day'))}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('Market:offerDialog.subtractDay')}>
                            <IconButton
                              onClick={() => field.onChange(dayjs(field.value).subtract(1, 'day'))}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </>
                    )}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.time`}
                    render={({ field }) => (
                      <TimeField
                        ampm={false}
                        label={t('Market:offerDialog.time')}
                        value={dayjs(field.value)}
                        onChange={field.onChange}
                        format={'HH:mm'}
                        className={'create_load_dialog_text_field'}
                        slotProps={{
                          textField: {
                            error: !!errors?.stops?.[index]?.time,
                            helperText: (
                              <Box
                                className={styles.errorBox}
                                height={2}
                              >
                                {errors?.stops?.[index]?.time?.message}
                              </Box>
                            ),
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.timeTo`}
                    render={({ field }) => (
                      <TimeField
                        ampm={false}
                        label={t('Market:offerDialog.timeTo')}
                        value={dayjs(field.value)}
                        onChange={field.onChange}
                        format={'HH:mm'}
                        className={'create_load_dialog_text_field'}
                        slotProps={{
                          textField: {
                            error: !!errors?.stops?.[index]?.timeTo,
                            helperText: (
                              <Box
                                className={styles.errorBox}
                                height={2}
                              >
                                {errors?.stops?.[index]?.timeTo?.message}
                              </Box>
                            ),
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
          </LocalizationProvider>
        )
      })}
      <Button
        variant={'contained'}
        onClick={() => {
          append(initICreateStopRequest())
          setCountryCodes((prev) => [...prev, country ?? 'US'])
        }}
      >
        {t('Market:offerDialog.addStop')}
      </Button>
      <Typography>{t('Market:offerDialog.details')}</Typography>
      <Controller
        control={control}
        name={'type'}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t('Market:offerDialog.type')}
            error={!!errors?.type}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.type?.message}
              </Box>
            }
          >
            <MenuItem value={'PTL'}>{t('Market:offerDialog.typePTL')}</MenuItem>
            <MenuItem value={'LTL'}>{t('Market:offerDialog.typeLTL')}</MenuItem>
            <MenuItem value={'FTL'}>{t('Market:offerDialog.typeFTL')}</MenuItem>
          </TextField>
        )}
      />
      <Controller
        control={control}
        name={'loadingMeters'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'number'}
            className={'create_load_dialog_text_field'}
            label={t('Fleet:createLoadDialog.loadingMeters')}
            error={!!errors?.loadingMeters}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.loadingMeters?.message}
              </Box>
            }
          />
        )}
      />
      <Stack
        direction={'row'}
        spacing={2}
      >
        <Controller
          control={control}
          name={'publishedPrice'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={'create_load_dialog_text_field'}
              label={t('Market:offerDialog.publishedPrice')}
              error={!!errors?.publishedPrice}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.publishedPrice?.message}
                </Box>
              }
            />
          )}
        />
        <Controller
          control={control}
          name={'publishedPriceCurrency'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              className={'create_load_dialog_text_field'}
              label={t('Market:offerDialog.publishedPriceCurrency')}
              error={!!errors?.publishedPriceCurrency}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.publishedPriceCurrency?.message}
                </Box>
              }
            >
              {/* @ts-ignore */}
              {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                (currency: string) => (
                  <MenuItem value={currency}>{t('Common:currencies.' + currency)}</MenuItem>
                ),
              )}
            </TextField>
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'commodityName'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Market:offerDialog.commodityName')}
            error={!!errors?.commodityName}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.commodityName?.message}
              </Box>
            }
          />
        )}
      />
      <Stack
        direction={'row'}
        spacing={2}
      >
        <Controller
          control={control}
          name={'weight'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={'create_load_dialog_text_field'}
              label={t('Market:offerDialog.weight')}
              error={!!errors?.weight}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.weight?.message}
                </Box>
              }
            />
          )}
        />
        <Controller
          control={control}
          name={'weightUnit'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              className={'create_load_dialog_text_field'}
              label={t('Market:offerDialog.weightUnit')}
              error={!!errors?.weightUnit}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.weightUnit?.message}
                </Box>
              }
            >
              <MenuItem value={'lbs'}>{t('Market:offerDialog.weightUnit_lbs')}</MenuItem>
              <MenuItem value={'kg'}>{t('Market:offerDialog.weightUnit_kg')}</MenuItem>
              <MenuItem value={'t'}>{t('Market:offerDialog.weightUnit_t')}</MenuItem>
            </TextField>
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'equipment'}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            options={options}
            value={options.find(({ value }) => value === field.value)}
            onChange={(
              e: SyntheticEvent<Element, Event>,
              option: IAutocompleteOption<string> | null,
            ) => {
              if (option != null) field.onChange(option.value)
            }}
            PaperComponent={(params) => (
              <Paper
                {...params}
                sx={{ backgroundColor: appTheme.palette.background.default }}
              />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors?.equipment}
                helperText={
                  <Box
                    className={styles.errorBox}
                    height={2}
                  >
                    {errors?.equipment?.message}
                  </Box>
                }
                label={t('Fleet:trailerDialog.category')}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={'description'}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={3}
            label={t('Market:offerDialog.description')}
            error={!!errors?.description}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.description?.message}
              </Box>
            }
          />
        )}
      />
      <Button
        variant={'contained'}
        type={'submit'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateOfferDialog
