import BusinessIcon from '@mui/icons-material/Business'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import WorkIcon from '@mui/icons-material/Work'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import {
  Box,
  Collapse,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import RequireRole from 'src/features/auth/RequireRole'

import { ITheme } from '@/pkg/sokarUI'

const Navbar = ({ ...props }) => {
  const { t } = useTranslation()

  const theme: ITheme = useTheme()

  const [isFleetOpen, setIsFleetOpen] = useState(false)
  const handleFleetClick = () => {
    setIsFleetOpen((prev) => !prev)
  }

  const [isMarketOpen, setIsMarketOpen] = useState(false)
  const handleMarketClick = () => {
    setIsMarketOpen((prev) => !prev)
  }

  const [isManagementOpen, setIsManagementOpen] = useState(false)
  const handleManagementClick = () => {
    setIsManagementOpen((prev) => !prev)
  }

  const [isAccountingOpen, setIsAccountingOpen] = useState<boolean>(false)
  const handleAccountingClick = (): void => {
    setIsAccountingOpen(!isAccountingOpen)
  }

  const location = useLocation()

  useEffect(() => {
    if (location.pathname.startsWith('/market')) {
      setIsMarketOpen(true)
    }
    if (location.pathname.startsWith('/fleet')) {
      setIsFleetOpen(true)
    }
    if (location.pathname.startsWith('/managment')) {
      setIsManagementOpen(true)
    }
  }, [location.pathname])

  const isPathSelected = (path: string) => {
    return location.pathname === path
  }

  const Options = (): JSX.Element => {
    return (
      <List>
        <RequireRole allowedRoles={['employee', 'dispatcher', 'carrier', 'client']}>
          <ListItemButton onClick={handleMarketClick}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={t('Common:UI.menu.market')} />
            {isMarketOpen ?
              <ExpandLess />
            : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isMarketOpen}
            timeout='auto'
            unmountOnExit
          >
            <List
              component='div'
              disablePadding
            >
              <RequireRole allowedRoles={['dispatcher', 'employee']}>
                <ListItemButton
                  component={Link}
                  href='/market/external'
                  sx={{ pl: 9 }}
                  selected={isPathSelected('/market/external')}
                >
                  <ListItemText primary={t('Common:UI.menu.externalMarket')} />
                </ListItemButton>
              </RequireRole>
              <RequireRole allowedRoles={['carrier', 'dispatcher', 'employee']}>
                <ListItemButton
                  component={Link}
                  href='/market/for-carriers'
                  sx={{ pl: 9 }}
                  selected={isPathSelected('/market/for-carriers')}
                >
                  <ListItemText primary={t('Common:UI.menu.carrierMarket')} />
                </ListItemButton>
              </RequireRole>
              <RequireRole allowedRoles={['dispatcher', 'employee']}>
                <ListItemButton
                  component={Link}
                  href='/market/offers-manager'
                  sx={{ pl: 9 }}
                  selected={isPathSelected('/market/offers-manager')}
                >
                  <ListItemText primary={t('Common:UI.menu.offersManager')} />
                </ListItemButton>
              </RequireRole>
              <RequireRole allowedRoles={['dispatcher', 'employee']}>
                <ListItemButton
                  component={Link}
                  href='/market/ongoing'
                  sx={{ pl: 9 }}
                  selected={isPathSelected('/market/ongoing')}
                >
                  <ListItemText primary={t('Common:UI.menu.ongoingTransports')} />
                </ListItemButton>
              </RequireRole>
              <RequireRole allowedRoles={['dispatcher', 'employee']}>
                <ListItemButton
                  component={Link}
                  href='/market/for-brokers'
                  sx={{ pl: 9 }}
                  selected={isPathSelected('/market/for-brokers')}
                >
                  <ListItemText primary={t('Common:UI.menu.clientOffers')} />
                </ListItemButton>
              </RequireRole>
              <RequireRole allowedRoles={['client']}>
                <ListItemButton
                  component={Link}
                  href='/market/transport-needs'
                  sx={{ pl: 9 }}
                  selected={isPathSelected('/market/transport-needs')}
                >
                  <ListItemText primary={t('Common:UI.menu.clientOffersManager')} />
                </ListItemButton>
              </RequireRole>
            </List>
          </Collapse>
        </RequireRole>
        <RequireRole allowedRoles={['accountant', 'dispatcher']}>
          <ListItemButton
            component={Link}
            href='/companies'
            selected={isPathSelected('/companies')}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={t('Common:UI.menu.companies')} />
          </ListItemButton>
        </RequireRole>
        <RequireRole allowedRoles={['dispatcher']}>
          <ListItemButton onClick={handleFleetClick}>
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary={t('Common:UI.menu.fleet')} />
            {isFleetOpen ?
              <ExpandLess />
            : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isFleetOpen}
            timeout='auto'
            unmountOnExit
          >
            <List
              component='div'
              disablePadding
            >
              <ListItemButton
                component={Link}
                href='/fleet/current-loads'
                sx={{ pl: 9 }}
                selected={isPathSelected('/fleet/current-loads')}
              >
                <ListItemText primary={t('Common:UI.menu.currentLoads')} />
              </ListItemButton>
              <ListItemButton
                component={Link}
                href='/fleet/drivers'
                sx={{ pl: 9 }}
                selected={isPathSelected('/fleet/drivers')}
              >
                <ListItemText primary={t('Common:UI.menu.drivers')} />
              </ListItemButton>
              <ListItemButton
                component={Link}
                href='/fleet/trucks'
                sx={{ pl: 9 }}
                selected={isPathSelected('/fleet/trucks')}
              >
                <ListItemText primary={t('Common:UI.menu.trucks')} />
              </ListItemButton>
              <ListItemButton
                component={Link}
                href='/fleet/trailers'
                sx={{ pl: 9 }}
                selected={isPathSelected('/fleet/trailers')}
              >
                <ListItemText primary={t('Common:UI.menu.trailers')} />
              </ListItemButton>
            </List>
          </Collapse>
        </RequireRole>
        <RequireRole allowedRoles={['manager']}>
          <ListItemButton onClick={handleManagementClick}>
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText primary={t('Common:UI.menu.management')} />
            {isManagementOpen ?
              <ExpandLess />
            : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isManagementOpen}
            timeout='auto'
            unmountOnExit
          >
            <List
              component='div'
              disablePadding
            >
              <ListItemButton
                component={Link}
                href='/management/access-requests'
                sx={{ pl: 9 }}
                selected={isPathSelected('/management/access-requests')}
              >
                <ListItemText primary={t('Common:UI.menu.registerRequests')} />
              </ListItemButton>
            </List>
          </Collapse>
        </RequireRole>
        <ListItemButton onClick={handleAccountingClick}>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary={t('Common:UI.menu.accounting')} />
          {isAccountingOpen ?
            <ExpandLess />
          : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={isAccountingOpen}
          timeout='auto'
          unmountOnExit
        >
          <List
            component={'div'}
            disablePadding
          >
            <RequireRole allowedRoles={['accountant', 'dispatcher']}>
              <ListItemButton
                component={Link}
                href='/accounting/to-invoice'
                sx={{ pl: 9 }}
                selected={isPathSelected('/accounting/to-invoice')}
              >
                <ListItemText primary={t('Common:UI.menu.toInvoice')} />
              </ListItemButton>
            </RequireRole>
            <RequireRole allowedRoles={['accountant']}>
              <ListItemButton
                component={Link}
                href='/accounting/generate-settlement'
                sx={{ pl: 9 }}
                selected={isPathSelected('/accounting/generate-settlement')}
              >
                <ListItemText primary={t('Common:UI.menu.generateSettlement')} />
              </ListItemButton>
            </RequireRole>
          </List>
        </Collapse>
        <RequireRole allowedRoles={['employee']}>
          <ListItemButton
            component={Link}
            href='/archive'
            selected={isPathSelected('/archive')}
          >
            <ListItemIcon>
              <HourglassEmptyIcon />
            </ListItemIcon>
            <ListItemText primary={t('Common:UI.menu.archive')} />
          </ListItemButton>
        </RequireRole>
      </List>
    )
  }

  const NavBar = (): JSX.Element => {
    return (
      <Box
        component='nav'
        sx={{
          width: { sm: props.drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant='permanent'
          open
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: props.drawerWidth,
              overflowX: 'hidden',
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          <Toolbar />
          <Options />
        </Drawer>
        <Drawer
          variant='temporary'
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
          }}
        >
          <Toolbar />
          <Options />
        </Drawer>
      </Box>
    )
  }

  return <NavBar />
}

export default Navbar
