import { yupResolver } from '@hookform/resolvers/yup'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { LatLngTuple } from 'leaflet'
import { ChangeEvent, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IMarket, IMarketFormFields } from '@/pkg/market/models'
import { IAddSubscriptionRequest } from '@/pkg/market/requests'
import { marketValidators } from '@/pkg/market/validators'
import { trailerTypes } from '@/pkg/trailerTypes'

import { marketMode } from '../utils/const'
import MarketMapBoard from './MapBoard'

export interface IMarketForm {
  onCreateMarketQuery: (data: IAddSubscriptionRequest) => void
  onUpdateMarketQuery: (data: IAddSubscriptionRequest) => void
  subscription?: IMarket
}

const MarketForm = ({ subscription, onCreateMarketQuery, onUpdateMarketQuery }: IMarketForm) => {
  const { t } = useTranslation()
  const [locationType, setLocationType] = useState<'origin' | 'destination'>('origin')
  const [origin, setOrigin] = useState<LatLngTuple | undefined>(undefined)
  const [destination, setDestination] = useState<LatLngTuple | undefined>(undefined)
  const [originRadius, setOriginRadius] = useState<number>(0)
  const [destinationRadius, setDestinationRadius] = useState<number>(0)
  const handleChangeLocationType = (
    event: React.SyntheticEvent,
    newValue: 'origin' | 'destination',
  ) => {
    setLocationType(newValue)
  }

  const defaultValues = {
    id: undefined,
    originCords: undefined,
    originRadius: 0,
    originRadiusUnit: 'Miles',
    originAppointmentStart: dayjs(),
    originAppointmentEnd: dayjs().add(1, 'h'),
    destinationCords: undefined,
    destinationRadius: 0,
    destinationRadiusUnit: 'Miles',
    destinationAppointmentStart: dayjs().add(1, 'h'),
    destinationAppointmentEnd: dayjs().add(2, 'h'),
    equipmentType: '',
    mode: '',
  } as IMarketFormFields

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(marketValidators),
  })

  useEffect(() => {
    if (!subscription) return
    reset({
      id: subscription.id,
      originCords: [
        subscription.origin.location.latitude,
        subscription.origin.location.longitude,
      ] as LatLngTuple,
      originRadius: subscription.origin.deadheadRadius.value,
      originRadiusUnit: subscription.origin.deadheadRadius.unit,
      originAppointmentStart: dayjs(subscription.origin.appointment.appointmentStartDateTime),
      originAppointmentEnd: dayjs(subscription.origin.appointment.appointmentEndDateTime),
      destinationCords: [
        subscription.destination.location.latitude,
        subscription.destination.location.longitude,
      ] as LatLngTuple,
      destinationRadius: subscription.destination.deadheadRadius.value,
      destinationRadiusUnit: subscription.destination.deadheadRadius.unit,
      destinationAppointmentStart: dayjs(
        subscription.destination.appointment.appointmentStartDateTime,
      ),
      destinationAppointmentEnd: dayjs(subscription.destination.appointment.appointmentEndDateTime),
      equipmentType: subscription.equipmentType,
      mode: subscription.mode,
    })
    setOrigin([
      subscription.origin.location.latitude,
      subscription.origin.location.longitude,
    ] as LatLngTuple)
    setDestination([
      subscription.destination.location.latitude,
      subscription.destination.location.longitude,
    ] as LatLngTuple)
    setOriginRadius(subscription.origin.deadheadRadius.value)
    setDestinationRadius(subscription.destination.deadheadRadius.value)
  }, [reset, subscription])

  const handleLocationChange = (type: 'origin' | 'destination', location?: LatLngTuple) => {
    if (type === 'origin') {
      location && setValue('originCords', location)
      setLocationType('destination')
    } else {
      location && setValue('destinationCords', location)
      setLocationType('origin')
    }
  }

  const onSubmit: SubmitHandler<IMarketFormFields> = (data: IMarketFormFields) => {
    const requestData = {
      id: data.id,
      origin: {
        location: {
          latitude: data.originCords?.[0],
          longitude: data.originCords?.[1],
        },
        deadheadRadius: {
          value: data.originRadius,
          unit: data.originRadiusUnit,
        },
        appointment: {
          appointmentStartDateTime: data.originAppointmentStart.toISOString(),
          appointmentEndDateTime: data.originAppointmentEnd.toISOString(),
        },
      },
      destination: {
        location: {
          latitude: data.destinationCords?.[0],
          longitude: data.destinationCords?.[1],
        },
        deadheadRadius: {
          value: data.destinationRadius,
          unit: data.destinationRadiusUnit,
        },
        appointment: {
          appointmentStartDateTime: data.destinationAppointmentStart.toISOString(),
          appointmentEndDateTime: data.destinationAppointmentEnd.toISOString(),
        },
      },
      equipmentType: data.equipmentType,
      mode: data.mode,
    } as IAddSubscriptionRequest

    if (subscription) {
      onUpdateMarketQuery(requestData)
    } else {
      onCreateMarketQuery(requestData)
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='market form'
      >
        <span className='market form-row'>
          <span>
            <Controller
              name='originAppointmentStart'
              control={control}
              render={({ field }) => (
                <div
                  className={`market form-date-helper ${errors?.originAppointmentStart ? 'error' : ''}`}
                >
                  <DateTimePicker
                    label={t('Market:form.originAppointmentStart')}
                    {...field}
                  />
                  {!!errors?.originAppointmentStart && (
                    <Box height={20}>{errors?.originAppointmentStart?.message}</Box>
                  )}
                </div>
              )}
            />
            <Controller
              name='originAppointmentEnd'
              control={control}
              render={({ field }) => (
                <div
                  className={`market form-date-helper ${errors?.originAppointmentEnd ? 'error' : ''}`}
                >
                  <DateTimePicker
                    label={t('Market:form.originAppointmentEnd')}
                    {...field}
                  />
                  {!!errors?.originAppointmentEnd && (
                    <Box height={20}>{errors?.originAppointmentEnd?.message}</Box>
                  )}
                </div>
              )}
            />
          </span>
          <span>
            <Controller
              name='originRadius'
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
                <TextField
                  type='number'
                  required
                  onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    const parsed = Number(e.target.value ?? '0')
                    onChange(parsed <= 0 ? 0 : parsed)
                    setOriginRadius(parsed <= 0 ? 0 : parsed)
                  }}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  ref={_ref}
                  error={!!errors?.originRadius}
                  helperText={<Box height={20}>{errors?.originRadius?.message}</Box>}
                />
              )}
            />

            <Controller
              name='originRadiusUnit'
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
                <div
                  className={`market form-date-helper ${errors?.originRadiusUnit ? 'error' : ''}`}
                >
                  <FormControl>
                    <InputLabel required>{t('Market:form.originRadiusUnit')}</InputLabel>
                    <Select
                      onChange={onChange}
                      onBlur={onBlur}
                      // @ts-ignore
                      value={value && (Array.isArray(value) ? value : [value])}
                      name={name}
                      ref={_ref}
                      input={<OutlinedInput label={t('Market:form.originRadiusUnit')} />}
                      error={!!errors?.originRadiusUnit}
                    >
                      <MenuItem value='Kilometers'>{t('Market:form.km')}</MenuItem>
                      <MenuItem value='Miles'>{t('Market:form.mil')}</MenuItem>
                    </Select>
                  </FormControl>
                  {!!errors?.originRadiusUnit && (
                    <Box height={20}>{errors?.originRadiusUnit?.message}</Box>
                  )}
                </div>
              )}
            />
          </span>
        </span>
        <span className='market form-row'>
          <span>
            <Controller
              name='destinationAppointmentStart'
              control={control}
              render={({ field }) => (
                <div
                  className={`market form-date-helper ${errors?.destinationAppointmentStart ? 'error' : ''}`}
                >
                  <DateTimePicker
                    label={t('Market:form.destinationAppointmentStart')}
                    {...field}
                  />
                  {!!errors?.destinationAppointmentStart && (
                    <Box height={20}>{errors?.destinationAppointmentStart?.message}</Box>
                  )}
                </div>
              )}
            />
            <Controller
              name='destinationAppointmentEnd'
              control={control}
              render={({ field }) => (
                <div
                  className={`market form-date-helper ${errors?.destinationAppointmentEnd ? 'error' : ''}`}
                >
                  <DateTimePicker
                    label={t('Market:form.destinationAppointmentEnd')}
                    {...field}
                  />
                  {!!errors?.destinationAppointmentEnd && (
                    <Box height={20}>{errors?.destinationAppointmentEnd?.message}</Box>
                  )}
                </div>
              )}
            />
          </span>
          <span>
            <Controller
              name='destinationRadius'
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
                <TextField
                  type='number'
                  required
                  onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    const parsed = Number(e.target.value ?? '0')
                    onChange(parsed <= 0 ? 0 : parsed)
                    setDestinationRadius(parsed <= 0 ? 0 : parsed)
                  }}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  ref={_ref}
                  error={!!errors?.destinationRadius}
                  helperText={<Box height={20}>{errors?.destinationRadius?.message}</Box>}
                />
              )}
            />

            <Controller
              name='destinationRadiusUnit'
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
                <div
                  className={`market form-date-helper ${errors?.destinationRadiusUnit ? 'error' : ''}`}
                >
                  <FormControl>
                    <InputLabel required>{t('Market:form.destinationRadiusUnit')}</InputLabel>
                    <Select
                      onChange={onChange}
                      onBlur={onBlur}
                      // @ts-ignore
                      value={value && (Array.isArray(value) ? value : [value])}
                      name={name}
                      ref={_ref}
                      input={<OutlinedInput label={t('Market:form.destinationRadiusUnit')} />}
                      error={!!errors?.destinationRadiusUnit}
                    >
                      <MenuItem value='Kilometers'>{t('Market:form.km')}</MenuItem>
                      <MenuItem value='Miles'>{t('Market:form.mil')}</MenuItem>
                    </Select>
                  </FormControl>
                  {!!errors?.destinationRadiusUnit && (
                    <Box height={20}>{errors?.destinationRadiusUnit?.message}</Box>
                  )}
                </div>
              )}
            />
          </span>
        </span>
        <span className='market form-row'>
          <Controller
            name='equipmentType'
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
              <div className={`market form-date-helper ${errors?.equipmentType ? 'error' : ''}`}>
                <FormControl>
                  <InputLabel required>{t('Market:form.equipmentType')}</InputLabel>
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    // @ts-ignore
                    value={value && (Array.isArray(value) ? value : [value])}
                    name={name}
                    ref={_ref}
                    input={<OutlinedInput label={t('Market:form.equipmentType')} />}
                    error={!!errors?.equipmentType}
                  >
                 
                    {Object.keys(trailerTypes).map((type: string, index: number) => {
                      return (
                        <MenuItem
                          value={type}
                          key={index}
                        >
                          {t(
                            `Fleet:equipment.${type}`,
                          )}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                {!!errors?.equipmentType && <Box height={20}>{errors?.equipmentType?.message}</Box>}
              </div>
            )}
          />
          <Controller
            name='mode'
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
              <div className={`market form-date-helper ${errors?.mode ? 'error' : ''}`}>
                <FormControl>
                  <InputLabel required>{t('Market:form.mode')}</InputLabel>
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    // @ts-ignore
                    value={value && (Array.isArray(value) ? value : [value])}
                    name={name}
                    ref={_ref}
                    input={<OutlinedInput label={t('Market:form.mode')} />}
                    error={!!errors?.mode}
                  >
                    {marketMode.map((mode: string, index: number) => {
                      return (
                        <MenuItem
                          value={mode}
                          key={index}
                        >
                          {t(`Market:mode.${mode}`)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                {!!errors?.mode && <Box height={20}>{errors?.mode?.message}</Box>}
              </div>
            )}
          />
        </span>
        <Tabs
          value={locationType}
          onChange={handleChangeLocationType}
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
          aria-label={`market-location-picker-${locationType}`}
          className='market tab-location-picker'
        >
          <Tab
            label={t('Market:form.pickOrigin')}
            icon={<AddLocationAltIcon />}
            iconPosition='start'
            value='origin'
          />
          <Tab
            label={t('Market:form.pickDestination')}
            icon={<WhereToVoteIcon />}
            iconPosition='start'
            value='destination'
          />
        </Tabs>
        {!!errors?.originCords && <Box height={20}>{errors?.originCords?.message}</Box>}
        {!!errors?.destinationCords && <Box height={20}>{errors?.destinationCords?.message}</Box>}
        <MarketMapBoard
          locationType={locationType}
          onLocationChange={handleLocationChange}
          originCords={origin}
          destinationCords={destination}
          originRadius={isNaN(originRadius) ? 0 : originRadius}
          destinationRadius={isNaN(destinationRadius) ? 0 : destinationRadius}
        />
        <span className='market form-btn-submit'>
          <Button
            type='submit'
            variant='contained'
            size='large'
          >
            {t('Market:form.submit')}
          </Button>
        </span>
      </form>
    </LocalizationProvider>
  )
}

export default MarketForm
