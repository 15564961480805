import { useState, useEffect, useRef, useCallback } from 'react'
import { t } from 'i18next'
import { useTheme } from '@emotion/react'
import useOwnUser from 'src/hooks/useOwnUser'
import useRefetchWithAlert from 'src/hooks/useRefetchWithAlert'
import { useGetDriverQuery, useSearchDriverQuery } from '@features/drivers/driversApiSlice'

import DriverCard from './components/driverCard'
import { ITableColumn } from 'src/pkg/sokarUI'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import { IDriver } from '@pkg/drivers/models'
import ContentTable, { ITableRef } from 'src/lib/ContentTable'
import DriverDialog from './components/driverDialog'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import DriversOptionsCard from './components/driversOptionsCard'
import { parsePhoneNumber } from 'src/shared/utils/formatters'
import CreateDriverDialog from './components/createDriverDialog'
import { getFileAlertColour } from '@shared/utils/formatters'

import { Chip, Tooltip, Skeleton, IconButton, useMediaQuery } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import WarningIcon from '@mui/icons-material/Warning'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:driversTable.name'),
    dataKey: 'firstName',
    isSortable: true,
  },
  {
    label: t('Fleet:driversTable.surname'),
    dataKey: 'lastName',
    isSortable: true,
  },
  {
    label: t('Fleet:driversTable.phone'),
    dataKey: 'phone',
    isSortable: false,
  },
  {
    label: 'e-mail',
    dataKey: 'email',
    isSortable: false,
  },
  {
    label: t('Fleet:driversTable.driverType'),
    dataKey: 'groups',
    isSortable: false,
  },
]

const DriversView = () => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const createDriverDialogRef = useRef<any>(null)
  const handleCreateDriverDialogOpen = () => {
    createDriverDialogRef.current?.open({})
  }
  const handleCreateDriverDialogClose = () => createDriverDialogRef.current?.close()
  const driverDialogRef = useRef<ISideDialogRef>(null)
  const handleDriverDialogOpen = useCallback((row: IDriver): void => {
    driverDialogRef.current?.open(row)
  }, [])
  const handleDriverDialogClose = useCallback((): void => {
    driverDialogRef.current?.close()
  }, [])

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Fleet:driversTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateDriverDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driversTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const cellRenderer = (column: ITableColumn, row: any): any => {
    if (column.dataKey === 'groups')
      return (
        <Chip
          color={'primary'}
          label={
            row.isOwner ? t('Fleet:driversTable.ownerDriver') : t('Fleet:driversTable.fleetDriver')
          }
        />
      )
    if (column.dataKey === 'phone') return parsePhoneNumber(row[column.dataKey])
    if (column.dataKey === 'options') {
      if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
        return (
          <Tooltip title={t('Common:documentRequiresAttention')}>
            {/* @ts-ignore */}
            <WarningIcon color={getFileAlertColour(row.documents)} />
          </Tooltip>
        )
      }
    }

    return row[column.dataKey]
  }

  const { companyId } = useOwnUser()

  const {
    data: drivers,
    isSuccess: isDriversSuccess,
    refetch: refetchDrivers,
  } = useGetDriverQuery({ companyId: companyId ?? '', archived: false }, { skip: !companyId })

  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchDrivers = (phrase: string, isSearched: boolean) => {
    setIsSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedDrivers, isSuccess: isSearchDriversSuccess } = useSearchDriverQuery(
    { phrase: searchPhrase, companyId: companyId, archived: false },
    { skip: !isSearched },
  )

  const refresh = useRefetchWithAlert(refetchDrivers)

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(drivers)
    else ref.current?.open(drivers, columns)
  }, [isMobile, drivers, columns])

  useEffect(() => {
    if (isDriversSuccess && !isSearched) ref.current?.open(drivers, columns)
  }, [isDriversSuccess, isSearched])

  useEffect(() => {
    if (isSearchDriversSuccess && isSearched) ref.current?.open(searchedDrivers, columns)
  }, [isDriversSuccess, isSearched, searchedDrivers])

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  if (!isDriversSuccess) return <Skeleton />

  return (
    <>
      <DriversOptionsCard handleSearch={handleSearchDrivers} />
      {isMobile ?
        <ContentList
          ref={listRef}
          listComponent={DriverCard}
          openDialog={handleDriverDialogOpen}
        />
      : <ContentTable
          ref={ref}
          freeActions={freeActions}
          cellRenderer={cellRenderer}
          openDialog={handleDriverDialogOpen}
        />
      }
      <SideDialog
        ref={createDriverDialogRef}
        title={t('Fleet:createDriverDialog.title')}
      >
        <CreateDriverDialog
          refetch={refetchDrivers}
          handleClose={handleCreateDriverDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={driverDialogRef}
        title={t('Fleet:driverDialog.title')}
      >
        <DriverDialog
          refetch={refetchDrivers}
          elementRef={driverDialogRef}
          handleClose={handleDriverDialogClose}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default DriversView
