export const countryCodes = {
  // Europe
  AL: 'EU',
  AD: 'EU',
  AM: 'EU',
  AT: 'EU',
  AZ: 'EU',
  BY: 'EU',
  BE: 'EU',
  BA: 'EU',
  BG: 'EU',
  HR: 'EU',
  CY: 'EU',
  CZ: 'EU',
  DK: 'EU',
  EE: 'EU',
  FI: 'EU',
  FR: 'EU',
  GE: 'EU',
  DE: 'EU',
  GR: 'EU',
  HU: 'EU',
  IS: 'EU',
  IE: 'EU',
  IT: 'EU',
  LV: 'EU',
  LI: 'EU',
  LT: 'EU',
  LU: 'EU',
  MK: 'EU',
  MT: 'EU',
  MD: 'EU',
  MC: 'EU',
  ME: 'EU',
  NL: 'EU',
  NO: 'EU',
  PL: 'EU',
  PT: 'EU',
  RO: 'EU',
  RU: 'EU',
  SM: 'EU',
  RS: 'EU',
  SK: 'EU',
  SI: 'EU',
  ES: 'EU',
  SE: 'EU',
  CH: 'EU',
  UA: 'EU',
  GB: 'EU',
  VA: 'EU',
  XK: 'EU',

  // North America
  AI: 'NA',
  AG: 'NA',
  BS: 'NA',
  BB: 'NA',
  BZ: 'NA',
  BM: 'NA',
  CA: 'NA',
  KY: 'NA',
  CR: 'NA',
  CU: 'NA',
  DM: 'NA',
  DO: 'NA',
  SV: 'NA',
  GD: 'NA',
  GT: 'NA',
  HT: 'NA',
  HN: 'NA',
  JM: 'NA',
  MX: 'NA',
  MS: 'NA',
  AN: 'NA',
  NI: 'NA',
  PA: 'NA',
  PR: 'NA',
  KN: 'NA',
  LC: 'NA',
  VC: 'NA',
  TT: 'NA',
  TC: 'NA',
  US: 'NA',
  VG: 'NA',
  VI: 'NA',

  // South America
  AR: 'SA',
  BO: 'SA',
  BR: 'SA',
  CL: 'SA',
  CO: 'SA',
  EC: 'SA',
  FK: 'SA',
  GF: 'SA',
  GY: 'SA',
  PY: 'SA',
  PE: 'SA',
  SR: 'SA',
  UY: 'SA',
  VE: 'SA',

  // Asia
  AF: 'AS',
  BH: 'AS',
  BD: 'AS',
  BT: 'AS',
  BN: 'AS',
  KH: 'AS',
  CN: 'AS',
  IN: 'AS',
  ID: 'AS',
  IR: 'AS',
  IQ: 'AS',
  IL: 'AS',
  JP: 'AS',
  JO: 'AS',
  KZ: 'AS',
  KW: 'AS',
  KG: 'AS',
  LA: 'AS',
  LB: 'AS',
  MY: 'AS',
  MV: 'AS',
  MN: 'AS',
  MM: 'AS',
  NP: 'AS',
  KP: 'AS',
  OM: 'AS',
  PK: 'AS',
  PH: 'AS',
  QA: 'AS',
  SA: 'AS',
  SG: 'AS',
  KR: 'AS',
  LK: 'AS',
  SY: 'AS',
  TW: 'AS',
  TJ: 'AS',
  TH: 'AS',
  TL: 'AS',
  TR: 'AS',
  TM: 'AS',
  AE: 'AS',
  UZ: 'AS',
  VN: 'AS',
  YE: 'AS',

  // Australia & Oceania
  AS: 'AU',
  AU: 'AU',
  CK: 'AU',
  FJ: 'AU',
  PF: 'AU',
  GU: 'AU',
  KI: 'AU',
  MH: 'AU',
  FM: 'AU',
  NR: 'AU',
  NC: 'AU',
  NZ: 'AU',
  NU: 'AU',
  NF: 'AU',
  MP: 'AU',
  PW: 'AU',
  PG: 'AU',
  PN: 'AU',
  WS: 'AU',
  SB: 'AU',
  TK: 'AU',
  TO: 'AU',
  TV: 'AU',
  VU: 'AU',
  WF: 'AU',

  // Africa
  DZ: 'AF',
  AO: 'AF',
  BJ: 'AF',
  BW: 'AF',
  BF: 'AF',
  BI: 'AF',
  CM: 'AF',
  CV: 'AF',
  CF: 'AF',
  TD: 'AF',
  KM: 'AF',
  CG: 'AF',
  CD: 'AF',
  DJ: 'AF',
  EG: 'AF',
  GQ: 'AF',
  ER: 'AF',
  SZ: 'AF',
  ET: 'AF',
  GA: 'AF',
  GM: 'AF',
  GH: 'AF',
  GN: 'AF',
  GW: 'AF',
  CI: 'AF',
  KE: 'AF',
  LS: 'AF',
  LR: 'AF',
  LY: 'AF',
  MG: 'AF',
  MW: 'AF',
  ML: 'AF',
  MR: 'AF',
  MU: 'AF',
  MA: 'AF',
  MZ: 'AF',
  NA: 'AF',
  NE: 'AF',
  NG: 'AF',
  RW: 'AF',
  ST: 'AF',
  SN: 'AF',
  SC: 'AF',
  SL: 'AF',
  SO: 'AF',
  ZA: 'AF',
  SS: 'AF',
  SD: 'AF',
  TZ: 'AF',
  TG: 'AF',
  TN: 'AF',
  UG: 'AF',
  EH: 'AF',
  ZM: 'AF',
  ZW: 'AF',
} as const
