import { useState, Suspense, lazy, LazyExoticComponent, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'

import { ISideDialogChildProps, ISideDialogRef } from 'src/lib/SideDialog'
import { useDeleteDriverMutation, useUpdateDriverMutation } from '@features/drivers/driversApiSlice'
import useServerResponse from '@hooks/useServerResponse'
import { parseError } from '@shared/utils/formatters'

import { Tooltip, IconButton, Button, useTheme } from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import HistoryIcon from '@mui/icons-material/History'
import PaymentsIcon from '@mui/icons-material/Payments'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ArchiveIcon from '@mui/icons-material/Archive'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import { createUpdateDriverRequest } from '@/pkg/drivers/converters'
import { ITheme } from '@pkg/sokarUI'

interface DriverDialogProps extends ISideDialogChildProps {
  elementRef: MutableRefObject<ISideDialogRef | null>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
  refetch: () => void
  handleClose: () => void
}

type driverDialogElements = 'info' | 'edit' | 'history' | 'loads' | 'permissions'

const DriverDialog: React.FC<DriverDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  const [deleteDriver] = useDeleteDriverMutation()
  const [updateDriver] = useUpdateDriverMutation()
  const { setResponseMessage, setIsSuccess, setShowResponse } = useServerResponse()
  const [driverId, setDriverId] = useState<string>('')

  const handleDeleteDriver = (id: string) => {
    const handleDelete = (id: string) => {
      deleteDriver(id)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Fleet:responses.driverDeleted'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.deleteDriverTitle'),
      text: t('Fleet:confirmationDialog.deleteDriverText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleArchiveDriver = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      let driver = createUpdateDriverRequest(props.data)
      driver.archived = true
      updateDriver(driver)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Fleet:responses.driverUpdated'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err) => {
          const error = parseError<any>(err.message)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveDriverTitle'),
      text: t('Fleet:confirmationDialog.archiveDriverText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:driverDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.loads')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('loads')}
        >
          <WorkHistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.paymentHistory')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('loads')}
        >
          <PaymentsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.makeUser')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <PersonAddAlt1Icon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.permissions')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('permissions')}
        >
          <LockPersonIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleDeleteDriver(driverId)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleArchiveDriver()}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<driverDialogElements>('info')
  const getProperElement = (
    key: driverDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      case 'loads':
        return lazy(() => import('./loads'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (!!props.data) setDriverId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [driverId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent data={props.data} />
    </Suspense>
  )
}

export default DriverDialog
