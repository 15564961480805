import { SyntheticEvent, ChangeEvent, useState } from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import useOwnUser from 'src/hooks/useOwnUser'
import useServerResponse from 'src/hooks/useServerResponse'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  useCreateTrailerMutation,
  useGetTrailerCategoriesQuery,
} from '@/features/equipment/equipmentApiSlice'

import { ICreateTrailerRequest } from '@pkg/equipment/requests'
import { IAutocompleteOption } from '@pkg/sokarUI'
import { trailerValidator } from '@pkg/equipment/validators'
import { initICreateTrailerRequest } from '@pkg/equipment/initializers'
import { parseError } from '@/shared/utils/formatters'

import CompanySearch from 'src/shared/components/companySearch'
import { ICreateFleetObjectDialogProps } from '@pkg/sokarUI'

import {
  Box,
  Paper,
  Stack,
  Button,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material'

const CreateTrailerDialog: React.FC<ICreateFleetObjectDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const defaultValues = initICreateTrailerRequest()
  const { data: trailerTypes } = useGetTrailerCategoriesQuery(null)
  const options: IAutocompleteOption<string>[] =
    trailerTypes ?
      trailerTypes.map((key) => {
        return {
          label: t('Fleet:equipment.' + key),
          value: key,
        }
      })
    : []
  const { companyId } = useOwnUser()
  const [createTrailer] = useCreateTrailerMutation()
  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateTrailerRequest>({
    resolver: yupResolver(trailerValidator),
  })

  const onSubmit = (trailerData: ICreateTrailerRequest): void => {
    trailerData.companyId = companyId ?? ''
    if (!trailerData.isCarrierOwned) delete trailerData.carrierId
    createTrailer(trailerData)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Fleet:responses.trailerCreated'))
        setIsSuccess(true)
        setShowResponse(true)
      })
      .then(() => reset(defaultValues))
      .then(() => props.refetch())
      .then(() => props.handleClose(false))
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const [isCarrierSelectDisabled, setIsCarrierSelectDisabled] = useState<boolean>(true)
  const handleIsCarrierOwnedChange = (
    value: boolean,
    onChange: (...event: any[]) => void,
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsCarrierSelectDisabled(!value)
    onChange(e)
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={3}
    >
      <Controller
        control={control}
        name={'plate'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.plates')}
            error={!!errors?.plate}
            helperText={<Box height={2}>{errors?.plate?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'category'}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            options={options}
            disabled={options.length === 0}
            value={options.find(({ value }) => value === field.value)}
            onChange={(
              e: SyntheticEvent<Element, Event>,
              option: IAutocompleteOption<string> | null,
            ) => {
              if (option != null) field.onChange(option.value)
            }}
            PaperComponent={(params) => (
              <Paper
                {...params}
                sx={{ backgroundColor: appTheme.palette.background.default }}
              />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors?.category}
                helperText={<Box height={2}>{errors?.category?.message}</Box>}
                label={t('Fleet:createTrailerDialog.type')}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={'vin'}
        render={({ field }) => (
          <TextField
            {...field}
            label={'VIN'}
            error={!!errors?.vin}
            helperText={<Box height={2}>{errors?.vin?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'make'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.make')}
            error={!!errors?.make}
            helperText={<Box height={2}>{errors?.make?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'model'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.model')}
            error={!!errors?.model}
            helperText={<Box height={2}>{errors?.model?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'year'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'number'}
            label={t('Fleet:createTrailerDialog.year')}
            error={!!errors?.year}
            helperText={<Box height={2}>{errors?.year?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'number'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.number')}
          />
        )}
      />
      <Stack
        direction={'row'}
        spacing={0}
        alignItems={'center'}
      >
        <Typography>{t('Fleet:createTrailerDialog.isCarrierOwned')}:</Typography>
        <Controller
          control={control}
          name={'isCarrierOwned'}
          render={({ field }) => (
            <Checkbox
              value={field.value}
              onChange={(e) => handleIsCarrierOwnedChange(e.target.checked, field.onChange, e)}
            />
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'carrierId'}
        render={({ field }) => (
          <CompanySearch
            {...field}
            error={errors?.carrierId}
            isDisabled={isCarrierSelectDisabled}
          />
        )}
      />
      <Button
        variant={'contained'}
        type={'submit'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateTrailerDialog
