import { useState, Suspense, lazy, LazyExoticComponent, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { ISideDialogChildProps, ISideDialogRef } from '@lib/SideDialog'
import { useUpdateTrailerMutation } from '@/features/equipment/equipmentApiSlice'
import { parseError } from '@shared/utils/formatters'
import { Tooltip, IconButton, Button } from '@mui/material'
import useServerResponse from '@/hooks/useServerResponse'

import HomeIcon from '@mui/icons-material/Home'
import HistoryIcon from '@mui/icons-material/History'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import { createUpdateTrailerRequest } from '@/pkg/equipment/converters'
import { IConfirmationDialogRef } from '@/lib/ConfirmationDialog'

type trailerDialogElements = 'info' | 'history'

interface ITrailerDialogProps extends ISideDialogChildProps {
  refetch: () => void
  handleClose: () => void
  elementRef: MutableRefObject<ISideDialogRef>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
}

const TrailerDialog: React.FC<ITrailerDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [updateTrailer] = useUpdateTrailerMutation()
  const [trailerId, setTrailerId] = useState<string>('')
  const { setIsSuccess, setShowResponse, setResponseMessage } = useServerResponse()

  const handleUnarchiveTrailer = () => {
    const handleUnarchive = () => {
      let trailer = createUpdateTrailerRequest(props.data!)
      trailer.archived = false
      updateTrailer(trailer)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Fleet:responses.trailerDeleted'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.unarchiveTrailerTitle'),
      text: t('Fleet:confirmationDialog.unarchiveTrailerText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleUnarchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:trailerDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleUnarchiveTrailer()}
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<trailerDialogElements>('info')
  const getProperElement = (
    key: trailerDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    if (key === 'history') {
      return lazy(() => import('@app/fleet/views/TrailersView/components/trailerDialog/history'))
    }
    return lazy(() => import('@app/fleet/views/TrailersView/components/trailerDialog/info'))
  }

  useEffect(() => {
    setElementKey('info')
    if (props.data) setTrailerId(props.data.id)
  }, [props.data])

  useEffect(() => {
    if (!!trailerId) props.elementRef.current?.setActions(dialogActions)
  }, [trailerId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
      />
    </Suspense>
  )
}

export default TrailerDialog
