import './style.scss'

import { useGetCustomerIdQuery, useGetCustomerQuery } from '@api/apiSlice'
import Logo from '@assets/img/sokar_logo_v2.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import useCustomerID from '@hooks/useCustomerID'
import useOwnUser from '@hooks/useOwnUser'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { ITheme } from '@pkg/sokarUI'
import { IUserLoginRequest } from '@pkg/users/requests'
import { ISignInResponse } from '@pkg/users/responses'
import { userLoginValidationSchema } from '@pkg/users/validators'
import { parseError } from '@shared/utils/formatters'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { setCredentials, setUser } from 'src/features/auth/authSlice'
import { useGetUserQuery, useSignInMutation } from 'src/features/users/usersApiSlice'
import useServerResponse from 'src/hooks/useServerResponse'

const SignIn = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const appTheme: ITheme = useTheme()
  const { setShowResponse, setResponseMessage } = useServerResponse()
  const customerTag = useCustomerID()

  const {
    data: customerId,
    isSuccess: isCustomerIdSuccess,
    isFetching: isCustomerIdFetching,
  } = useGetCustomerIdQuery(customerTag, {
    skip: !customerTag,
  })
  const { data: customer } = useGetCustomerQuery(customerId ?? '', { skip: !customerId })
  useEffect(() => {
    if (customer) localStorage.setItem('country', customer?.customer?.countryCode)
  }, [customer])

  const methods = useForm<IUserLoginRequest>({
    resolver: yupResolver(userLoginValidationSchema),
  })
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods

  const dispatch = useDispatch()
  const [signIn] = useSignInMutation()
  const [userId, setUserId] = useState<string | undefined>(undefined)
  const { data: user } = useGetUserQuery(userId ?? '', {
    skip: !userId,
  })
  const signedUser = useOwnUser()
  useEffect(() => {
    if (!!signedUser && !signedUser.roles.includes('client')) {
      navigate('/market/for-carriers')
    } else if (signedUser) {
      navigate('/market/transport-needs')
    }
  }, [signedUser])

  useEffect(() => {
    !!user && dispatch(setUser({ userInfo: user }))
  }, [user])

  const onSubmit = async (credentials: IUserLoginRequest): Promise<void> => {
    if (isCustomerIdSuccess) credentials.customerId = customerId
    if (rememberMe) Cookies.set('rememberUsername', credentials.username, { expires: 365 })

    signIn(credentials)
      .unwrap()
      .then((res: ISignInResponse) => {
        dispatch(setCredentials({ ...res, customerId }))
        setUserId(res.id)
      })
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setShowResponse(true)
      })
  }

  const [rememberMe, setRememberMe] = useState(!!Cookies.get('rememberUsername'))
  const [username, setUsername] = useState(Cookies.get('rememberUsername') ?? '')

  useEffect(() => {
    if (username) setValue('username', username)
  }, [])

  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <Stack
      direction={'column'}
      spacing={1}
      sx={{ width: '80%', margin: '0 auto' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={'column'}
          spacing={2}
          className={'sign_in_container'}
          sx={{ color: appTheme.palette.text.primary }}
        >
          <img
            src={Logo}
            alt={"application's logo"}
            className={'sign_in_logo'}
          />
          {!isCustomerIdFetching && !customerId ?
            <Typography
              className={'sign_in_title'}
              sx={{ color: appTheme.palette.primary.main }}
            >
              {t('Root:auth.customerError')}
            </Typography>
          : <>
              <Typography
                className={'sign_in_title'}
                sx={{ color: appTheme.palette.primary.main }}
              >
                {t('Root:auth.signIn')}
              </Typography>
              <Controller
                name={'username'}
                control={control}
                render={() => (
                  <TextField
                    value={username}
                    variant='outlined'
                    className={'sign_in_input'}
                    {...register('username')}
                    error={!!errors.username}
                    helperText={errors.username?.message}
                    label={t('Root:auth.username')}
                    onChange={(event) => {
                      setUsername(event.target.value)
                      setValue('username', event.target.value)
                    }}
                  />
                )}
              />
              <Controller
                name={'password'}
                control={control}
                render={({ field }) => (
                  <TextField
                    variant='outlined'
                    error={!!errors.password}
                    className='sign_in_input'
                    helperText={errors.password?.message}
                    type={showPassword ? 'text' : 'password'}
                    label={t('Root:auth.password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ?
                              <VisibilityOffIcon />
                            : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
              <Stack className='signin_stack'>
                <Stack
                  justifyContent='flex-start'
                  alignItems='center'
                  direction='row'
           
                >
                  <Checkbox
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 20 },
                    }}
                    className='signin_checkbox'
                    checked={rememberMe}
                    value={rememberMe}
                    onClick={() => setRememberMe(!rememberMe)}
                  />
                  <Typography className='signin_remember_typo'>
                    {t('Root:auth.remember')}
                  </Typography>
                  <span style={{ flex: 1 }} />
                </Stack>
              </Stack>
              <Button
                color='primary'
                type='submit'
                variant='contained'
                fullWidth
              >
                {t('Root:auth.signIn')}
              </Button>
              <Typography
                    className='signin_typo'
                    sx={{
                      color: appTheme.palette.primary.main,
                    }}
                  >
                    <a
                      style={{
                        color: appTheme.palette.primary.main,
                      }}
                      href='/password/reset'
                    >
                      {t('Root:auth.forgotPassword')}
                    </a>
                  </Typography>
              <Typography className='signin_register'>
                <a
                  style={{
                    color: appTheme.palette.primary.main,
                  }}
                  href='/register'
                >
                  {' '}
                  {t('Root:auth.dontHaveAccount')}
                </a>
              </Typography>
            </>
          }
        </Stack>
      </form>
    </Stack>
  )
}

export default SignIn
