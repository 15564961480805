import { SetStateAction, Dispatch } from 'react'
import { settingsComponent } from './../../index'
import { Stack, List, useTheme, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'

import LockIcon from '@mui/icons-material/Lock'
import PersonIcon from '@mui/icons-material/Person'

interface SettingsNavProps {
  setNavComponent: Dispatch<SetStateAction<settingsComponent>>
}

const SettingsNav = ({ setNavComponent }: SettingsNavProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()

  return (
    <Stack
      direction={'column'}
      spacing={2}
    >
      <List
        sx={{ bgcolor: appTheme.palette.background.default }}
        component='nav'
      >
        <ListItemButton onClick={() => setNavComponent('personal')}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t('Root:auth.personal')} />
        </ListItemButton>
        <ListItemButton onClick={() => setNavComponent('security')}>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary={t('Root:auth.security')} />
        </ListItemButton>
      </List>
    </Stack>
  )
}

export default SettingsNav
