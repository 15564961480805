import { useEffect, useRef, useCallback } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useGetLoadQuery } from '@features/loads/loadsApiSlice'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import { ITableColumn, ITheme } from '@pkg/sokarUI'
import { ILoad } from '@pkg/loads/models'
import SideDialog from '@lib/SideDialog'
import useOwnUser from '@hooks/useOwnUser'
import CreateClientOfferDialog from './components/createClientOfferDialog'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import ContentList from '@lib/ContentList'
import ClientOfferCard from './components/clientOfferCard'
import ClientOfferDialog from './components/clientOfferDialog'

import { Skeleton, Typography, useMediaQuery, useTheme, Tooltip, IconButton } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'

const columns: ITableColumn[] = [
  {
    label: t('Market:clientOffers.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.deliveryLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.deliveryDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.rate'),
    dataKey: 'rate',
    isSortable: false,
  },
]

const ClientOffersManager: React.FC = () => {
  const appTheme: ITheme = useTheme()
  const { id: userId } = useOwnUser()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const {
    data: transportOffers,
    isSuccess: isOffersSuccess,
    refetch: refetchOffers,
  } = useGetLoadQuery({
    published: true,
    tags: 'shipper-offer',
    ownerId: userId,
  })
  const refresh = useRefetchWithAlert(refetchOffers)

  const tableRef = useRef<ITableRef>(null)
  const listRef = useRef<any>(null)
  useEffect(() => {
    if (transportOffers) tableRef.current?.open(transportOffers, columns)
    if (transportOffers && isMobile) listRef.current?.open(transportOffers)
  }, [transportOffers, isMobile])

  const cellRenderer = (column: ITableColumn, row: ILoad): JSX.Element => {
    if (column.dataKey === 'pickupLocation') {
      return <Typography>{row.stops[0].countryAbbreviation + ' ' + row.stops[0].city}</Typography>
    }
    if (column.dataKey === 'pickupDate') {
      return <Typography>{dayjs(row.stops[0].date).format('YYYY-MM-DD')}</Typography>
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        <Typography>
          {row.stops[row.stops.length - 1].countryAbbreviation + ' ' + row.stops[0].city}
        </Typography>
      )
    }
    if (column.dataKey === 'deliveryDate') {
      return (
        <Typography>{dayjs(row.stops[row.stops.length - 1].date).format('YYYY-MM-DD')}</Typography>
      )
    }
    if (column.dataKey === 'rate') {
      return <Typography>{row.publishedPriceCurrency + ' ' + row.publishedPrice}</Typography>
    }

    return <Typography>{row[column.dataKey as keyof ILoad] as string}</Typography>
  }

  const createOfferDialogRef = useRef<any>(null)
  const handleCreateOfferDialogOpen = (): void => {
    createOfferDialogRef.current?.open({})
  }
  const handleCreateOfferDialogClose = (): void => {
    createOfferDialogRef.current?.close()
  }

  const freeActions: JSX.Element = (
    <>
      <Tooltip title={t('Market:clientOffers.add')}>
        <IconButton onClick={handleCreateOfferDialogOpen}>
          <AddIcon sx={{ color: appTheme.palette.text.light }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Market:clientOffers.refresh')}>
        <IconButton onClick={refresh}>
          <ReplayIcon sx={{ color: appTheme.palette.text.light }} />
        </IconButton>
      </Tooltip>
    </>
  )

  const offerDialogRef = useRef<any>(null)
  const handleOfferDialogOpen = useCallback((row: ILoad): void => {
    offerDialogRef.current?.open(row)
  }, [])

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          listComponent={ClientOfferCard}
          openDialog={handleOfferDialogOpen}
        />
      )
    }
    return (
      <ContentTable
        ref={tableRef}
        cellRenderer={cellRenderer}
        freeActions={freeActions}
        style={{ height: 'calc(100dvh - 120px)' }}
        openDialog={handleOfferDialogOpen}
      />
    )
  }

  if (!isOffersSuccess) {
    return <Skeleton />
  }

  return (
    <>
      {getProperComponent()}
      <SideDialog
        ref={offerDialogRef}
        title={t('Market:clientOfferDialog.title')}
      >
        <ClientOfferDialog />
      </SideDialog>
      <SideDialog
        ref={createOfferDialogRef}
        title={t('Market:createClientOfferDialog.title')}
      >
        <CreateClientOfferDialog
          handleClose={handleCreateOfferDialogClose}
          refetch={refetchOffers}
        />
      </SideDialog>
    </>
  )
}

export default ClientOffersManager
