import { useState, Suspense, lazy, LazyExoticComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, IconButton, useTheme, Button, TextField, MenuItem } from '@mui/material'
import { ILoad } from '@pkg/loads/models'
import { ITheme } from '@pkg/sokarUI'
import styles from './loadDialog.module.scss'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { useUpdateLoadMutation } from '@features/loads/loadsApiSlice'
import { parseError } from '@shared/utils/formatters'
import useServerResponse from '@hooks/useServerResponse'
import { createUpdateLoadRequest } from '@/pkg/loads/converters'
import ArchiveIcon from '@mui/icons-material/Archive'

type ongoingTransportElement = 'info' | 'edit' | 'history'

export interface OngoingTransportDialogProps {
  data: ILoad | null
  elementRef: any
  refetch: any
  handleClose: any
}

const OfferDialog: React.FC<any> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  const [updateLoad] = useUpdateLoadMutation()
  const [offerId, setOfferId] = useState<string>('')
  const { setIsSuccess, setShowResponse, setResponseMessage } = useServerResponse()

  const handleStatusChange = () => {
    let loadData = createUpdateLoadRequest(props.data)
    const handleChange = () => {
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Market:response.transportStatusUpdated'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.changeStatus'),
      text: (
        <TextField
          select
          defaultValue={loadData.statusName}
          className={styles.textField}
          onChange={(e) => {
            loadData.statusName = e.target.value
          }}
        >
          <MenuItem value={'ho-pu'}>{t('Fleet:currentLoads.statuses.ho-pu')}</MenuItem>
          <MenuItem value={'pick'}>{t('Fleet:currentLoads.statuses.pick')}</MenuItem>
          <MenuItem value={'in-trans'}>{t('Fleet:currentLoads.statuses.in-trans')}</MenuItem>
          <MenuItem value={'drop'}>{t('Fleet:currentLoads.statuses.drop')}</MenuItem>
          <MenuItem value={'del'}>{t('Fleet:currentLoads.statuses.del')}</MenuItem>
          <MenuItem value={'tonu'}>{t('Fleet:currentLoads.statuses.tonu')}</MenuItem>
          <MenuItem value={'can'}>{t('Fleet:currentLoads.statuses.can')}</MenuItem>
        </TextField>
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>
          {t('Common:cancel')}
        </Button>,
        <Button onClick={() => handleChange()}>{t('Common:confirm')}</Button>,
      ],
    })
  }

  const handleArchiveLoad = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      let loadData = createUpdateLoadRequest(props.data)
      loadData.archived = true
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          setResponseMessage(t('Market:response.transportStatusUpdated'))
          setIsSuccess(true)
          setShowResponse(true)
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
          setIsSuccess(false)
          setShowResponse(true)
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveLoadTitle'),
      text: t('Fleet:confirmationDialog.archiveLoadText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Market:ongoingLoadDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingLoadDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingLoadDialog.changeStatus')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleStatusChange}
        >
          <TrackChangesIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingLoadDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleArchiveLoad}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<ongoingTransportElement>('info')
  const getProperElement = (
    key: ongoingTransportElement,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (!!props.data) setOfferId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [offerId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
        handleClose={props.handleClose}
      />
    </Suspense>
  )
}

export default OfferDialog
