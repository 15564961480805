import { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import useOwnUser from '@hooks/useOwnUser'
import { yupResolver } from '@hookform/resolvers/yup'
import useServerResponse from '@hooks/useServerResponse'
import { useCreateDriverMutation } from 'src/features/drivers/driversApiSlice'

import CompanySearch from 'src/shared/components/companySearch'
import { IDriverCreateRequest } from '@pkg/drivers/requests'
import { initICreateDriver } from '@pkg/drivers/initalizers'
import { driverValidator } from '@pkg/drivers/validators'
import { ICreateFleetObjectDialogProps } from 'src/pkg/sokarUI'

import { Box, Stack, Button, MenuItem, Checkbox, TextField, Typography } from '@mui/material'
import { parseError } from '@shared/utils/formatters'
import PhoneNumberField from '@lib/PhoneNumberField'

const CreateDriverDialog: React.FC<ICreateFleetObjectDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const [createDriver] = useCreateDriverMutation()
  const defaultValues = initICreateDriver()
  const { companyId } = useOwnUser()

  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IDriverCreateRequest>({
    resolver: yupResolver(driverValidator),
  })

  const onSubmit = (driverData: IDriverCreateRequest): void => {
    driverData.companyId = companyId ?? ''
    if (!driverData.isOwner) delete driverData.carrierId
    createDriver(driverData)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Fleet:responses.driverCreated'))
        setIsSuccess(true)
        setShowResponse(true)
      })
      .then(() => reset(defaultValues))
      .then(() => setCompensationType(''))
      .then(() => props.refetch())
      .then(() => props.handleClose(false))
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const [isCarrierSelectDisabled, setIsCarrierSelectDisabled] = useState<boolean>(true)
  const handleIsCarrierOwnedChange = (
    value: boolean,
    onChange: (...event: any[]) => void,
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsCarrierSelectDisabled(!value)
    onChange(e)
  }

  const [compensationType, setCompensationType] = useState<string>('')
  const handleCompensationTypeChange = (
    value: string,
    onChange: (...event: any[]) => void,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setCompensationType(value)
    onChange(e)
  }

  const getCompensationInput = (): JSX.Element => {
    switch (compensationType) {
      case 'payPerUnit':
        return (
          <Controller
            control={control}
            name={'payPerKilometer'}
            render={({ field }) => (
              <TextField
                {...field}
                type={'number'}
                label={t('Fleet:createDriverDialog.compensationPayPerKilometer')}
                error={!!errors?.payPerKilometer}
                helperText={<Box height={2}>{errors?.payPerKilometer?.message}</Box>}
              />
            )}
          />
        )
      case 'percentage':
        return (
          <Controller
            control={control}
            name={'orderIncomePercentage'}
            render={({ field }) => (
              <TextField
                {...field}
                type={'number'}
                label={t('Fleet:createDriverDialog.compensationPercentage')}
                error={!!errors?.orderIncomePercentage}
                helperText={<Box height={2}>{errors?.orderIncomePercentage?.message}</Box>}
              />
            )}
          />
        )
      default:
        return <TextField disabled />
    }
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={3}
    >
      <Controller
        control={control}
        name={'firstName'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createDriverDialog.name')}
            error={!!errors?.firstName}
            helperText={<Box height={2}>{errors?.firstName?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'lastName'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createDriverDialog.surname')}
            error={!!errors?.lastName}
            helperText={<Box height={2}>{errors?.lastName?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'phone'}
        render={({ field }) => (
          <PhoneNumberField
            {...field}
            error={errors.phone}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        render={({ field }) => (
          <TextField
            {...field}
            label={'e-mail'}
            error={!!errors?.email}
            helperText={<Box height={2}>{errors?.email?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'socialSecurity'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createDriverDialog.socialSecurity')}
            error={!!errors?.socialSecurity}
            helperText={<Box height={2}>{errors?.socialSecurity?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'number'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createDriverDialog.number')}
            error={!!errors?.number}
            helperText={<Box height={2}>{errors?.number?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'compensationType'}
        render={({ field }) => (
          <TextField
            select
            value={field.value}
            onChange={(e) => handleCompensationTypeChange(e.target.value, field.onChange, e)}
            label={t('Fleet:createDriverDialog.compensationType')}
            error={!!errors?.compensationType}
            helperText={<Box height={2}>{errors?.compensationType?.message}</Box>}
          >
            <MenuItem value={'percentage'}>
              {t('Fleet:createDriverDialog.compensationPercentage')}
            </MenuItem>
            <MenuItem value={'payPerUnit'}>
              {t('Fleet:createDriverDialog.compensationPayPerKilometer')}
            </MenuItem>
          </TextField>
        )}
      />
      {getCompensationInput()}
      <Stack
        direction={'row'}
        spacing={0}
        alignItems={'center'}
      >
        <Typography>{t('Fleet:createDriverDialog.isOwner')}:</Typography>
        <Controller
          control={control}
          name={'isOwner'}
          render={({ field }) => (
            <Checkbox
              value={field.value}
              onChange={(e) => handleIsCarrierOwnedChange(e.target.checked, field.onChange, e)}
            />
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'carrierId'}
        render={({ field }) => (
          <CompanySearch
            {...field}
            isDisabled={isCarrierSelectDisabled}
            error={errors.carrierId}
          />
        )}
      />
      <Button
        variant={'contained'}
        type={'submit'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateDriverDialog
