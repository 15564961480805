import { apiSlice } from 'src/api/apiSlice'

import { ICompany } from '@/pkg/companies/models'
import { ICreateCompanyRequest } from '@/pkg/companies/requests'
import { ISliceBuilder } from '@/pkg/types'

export const companiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: ISliceBuilder) => ({
    getCompanies: builder.query<
      ICompany[],
      { id?: string; isSuspended?: boolean; page: number; pageSize: number; key?: string }
    >({
      query: ({ id, isSuspended, page, pageSize, key }) => ({
        url: '/company',
        params: { id, isSuspended, page, pageSize },
      }),
      transformResponse: (response: { companies: ICompany[] }) => {
        return response.companies
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName + '-' + queryArgs.key
      },
      merge: (currentCache, newItems) => {
        currentCache.push(...newItems)
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: ['companies'],
    }),
    createCompany: builder.mutation<ICompany, ICreateCompanyRequest>({
      query: (company: ICreateCompanyRequest) => ({
        url: '/company',
        method: 'POST',
        body: company,
      }),
      transformResponse: (response: { company: ICompany }) => response.company,
      invalidatesTags: ['companies'],
    }),
    updateCompany: builder.mutation<ICompany, ICompany>({
      query: (company: ICompany) => ({
        url: '/company',
        method: 'PUT',
        body: company,
      }),
      transformResponse: (response: { company: ICompany }) => response.company,
      invalidatesTags: ['companies'],
    }),
    searchCompanies: builder.query<
      ICompany[],
      { phrase?: string; customerId?: string; page: number; pageSize: number; key?: string }
    >({
      query: ({ phrase, customerId, page, pageSize, key }) => ({
        url: '/company/search',
        params: { phrase, customerId, page, pageSize },
      }),
      transformResponse: (response: { companies: ICompany[] }) => response.companies,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName + '-' + queryArgs.key
      },
      // merge: (currentCache, newItems) => {
      //   currentCache.push(...newItems)
      // },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    deleteCompany: builder.mutation<string, string>({
      query: (id: string) => ({
        url: `/company/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['companies'],
      transformResponse: (response: { id: string }) => response.id,
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useSearchCompaniesQuery,
  useDeleteCompanyMutation,
} = companiesApiSlice
