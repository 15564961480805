import './style.scss'

import { useTheme } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
// eslint-disable-next-line import/named
import { DefaultTFuncReturn } from 'i18next'
import {
  cloneElement,
  forwardRef,
  Fragment,
  isValidElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

interface SideDialogProps {
  title: string | JSX.Element | DefaultTFuncReturn
  isOpen?: boolean
  children: React.ReactNode
  actions?: JSX.Element
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>
  dialogActions?: JSX.Element
}

export interface ISideDialogRef {
  open: (data: any) => void
  setActions: (actions: any) => void
  close: () => void
}

export interface ISideDialogChildProps {
  data?: any
  isOpen?: boolean
}

const SideDialog = forwardRef<ISideDialogRef | undefined, SideDialogProps>(
  ({ children, ...props }, ref) => {
    const { t } = useTranslation()
    const appTheme: any = useTheme()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [data, setData] = useState<any>(undefined)
    const [dialogActions, setDialogActions] = useState<JSX.Element[]>([])
    useEffect(() => {
      if (props.dialogActions) setDialogActions([props.dialogActions])
    }, [props.dialogActions])

    useImperativeHandle(ref, () => ({
      open(_data: any) {
        if (!_data) return
        setData(_data)
        setIsOpen(true)
      },
      setActions(_actions: any) {
        if (!_actions) return
        setDialogActions(_actions)
      },
      close() {
        setIsOpen(false)
        if (data) setData(undefined)
      },
    }))

    const PropsInjectedChildren = () => {
      children && Array.isArray(children) ?
        children.map((child: any) => {
          if (isValidElement(child)) {
            return cloneElement(child as any, { isOpen, data })
          }
        })
      : isValidElement(children)
      return cloneElement(children as any, { isOpen, data })
    }

    return (
      <Box
        className={isOpen ? 'side_dialog_display' : 'side_dialog_donotdisplay'}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Box
          className={'side_dialog_actions_column'}
          sx={{
            background:
              'linear-gradient(0deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
          }}
        >
          <Tooltip
            title={t('Common:close')}
            placement={'left'}
            arrow={true}
          >
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon sx={{ color: appTheme.palette.text.light }} />
            </IconButton>
          </Tooltip>
          {dialogActions}
        </Box>
        <Box className={'side_dialog_content'}>
          <Box className={'side_dialog_title_bar'}>
            <Typography variant={'h1'}>{props.title}</Typography>
          </Box>
          <Box className={'side_dialog_contents'}>{isOpen && PropsInjectedChildren()}</Box>
        </Box>
      </Box>
    )
  },
)

SideDialog.displayName = 'SideDialog'

export default SideDialog
