import { apiSlice } from '@api/apiSlice'
import { ICity, ILoad } from '@/pkg/loads/models'
import { IUpdateLoadRequest } from '@/pkg/loads/requests'

export const loadsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoad: builder.query<
      ILoad[],
      {
        published: boolean
        id?: string
        archived?: boolean
        driverId?: string
        ownerId?: string
        tags?: string
      }
    >({
      query: ({ published, id, archived, driverId, ownerId, tags }) => ({
        url: '/loads',
        params: { published, id, archived, driverId, ownerId, tags },
      }),
      transformResponse: (response: { loads: ILoad[] }) => {
        return response.loads.slice().reverse()
      },
    }),
    createLoad: builder.mutation<ILoad, any>({
      query: (load) => ({
        url: '/loads',
        method: 'POST',
        body: load,
      }),
    }),
    updateLoad: builder.mutation<ILoad, IUpdateLoadRequest>({
      query: (load) => ({
        url: '/loads',
        method: 'PUT',
        body: load,
      }),
    }),
    searchLoads: builder.query<
      ILoad[],
      {
        phrase: string
        companyId?: string
        archived?: boolean
        published?: boolean
        ownerId?: string
        tags?: string
      }
    >({
      query: ({ phrase, companyId, archived, published, ownerId, tags }) => ({
        url: `/loads/search`,
        params: { phrase, companyId, archived, published, ownerId, tags },
      }),
      transformResponse: (response: { loads: ILoad[] }) => {
        return response.loads.slice().reverse()
      },
    }),
    deleteLoad: builder.mutation<{ id: string }, string>({
      query: (id) => ({
        url: `/loads/${id}`,
        method: 'DELETE',
      }),
    }),
    searchCities: builder.query<ICity[], { searchPhrase: string; countryCode: string }>({
      query: ({ searchPhrase, countryCode }) =>
        `/loads/city/search?phrase=${searchPhrase}&countryId=${countryCode}`,
      transformResponse: (response: { cities: ICity[] }) => response.cities,
    }),
  }),
})

export const {
  useGetLoadQuery,
  useCreateLoadMutation,
  useUpdateLoadMutation,
  useSearchLoadsQuery,
  useDeleteLoadMutation,
  useSearchCitiesQuery,
} = loadsApiSlice
