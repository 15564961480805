import { IDocument } from '@pkg/documents/models'
import { CreateFromTemplate, DocumentCreate, DocumentUpdate } from '@pkg/documents/requests'
import { apiSlice } from '@api/apiSlice'

export const documentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query<IDocument[], { [key: string]: string | undefined }>({
      query: (params) => ({
        url: '/documents',
        params,
      }),
      transformResponse: (response: { documents: IDocument[] }) => response.documents,
    }),
    getDocumentCategories: builder.query<string[], null>({
      query: () => ({
        url: '/documents/categories',
      }),
      transformResponse: (response: { categories: string[] }) => response.categories,
    }),
    getDocumentContent: builder.mutation<{ content: string }, string>({
      query: (id) => ({
        url: '/documents/content',
        params: { id },
      }),
    }),
    createDocument: builder.mutation<IDocument, DocumentCreate | CreateFromTemplate>({
      query: (documentInfo) => ({
        url: '/documents',
        method: 'POST',
        body: documentInfo,
      }),
      transformResponse: (response: { document: IDocument }) => response.document,
    }),
    updateDocument: builder.mutation<IDocument, DocumentUpdate>({
      query: (documentInfo) => ({
        url: '/documents',
        method: 'PUT',
        body: documentInfo,
      }),
    }),
    deleteDocument: builder.mutation<{ id: string }, { id: string }>({
      query: ({ id }) => ({
        url: `/documents/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  useGetDocumentCategoriesQuery,
  useGetDocumentContentMutation,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
} = documentsApiSlice
