import './i18n'
import { useState, createContext } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { getTheme, setTheme } from './theme'

import './App.scss'
import { Theme as ITheme, ThemeOptions, ThemeProvider, createTheme } from '@mui/material'
import { defaultTheme } from './themes/default'
import { Theme } from './pkg/types'

import Root from /* application's entry point */ './app/root'
import { useDispatch } from 'react-redux'

const appTheme: ITheme = createTheme(defaultTheme.light as ThemeOptions)
const appDarkTheme: ITheme = createTheme(defaultTheme.dark as ThemeOptions)

export const ThemeCtx: React.Context<{
  theme: Theme
  setThemeHandler: (arg: Theme) => void
}> = createContext({
  theme: 'light' as Theme,
  setThemeHandler: (arg: Theme) => {},
})

const App = () => {
  const [currentTheme, setCurrenTheme] = useState<Theme>(getTheme())

  const setThemeHandler = (theme: Theme): void => {
    setCurrenTheme(theme)
    setTheme(theme)
  }

  return (
    <ThemeCtx.Provider value={{ theme: currentTheme, setThemeHandler }}>
      <ThemeProvider theme={getTheme() === 'dark' ? appDarkTheme : appTheme}>
        <CssBaseline />
        <Root />
      </ThemeProvider>
    </ThemeCtx.Provider>
  )
}

export default App
