import { useState, useRef, useEffect, useCallback } from 'react'
import { t } from 'i18next'
import SideDialog from '@lib/SideDialog'
import dayjs from 'dayjs'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import { useGetLoadQuery, useSearchLoadsQuery } from '@features/loads/loadsApiSlice'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import { ITableColumn, ITheme } from '@pkg/sokarUI'
import OfferDialog from './components/offerDialog'
import ConfirmationDialog from '@lib/ConfirmationDialog'
import { ILoad } from '@pkg/loads/models'
import OfferCard from './components/offerCard'
import CreateOfferDialog from './components/createOfferDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import OffersMarketOptionCard from './components/offersMarketOptionsCard'

import {
  Chip,
  Typography,
  Tooltip,
  IconButton,
  Skeleton,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import useOwnUser from '@hooks/useOwnUser'

const columns: ITableColumn[] = [
  {
    label: t('Market:publishedTable.equipment'),
    dataKey: 'equipment',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.weight'),
    dataKey: 'weight',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.publishedPrice'),
    dataKey: 'publishedPrice',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
]

const OffersManager: React.FC = () => {
  const appTheme: ITheme = useTheme()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const ref = useRef<ITableRef>(null)
  const offerDialogRef = useRef<any>(null)
  const handleOfferDialogOpen = useCallback((row: any): void => {
    offerDialogRef.current?.open(row)
  }, [])
  const handleOfferDialogClose = useCallback((): void => {
    offerDialogRef.current?.close()
  }, [])

  const createOfferDialogRef = useRef<any>(null)
  const handleCreateOfferDialogOpen = useCallback((row: any): void => {
    createOfferDialogRef.current?.open(row)
  }, [])
  const handleCreateOfferDialogClose = useCallback((): void => {
    createOfferDialogRef.current?.close()
  }, [])

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Market:publishedTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateOfferDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:publishedTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const cellRenderer = (column: ITableColumn, row: ILoad): JSX.Element => {
    if (column.dataKey === 'equipment') {
      return (
        <Chip
          color={'primary'}
          label={row.equipment}
        />
      )
    }
    if (column.dataKey === 'weight') {
      return (
        <Typography>
          {row.weight} {row.weightUnit}
        </Typography>
      )
    }
    if (column.dataKey === 'publishedPrice') {
      return (
        <Typography>
          {row.publishedPrice} {row.publishedPriceCurrency}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupDate') {
      return <Typography>{dayjs(row.stops[0].date).format('YYYY-MM-DD')}</Typography>
    }
    if (column.dataKey === 'deliveryDate') {
      return (
        <Typography>{dayjs(row.stops[row.stops.length - 1].date).format('YYYY-MM-DD')}</Typography>
      )
    }
    if (column.dataKey === 'pickupLocation') {
      return <Typography>{row.stops[0].countryAbbreviation + ' ' + row.stops[0].city}</Typography>
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        <Typography>
          {row.stops[row.stops.length - 1].countryAbbreviation +
            ' ' +
            row.stops[row.stops.length - 1].city}
        </Typography>
      )
    }

    return <Typography>{row[column.dataKey as keyof ILoad] as string}</Typography>
  }

  const owner = useOwnUser()
  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchOffers = (phrase: string, isSearched: boolean) => {
    setIsSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedOffers, isSuccess: isSearchSuccess } = useSearchLoadsQuery(
    { phrase: searchPhrase, published: true, archived: false, ownerId: owner.id },
    { skip: !isSearched },
  )

  const {
    data: loadOffers,
    isSuccess: isOffersSuccess,
    refetch: refetchOffers,
  } = useGetLoadQuery({ published: true, ownerId: owner.id, tags: 'broker-load' })
  const refresh = useRefetchWithAlert(refetchOffers)

  useEffect(() => {
    if (isOffersSuccess && !isSearched) {
      ref.current?.open(loadOffers, columns)
      listRef.current?.open(loadOffers)
    }
  }, [isOffersSuccess, isSearched, loadOffers])

  useEffect(() => {
    if (isSearchSuccess && isSearched) {
      ref.current?.open(searchedOffers, columns)
      listRef.current?.open(searchedOffers)
    }
  }, [isSearchSuccess, isSearched, searchedOffers])

  const confirmationDialogRef = useRef<any>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(loadOffers)
    else ref.current?.open(loadOffers, columns)
  }, [isMobile, loadOffers, columns])

  const getProperView = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleOfferDialogOpen}
          listComponent={OfferCard}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleOfferDialogOpen}
      />
    )
  }

  if (!isOffersSuccess) return <Skeleton />

  return (
    <>
      <OffersMarketOptionCard
        value={searchPhrase}
        handleSearch={handleSearchOffers}
      />
      {getProperView()}
      <SideDialog
        ref={offerDialogRef}
        title={t('Market:carrierLoadDialog.title')}
      >
        <OfferDialog
          refetch={refetchOffers}
          elementRef={offerDialogRef}
          confirmationRef={confirmationDialogRef}
          handleClose={handleOfferDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={createOfferDialogRef}
        title={t('Market:offerDialog.createTitle')}
      >
        <CreateOfferDialog
          refetch={refetchOffers}
          handleClose={handleCreateOfferDialogClose}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default OffersManager
